import appConfig from '../appConfig'
import axios from 'axios'

export const userService = {
    login,
    logout,
    is_auth,
    user_details
};

function login(username, password) {
    let data = {
        username: username,
        password: password
    }
    if (localStorage.getItem('token')) {
        data.token = localStorage.getItem('token')
    }
    if (localStorage.getItem('language')) {
        data.lang = localStorage.getItem('language')
    }
    const config = {
        method: 'POST',
        url: appConfig.apiUrl + '/auth/login',
        headers: {
            'Content-Type': 'application/json',
        },
        data: data,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                logout();
                // location.reload(true);
            }

            return Promise.reject(err);
        })
}

function logout() {
    const config = {
        method: 'POST',
        url: appConfig.apiUrl + '/auth/logout',
        headers: {
            'Content-Type': 'application/json',
        },
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            return Promise.reject(err);
        })
}

function is_auth() {
    const config = {
        method: 'GET',
        url: appConfig.apiUrl + '/auth/is_auth',
        headers: {
            'Content-Type': 'application/json',
        },
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            return Promise.reject(err);
        })
}

function user_details() {
    let data = {}
    const config = {
        method: 'GET',
        url: appConfig.apiUrl + '/user/user_details',
        headers: {
            'Content-Type': 'application/json',
        },
        params: data,
        data: data,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                logout();
                location.reload(true);
            }

            return Promise.reject(err);
        })
}