import appConfig from '../appConfig';
import axios from 'axios';
import router from '../router';

export const shaniv_managementService = {
  updateProductsQuantityFromExcel,
  getProduct,
  getProducts,
  getProductDetail,
  getSubcategoryData,
  updateData,
  getShanivTags,
  getPackageData,
  getPackageList,
  updatePackageData,
  changePackageState,
  getCustomers,
  // createResellerAndUser,
  verifyUsername,
  createReseller,
  createUser,
  updateReseller,
  getPackageIDs,
  getPackageDetails,
  getProductData,
  getPackageListById,
  getCompanyList,
  getSimilarCustomer,
  verifyFields,
  createDistributes,
  getCustomerOptions
};

function updateProductsQuantityFromExcel(params) {
  const config = {
    method: 'POST',
    url: appConfig.apiUrl + '/shaniv/product/quantity_from_excel',
    data: params,
    withCredentials: 1,
  };

  return axios(config)
    .then((res) => {
      if (res.status !== 200) {
        const err = (res.data && res.data.message) || res.statusText;
        return Promise.reject(err);
      }

      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        router.push('/login');
      }

      return Promise.reject(err);
    });
}

function getShanivTags() {
  const config = {
    method: 'GET',
    url: appConfig.apiUrl + '/shaniv/product/tags',
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: 1,
    params: {},
    data: {},
  };

  return axios(config)
    .then((res) => {
      if (res.status !== 200) {
        const err = (res.data && res.data.message) || res.statusText;
        return Promise.reject(err);
      }

      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        router.push('/login');
      }

      return Promise.reject(err);
    });
}

function getProducts(params) {
  const config = {
    method: 'GET',
    url: appConfig.apiUrl + '/shaniv/product',
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: 1,
    params: params,
    data: params,
  };

  return axios(config)
    .then((res) => {
      if (res.status !== 200) {
        const err = (res.data && res.data.message) || res.statusText;
        return Promise.reject(err);
      }

      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        router.push('/login');
      }

      return Promise.reject(err);
    });
}

function getProduct(id) {
  const config = {
    method: 'GET',
    url: appConfig.apiUrl + '/shaniv/product/' + id,
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: 1,
  };

  return axios(config)
    .then((res) => {
      if (res.status !== 200) {
        const err = (res.data && res.data.message) || res.statusText;
        return Promise.reject(err);
      }

      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        router.push('/login');
      }

      return Promise.reject(err);
    });
}

function getProductDetail(extpart) {
  const params = {};

  const config = {
    method: 'GET',
    // url: appConfig.apiUrl + '/admin/shaniv_product/' + partname,
    url: appConfig.apiUrl + '/shaniv/product/' + extpart,
    headers: {
      'Content-Type': 'application/json',
    },
    params: params,
    data: params,
    withCredentials: 1,
  };

  return axios(config)
    .then((res) => {
      if (res.status !== 200) {
        const err = (res.data && res.data.message) || res.statusText;
        return Promise.reject(err);
      }

      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        router.push('/login');
      }

      return Promise.reject(err);
    });
}

function getSubcategoryData() {
  const params = {};

  const config = {
    method: 'GET',
    url: appConfig.apiUrl + '/admin/shaniv_product/subcategories',
    headers: {
      'Content-Type': 'application/json',
    },
    params: params,
    data: params,
    withCredentials: 1,
  };

  return axios(config)
    .then((res) => {
      if (res.status !== 200) {
        const err = (res.data && res.data.message) || res.statusText;
        return Promise.reject(err);
      }

      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        router.push('/login');
      }

      return Promise.reject(err);
    });
}

function updateData(id, params) {
  const config = {
    method: 'PUT',
    url: appConfig.apiUrl + '/shaniv/product/' + id,
    data: params,
    withCredentials: 1,
  };

  return axios(config)
    .then((res) => {
      if (res.status !== 200) {
        const err = (res.data && res.data.message) || res.statusText;
        return Promise.reject(err);
      }

      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        router.push('/login');
      }

      return Promise.reject(err);
    });
}

function getPackageData(id) {
  const params = {};

  const config = {
    method: 'GET',
    url: appConfig.apiUrl + '/shaniv/package/' + id,
    headers: {
      'Content-Type': 'application/json',
    },
    params: params,
    data: params,
    withCredentials: 1,
  };

  return axios(config)
    .then((res) => {
      if (res.status !== 200) {
        const err = (res.data && res.data.message) || res.statusText;
        return Promise.reject(err);
      }

      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        router.push('/login');
      }

      return Promise.reject(err);
    });
}
function getPackageList(params) {
  const config = {
    method: 'GET',
    url: appConfig.apiUrl + '/shaniv/package',
    headers: {
      'Content-Type': 'application/json',
    },
    params: params,
    data: params,
    withCredentials: 1,
  };

  return axios(config)
    .then((res) => {
      if (res.status !== 200) {
        const err = (res.data && res.data.message) || res.statusText;
        return Promise.reject(err);
      }

      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        router.push('/login');
      }

      return Promise.reject(err);
    });
}

function updatePackageData(id, package_data) {
  let formData = new FormData();

  formData.append('start_time', package_data.start_time);
  formData.append('end_time', package_data.end_time);
  formData.append('id', package_data.id);
  formData.append('name', package_data.name);
  formData.append('start_date', package_data.start_date);
  formData.append('end_date', package_data.end_date);
  formData.append('price', package_data.price);
  formData.append('image', package_data.image);
  formData.append('active', package_data.active);
  formData.append('company', package_data.company);
  formData.append('products', JSON.stringify(package_data.products));
  let config = {};
  if (id) {
    config = {
      method: 'PUT',
      url: appConfig.apiUrl + '/shaniv/package/' + id,
      data: formData,
      withCredentials: 1,
    };
  } else {
    config = {
      method: 'POST',
      url: appConfig.apiUrl + '/shaniv/package',
      data: formData,
      withCredentials: 1,
    };
  }

  return axios(config)
    .then((res) => {
      if (res.status !== 200) {
        const err = (res.data && res.data.message) || res.statusText;
        return Promise.reject(err);
      }

      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        router.push('/login');
      }

      return Promise.reject(err);
    });
}
function changePackageState(id, active) {
  const params = {
    active: active,
  };

  const config = {
    method: 'PUT',
    url: appConfig.apiUrl + '/shaniv/package/' + id + '/active',
    headers: {
      'Content-Type': 'application/json',
    },
    data: params,
    withCredentials: 1,
  };

  return axios(config)
    .then((res) => {
      if (res.status !== 200) {
        const err = (res.data && res.data.message) || res.statusText;
        return Promise.reject(err);
      }

      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        router.push('/login');
      }

      return Promise.reject(err);
    });
}

function getCustomers(params) {
  const config = {
    method: 'GET',
    url: appConfig.apiUrl + '/shaniv/customer/active_customers',
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: 1,
    params: params,
    data: params,
  };

  return axios(config)
    .then((res) => {
      if (res.status !== 200) {
        const err = (res.data && res.data.message) || res.statusText;
        return Promise.reject(err);
      }

      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        router.push('/login');
      }

      return Promise.reject(err);
    });
}

function getCustomerOptions(params) {
  const config = {
    method: 'GET',
    url: appConfig.apiUrl + '/shaniv/customer/option',
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: 1,
    params: params,
    data: params,
  };

  return axios(config)
    .then((res) => {
      if (res.status !== 200) {
        const err = (res.data && res.data.message) || res.statusText;
        return Promise.reject(err);
      }

      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        router.push('/login');
      }

      return Promise.reject(err);
    });
}

function verifyUsername(username) {
  const config = {
    method: 'GET',
    url: appConfig.apiUrl + '/user/valid_fields',
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: 1,
    params: {
      model: 'user',
      field: 'login_name',
      value: username,
    },
  };

  return axios(config)
    .then((res) => {
      if (res.status !== 200) {
        const err = (res.data && res.data.message) || res.statusText;
        return Promise.reject(err);
      }

      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        router.push('/login');
      }

      return Promise.reject(err);
    });
}
function verifyFields(data) {
  const config = {
    method: 'GET',
    url: appConfig.apiUrl + '/user/valid_fields',
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: 1,
    params: {
      model: 'user',
      field: data.field,
      value: data.value
    },
  };

  return axios(config)
    .then((res) => {
      if (res.status !== 200) {
        const err = (res.data && res.data.message) || res.statusText;
        return Promise.reject(err);
      }

      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        router.push('/login');
      }

      return Promise.reject(err);
    });
}

function createReseller(businessData) {
  const config = {
    method: 'POST',
    url: appConfig.apiUrl + '/business/business',
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: 1,
    params: {},
    data: {
      ...businessData,
    },
  };

  return axios(config)
    .then((res) => {
      if (res.status !== 200) {
        const err = (res.data && res.data.message) || res.statusText;
        return Promise.reject(err);
      }

      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        router.push('/login');
      }

      return Promise.reject(err);
    });
}

function createUser(userData) {
  // return new Promise((resolve) => setTimeout(() => resolve(16), 2000));

  const config = {
    method: 'POST',
    url: appConfig.apiUrl + '/user',
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: 1,
    params: {},
    data: {
      ...userData,
    },
  };

  return axios(config)
    .then((res) => {
      if (res.status !== 200) {
        const err = (res.data && res.data.message) || res.statusText;
        return Promise.reject(err);
      }

      return res.data.id;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        router.push('/login');
      }

      return Promise.reject(err);
    });
}

function createDistributes(data) {
  const config = {
      method: 'POST',
      url: appConfig.apiUrl + '/distributor/distributor',
      headers: {
          'Content-Type': 'application/json',
      },
      params: {},
      data: data,
      withCredentials: 1
  }

  return axios(config)
      .then(res => {
          if (res.status !== 200) {
              const err = (res.data && res.data.message) || res.statusText;
              return Promise.reject(err);
          }

          return res.data
      })
      .catch(err => {
          if (err.response.status === 401) {
              router.push('/login');
          }

          return Promise.reject(err);
      })
}

function updateReseller(customerId, businessId, userId) {
  const config = {
    method: 'PUT',
    url: appConfig.apiUrl + '/shaniv/customer/update_reseller/' + customerId,
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: 1,
    params: {},
    data: {
      business_id: businessId,
      user_id: userId,
    },
  };

  return axios(config)
    .then((res) => {
      if (res.status !== 200) {
        const err = (res.data && res.data.message) || res.statusText;
        return Promise.reject(err);
      }

      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        router.push('/login');
      }

      return Promise.reject(err);
    });
}

function getPackageIDs(EXTPART) {
  const params = {};

  const config = {
    method: 'GET',
    url: appConfig.apiUrl + '/shaniv/get-packages-ids/' + EXTPART,
    headers: {
      'Content-Type': 'application/json',
    },
    params: params,
    data: params,
    withCredentials: 1,
  };

  return axios(config)
    .then((res) => {
      if (res.status !== 200) {
        const err = (res.data && res.data.message) || res.statusText;
        return Promise.reject(err);
        
      }
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        router.push('/login');
      }
      return Promise.reject(err);
    });
}

function getPackageDetails(id) {
  const params = {};

  const config = {
    method: 'GET',
    url: appConfig.apiUrl + '/shaniv/package/' + id,
    headers: {
      'Content-Type': 'application/json',
    },
    params: params,
    data: params,
    withCredentials: 1,
  };

  return axios(config)
    .then((res) => {
      if (res.status !== 200) {
        const err = (res.data && res.data.message) || res.statusText;
        return Promise.reject(err);
      }
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        router.push('/login');
      }

      return Promise.reject(err);
    });
}

function getProductData(extpart, plname) {
  const params = {
    EXTPART: extpart,
    PLNAME: plname
  };

  const config = {
    method: 'GET',
    url: appConfig.apiUrl + '/shaniv/product',
    headers: {
      'Content-Type': 'application/json',
    },
    params: params,
    withCredentials: 1,
  };

  return axios(config)
    .then((res) => {
      if (res.status !== 200) {
        const err = (res.data && res.data.message) || res.statusText;
        return Promise.reject(err);
      }
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        router.push('/login');
      }

      return Promise.reject(err);
    });
}
function getPackageListById(id) {
  const params = {
    active: true
  };

  const config = {
    method: 'GET',
    url: appConfig.apiUrl + '/shaniv/product/' + id + '/packages',
    headers: {
      'Content-Type': 'application/json',
    },
    params: params,
    withCredentials: 1,
  };

  return axios(config)
    .then((res) => {
      if (res.status !== 200) {
        const err = (res.data && res.data.message) || res.statusText;
        return Promise.reject(err);
      }
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        router.push('/login');
      }

      return Promise.reject(err);
    });
}
function getCompanyList() {
  const params = {};
  const config = {
    method: 'GET',
    url: appConfig.apiUrl + '/shaniv/company',
    headers: {
      'Content-Type': 'application/json',
    },
    params: params,
    withCredentials: 1,
  };

  return axios(config)
    .then((res) => {
      if (res.status !== 200) {
        const err = (res.data && res.data.message) || res.statusText;
        return Promise.reject(err);
      }
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        router.push('/login');
      }

      return Promise.reject(err);
    });
}
function getSimilarCustomer(id) {
  const params = {};
  const config = {
    method: 'GET',
    url: appConfig.apiUrl + '/shaniv/customer/'+id+'/similar',
    headers: {
      'Content-Type': 'application/json',
    },
    params: params,
    withCredentials: 1,
  };

  return axios(config)
    .then((res) => {
      if (res.status !== 200) {
        const err = (res.data && res.data.message) || res.statusText;
        return Promise.reject(err);
      }
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        router.push('/login');
      }

      return Promise.reject(err);
    });
}

