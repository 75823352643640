import { paymentService } from '../services';
import router from '../router/index';

const state = {
    transactions_receipts: [],
    current_transaction_id: null,
    current_transaction_card_number: null,
    current_transaction_product_id: null,
    current_transaction_supplier_id: null,
    paymentRequestInProgress: false
}
const actions = {
    getTransactionReceipt({commit}, {transaction_id}) {
        paymentService.getTransactionReceipt(transaction_id)
            .then(
                receipt_data => {
                    if (receipt_data && receipt_data.hasOwnProperty('transId') && receipt_data.transId) {
                        commit('setTransactionReceipt', {
                            transaction_id: receipt_data.transId,
                            receipt_data: receipt_data
                        })
                    }
                },
                error => {
                }
            );
    },
    paymentProduct({dispatch, commit}, {supplier_id, product_id, contract_number, phone_number}) {
        commit('setPaymentRequestInProgress', true);
        try {

            return paymentService.productPayment(supplier_id, product_id, contract_number, phone_number)
                .then(
                    result_data => {
                        if (result_data && result_data.hasOwnProperty('currentTransaction') && result_data.currentTransaction
                            && result_data.hasOwnProperty('success') && result_data.success) {
                            commit('setCurrentTransactionId', {transaction_id: result_data.currentTransaction})
                            commit('setCurrentTransactionCardNumber', {card_number: result_data.success})
                            commit('setCurrentTransactionProductId', {product_id: product_id})
                            commit('setCurrentTransactionSupplierId', {supplier_id: supplier_id})
                            dispatch('account/getUserDetails', {}, {root: true})
                            router.push({
                                name: 'CommunicationPaymentSuccess',
                                params: {id: result_data.currentTransaction}
                            })
                        }

                        commit('setPaymentRequestInProgress', false);
                        return result_data
                    },
                    error => {
                        if (error && error.hasOwnProperty('response') && error.response
                            && error.response.hasOwnProperty('data') && error.response.data) {
                            let data = error.response.data
                            if (data.error) {
                                dispatch('alert/error', data.error, {root: true})
                            }
                        }
                        commit('setPaymentRequestInProgress', false);
                        return error
                    }
                );
        } catch (e) {
            commit('setPaymentRequestInProgress', false);
        }
    }
};

const mutations = {
    setPaymentRequestInProgress(state, in_progress) {
        state.paymentRequestInProgress = in_progress;
    },
    setTransactionReceipt(state, {transaction_id, receipt_data}) {
        let transactions_receipts = state.transactions_receipts || []
        transactions_receipts[transaction_id] = receipt_data
        state.transactions_receipts = null;
        state.transactions_receipts = transactions_receipts;
    },
    setCurrentTransactionId(state, {transaction_id}) {
        state.current_transaction_id = transaction_id;
    },
    setCurrentTransactionCardNumber(state, {card_number}) {
        state.current_transaction_card_number = card_number;
    },
    setCurrentTransactionProductId(state, {product_id}) {
        state.current_transaction_product_id = product_id;
    },
    setCurrentTransactionSupplierId(state, {supplier_id}) {
        state.current_transaction_supplier_id = supplier_id;
    }
};

export const payment = {
    namespaced: true,
    state,
    actions,
    mutations
};