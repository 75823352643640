// HEVREW
export const locale = {
  COMMON: {
    NIS: 'ש"ח',
    SELECT: 'בחר',
    DATE: 'תאריך',
    DATES: 'תאריכים',
    NO_DATA: 'אין מידע',
    OFFERS: 'הצעות',
    SEARCH: 'חיפוש',
    SAVE: 'שמור',
    EDIT: 'לַעֲרוֹך',
    CANCEL: 'לְבַטֵל',
    DELETE: 'לִמְחוֹק',
    PHONE: 'טלפון',
    NEW: 'חדש',
    ACTIVE: 'פעיל',
    INACTIVE: "לֹא פָּעִיל",
    YES: 'כן',
    NO: 'לא',
    ADD: 'הוסף',
    PRICE: 'מחיר',
    FIND: 'למצוא',
    KG: 'ק"ג',
    TIME: 'הזמן',
    PLACE: 'מקום',
    INVOICE: 'חשבונית מס',
    CODE: 'קוד',
    CONTINUE: 'המשך',
    OK: 'בסדר',
    BACK: 'חזרה',
    ACTION: 'פעולה',
    E_MAIL: 'דואר אלקטרוני',
    CITY: 'עיר',
    ADDRESS: 'כתובת',
    POSTAL_CODE: 'מיקוד',
    ACTIVITY_TIME: 'שעות פעילות',
    FIRST_NAME: 'שם פרטי',
    LAST_NAME: 'שם משפחה',
    ID_NUMBER_PASSPORT: 'מספר ת.ז/דרכון',
    HOME_ADDRESS: 'כתובת בית',
    PASSWORD: 'סיסמה',
    SECURE: 'מאובטח',
    SECURE_DEVICE: 'מאובטח מכשיר',
    PAYMENT_METHOD: 'צורת תשלום',
    ID: 'תעודת זהות',
    HOUR: 'שעה',
    MORE: 'יותר',
    LESS: 'פָּחוּת',
    COST: 'עלות',
    BALANCE: 'יתרה',
    NAME: 'שֵׁם',
    BIRTHDAY: 'יום הולדת',
    RETURN: 'חזור',
    CLOSE: 'סגור',
    UPLOAD_IMAGE: 'להעלות תמונות',
    HIDE: 'להתחבא',
    DAY: 'יְוֹם',
    MONDAY: 'יוֹם שֵׁנִי',
    TUESDAY: 'יוֹם שְׁלִישִׁי',
    WEDNESDAY: 'יום רביעי',
    THURSDAY: 'יוֹם חֲמִישִׁי',
    FRIDAY: 'יוֹם שִׁישִׁי',
    SATURDAY: 'יום שבת',
    SUNDAY: 'יוֹם רִאשׁוֹן',
    COMMENTS: 'הערות',
    DURATION: 'מֶשֶׁך',
    VIEW: 'נוף',
    COVID_TXT: 'אנא בדוק את מסלול הטיול שלך, כולל עצירות ביניים, עבור <b>הגבלות נסיעה</b> לפני ההזמנה. מסלול המוצג אינו אישור לזכאותך לנסיעה.',
    CONFIG: 'Config',
    CERTIFICATE: 'תְעוּדָה',
  },
  DATE: {
    SUN: 'ראשון',
    MON: 'שני',
    TUE: 'שלישי',
    WED: 'רביעי',
    THU: 'חמישי',
    FRI: 'שישי',
    SAT: 'שבת',
  },
  CURRENCY: {
    USD: '$',
    EUR: '€',
  },
  LOGIN: {
    ENTRANCE: 'כניסה',
    LOGIN_TEXT: '.ברוכים הבאים, נא הכנס שם משתמש וסיסמא לכניסה למערכת',
    USERNAME: 'שם משתמש',
    PASSWORD: 'סיסמא',
    REMEMBER_ME: 'זכור אותי',
    FORGOT_PASSWORD: 'שכחתי סיסמא',
    LOGIN: 'התחבר',
    RESTORE: 'שחזור סיסמא',
    RESTORE_TEXT: 'על מנת לשחזר סיסמא הזן את המספר טלפון הבא:',
    PHONE_NUMBER: 'הזן את מספר הטלפון:',
    APPROVE: 'אישור',
    VERIFICATIONCODE: 'הכנס את הקוד אימות המופיע בהודעה',
    ENTERVERIFICATIONCODE: 'קוד אימות',
    ENTERNEWPASSWORD: 'הכנס סיסמא חדשה',
    CONTACT_US: 'צור קשר',
    
  },
  AUTHFOOTER: {
    SUPPORT: 'תמיכה',
    HOLIDAY_HOURS: 'בשישי ובערבי חג בשעות',
    ANSWER_HOURS: "מענה א'-ה' בין השעות",
    SCREEN_SHARING: 'לתמיכה על ידי שיתוף מסך',
    START_SUPPORT: 'התחל תמיכה',
    PHONE_SUPPORT: 'לתמיכה עם נציג בטלפון',
    CHAT_INTRO_DESC: 'לצאט עם נציג לחץ על סמל התמיכה בפינה התחתונה למטה',
    
  },
  ASIDE: {
    MAIN: 'ראשי',
    TOURISM: 'תיירות',
    COMMUNICATION: 'תקשורת',
    PAYMENTS: 'תשלומים',
    GIFT_CARD: 'Gift card',
    REPORTS: 'דוחות',
    SECOND_LEVEL: 'רמה שניה',
    SECOND_LEVEL_OPEN: 'רמה שניה פתוח',
    THIRD_LEVEL: 'רמה שלישית',
    THIRDLY: 'רשלישיתמה',
    CANCEL_SALE: 'ביטול מכירה',
    SYSTEM_SETTINGS: 'הגדרות מערכת',
    MANAGEMENT: 'ניהול',
    CATEGORIES_PRODUCTS: 'קטגוריות ומוצרים',
    VENDORS: 'ספקים',
    PRODUCTS: 'מוצרים',
    TOURISM_PROVIDERS: 'ספקי תיירות',
    TOURISM_PRODUCTS: 'מוצרי תיירות',
    TOURIST_DESTINATIONS: 'יעדי תיירות',
    HOTELS: 'מלונות',
    SPORT: 'ספורט',
    EXHIBITIONS: 'תערוכות',
    IMPRESSIONS: 'הופעות',
    MARKETERS: 'משווקים',
    PRIVATE_CUSTOMERS: 'לקוחות פרטיים',
    SALES_REPORTS: 'דוחות מכירה',
    BOOKKEEPING: 'הנהלת חשבונות',
    ANALYSIS_STATISTICS: 'ניתוח וסטטיסטיקה',
    MANAGE_TRANSLATION: 'נהל תרגום',
    GENERAL: 'כללי',
    TOURISM_PROFIT_PERCENTAGES: 'אחוזי רווח תיירות',
    PROFIT_PERCENTAGES: 'אחוזי רווח',
    PROFIT_COMMISSIONS: 'עמלות רווח',
    COPY_PROFIT_PERCENTAGE_TO_PRODUCT: 'העתקת אחוז רווח למוצר',
    AUTHORIZES_PROVIDER_TO_PROVIDER: 'הרשאות מספק לספק',
    COPY_RESELLER_PROFILE: 'העתקת פרופיל משווק',
    TAG_MANAGMENT: 'ניהול תגיות',
    RESELLER_TOUR: 'תירות משווקים',
    TOURISM_REPORT: 'דוח תיירות',
    SALES_AND_PAYMENTS: 'מכירות ותשלומים',
    CONTRACT_DATA: 'נתוני חוזה',
    SHANIV_PRODUCTS: 'מוצרי שניב',
    SHANIV_PACKAGES: 'ניהול חבילות',
    SHANIV_CUSTOMERS: 'לקוחות שניב',
    SALES: 'מכירות',
    TRANSACTIONS: 'טרנזקציות',
    OBLIGO: 'אובליגו',
    TICKETSUMMARY: 'ריכוז כרטיסים',
    BALANCEBYDATE: 'יתרה לפי תאריך',
    CREDITCARDHISTORY: 'היסטורית מסגרת אשראי',
  },
  HEADER: {
    CUSTOMER_NUMBER: 'מספר לקוח',
    UPDATES_ANNOUNCEMENTS: 'עדכונים והודעות',
    PERSONAL_AREA: 'איזור אישי',
    LOG_OFF: 'התנתק',
    SHORTCUT_SHANIV: 'קיצור דרך שניב',
    SHORTCUT_SHORTCUT: 'קיצור דרך פלאפייד',
    CREATE_SHORTCUT: 'צור קיצור דרך',

  },
  DASHBOARD: {
    DIAL_CARDS: 'כרטיסי חיוג',
    STARTING_NIS: 'החל מ-{num} ש"ח',
    VACATION_PACKAGES: 'חבילות נופש',
    STARTING_VACATION: 'החל מ-$199',
    GIFT_CARDS: 'Gift cards',
    PRODUCT_NAME: 'שם המוצר',
    PARIS: 'פריז',
    BERLIN: 'ברלין',
    ROME: 'רומא',
    LONDON: 'לונדון',
    WEEKEND_HOTEL: 'סופ"ש מלון {num} כוכבים',
    PRODUCT_DESCRIPTION: 'תיאור המוצר',
    FAVORITES: 'מועדפים',
    CALLING_CARD: 'כרטיס חיוג',
    GAMES: 'משחקים',
    VACATION_PACKAGES_TO_SELECTED_DESTINATIONS: 'חבילות נופש ליעדים נבחרים',
    ORGANIZED_TRIPS_TO_SELECTED_DESTINATIONS: 'טיולים מאורגנים ליעדים נבחרים',
  },
  TOURISM: {
    FROM: 'המראה',
    TO: 'יעד',
    PASSENGERS: 'נוסעים',
    ADULTS: 'מבוגרים',
    ADULT: 'מְבוּגָר',
    CHILD: 'יֶלֶד',
    CHILDREN: 'ילדים',
    BOY: 'ילד',
    BUDGET: 'תקציב',
    DEGREE_HOSPITALITY: 'דרגת אירוח',
    PROMOTIONS: 'מבצעים',
    CASINO: 'קזינו',
    EXHIBITIONS: 'תערוכות',
    IMPRESSIONS: 'הופעות',
    SUNBATHING: 'בטן גב',
    SUN_FUN: 'שמש וכיף',
    LAST_MOMENT: 'רגע אחרון',
    SYLVESTER: 'סילבסטר',
    TO_ALL_FAMILY: 'לכל המשפחה',
    ATTRACTIVENESS: 'אטרקטיות',
    RECOMMENDED: 'מומלצים',
    POPULAR: 'פופלריים',
    STARTING_FROM: 'החל מ',
    TOUR: 'טיול',
    TYPE_OF_DEAL: 'סוג העסקה',
    PRICE_SHOWN_FOR_PACKAGE_DEAL_ON_DATES: 'המחיר המוצג הוא עבור עסקת חבילה בתאריכים',
    PRICE_SHOWN_FOR_ORGANIZED_TOUR_ON_DATES: 'המחיר המוצג הוא עבור טיול מאורגן בתאריכים',
    VACATION_PACKAGES_TO_DESTINATION_ON_VARIETY_DATES: 'חבילות נופש ל - {DES} במגוון תאריכים',
    ORGANIZED_TOUR_TO_DESTINATION_ON_VARIETY_DATES: 'טיול מאורגן אל {DES} במגוון תאריכים',
    FIND_DATES: 'מצא תאריכים',
    CHOOSE_VACATION_PACKAGE: 'בחר חבילת נופש',
    DES_VACATION_FOR_NIGHTS: '{DES} חבילת חופשה ל- {NUM} לילות',
    SHOW_MORE: 'להראות יותר',
    ANYWHERE: 'בְּכָל מָקוֹם',
    NOFSHON: 'NOFSHON',
    ORGANIZED_TOUR: 'טיולים מאורגן',
    ORGANIZED_TOURS: 'סיורים מאורגנים',
    ALL: 'את כל',
    ALL_DESTINATIONS: 'כל היעדים',
    VIEW_ALL_DEAL_DESTINATION: 'צפו בכל יעד העסקה',
    PACKAGE_DEALS: 'מבצעי חבילה',
    SORT: 'סוג',
    FILTER: 'לְסַנֵן',
    DEPARTURE_DATE: 'תאריך עזיבה',
    LOW_TO_HIGH: 'נמוך עד גבוה',
    HIGH_TO_LOW: 'גבוה לנמוך',
    NON_STOP: 'ללא הפסקה',
    STOP_NUM: 'עצירה {NUM}',
    STOPS: 'עוצר',
    NUMBER_OF_NIGHTS: 'מספר לילות',
    ACCOMMODATION_STAR_RATING: 'אירוח דירוג כוכבים',
    NON: 'לֹא',
    BOARD_BASIS: 'בסיס מועצה',
    ROOM_ONLY: 'חדר בלבד',
    BED_BREAKFAST: 'שינה וארוחת בוקר',
    HALF_BOARD: 'חצי פנסיון',
    FULL_BOARD: 'פנסיון מלא',
    PRICE_RANGE: 'טווח מחירים',
    TIMES: 'פִּי',
    TAKE_OFF: 'להמריא',
    LANDING: 'נְחִיתָה',
    TAKE_OFF_FROM_PLACE: 'המראה מ- {PLACE}',
    LANDING_FROM_PLACE: 'נחיתה מ- {PLACE}',
    CLEAR_FILTERS: 'נקה פילטרים',
    DEAL_CHANGED: 'שים לב שהעסקה שנבחרה שונתה',
    PLEASE_CHECK_OTHER_DEALS: 'מצטער שהחמצת את עסקת החבילה - אנא בדוק את המבצעים האחרים',
    SPORT_EVENTS: 'אירועי ספורט',
    WORKING_WITH: 'עובד עם',
    TOP_CATEGORIES_QUICK_SELECT: 'קטגוריות מובילות בחירה מהירה',
    SEARCH_YOUR_DESIRE: 'חפש את הרצון שלך',
    ORGANIZED_TOURS_TO_SELECTED_DESTINATIONS: 'טיולים מאורגנים ליעדים נבחרים',
    TRADE_SHOWS: 'תערוכות',
    FREE_SEARCH: 'חיפוש חינם',
    RECOMMENDED_TRADE_SHOWS: 'תערוכות מומלצות',
    UPCOMING_TRADE_SHOWS: 'תערוכות בקרוב',
    INFO: 'מידע',
    MORE_DATES: 'עוד תאריכים',
    TRAVELERS: 'מטיילים',
    ADVERTISEMENT: 'פרסומת',
    EXHIBITION_FOR_ADVERTISING_PROMOTIONAL_ITEMS: 'תערוכת פריטי פרסום וקידום מכירות',
    ADVERTISING_TRADE_SHOWS: 'תערוכות פרסום',
    CHOOSE_VACATION_DEALS: 'בחר דיל נופש',
    VACATION_PACKAGES_TO: 'חבילות נופש אל {DES}',
    MARKETING_TRADE_SHOWS: 'שיווק תערוכות',
    TRADE_SHOW_CONTACT: 'איש קשר בתערוכה',
    AUDIENCE: 'קהל',
    CYCLE: 'מחזור',
    LOCAL_TIME: 'זמן מקומי',
    FAIR_LOCATION: 'מיקום הוגן',
    OPEN_IN_MAP: 'פתח במפה',
    FAIR_ORGANIZER: 'מארגן יריד',
    FAIRS_OF_THESE_SECTORS: 'ירידים של מגזרים אלה',
    HOTEL_DETAILS: 'פרטי המלון',
    RECOMMENDED_VACATION_PACKAGE: 'חבילת נופש מומלצת',
    BOOK: 'סֵפֶר',
    DISCLAIMER: 'כתב ויתור',
    CHOOSE_DIFFERENCT_RECOMMENDED_VACATION_PACKAGE: 'בחרו חבילת נופש מומלצת אחרת',
    VIEW_ALL_FLIGHTS: 'הצג את כל הטיסות',
    FLIGHTS_TO: 'טיסות ל',
    FULL_DETAILS: 'פרטים מלאים',
    EXPLORE_MORE_DATES: 'גלה עוד תאריכים',
    FLIGHTS_SELECTED_DESTINATIONS: 'טיסות ליעדים נבחרים',
    VIEW_ALL_VACATIONS_DEALS: "הצג את כל מבצעי החופשות",
    VACATION_PACKAGES_TO_SELECTED_DESTINATIONS: "חבילות נופש ליעדים נבחרים",
    VIEW_ALL_ORGANIZED_DEALS: 'הצג את כל העסקאות המאורגנות',
    PRICE_SPECIFIC_FLIGHT_SHOWN: 'המחיר הוא עבור הטיסה הספציפית המוצגת',
    GO_TO_DEAL: 'לך להתמודד',
    BOOK_FLIGHT: 'ספר טיסה',
    BOOK_A_DEAL: 'הזמינו עסקה',
    ORDER_DETAILS: 'פרטי הזמנה',
    TRIP_SUMMARY: 'סיכום טיול',
    PERSONAL_ITEM: 'חפץ אישי',
    CABIN_BAGGAGE: 'כבודת תא הנוסעים',
    CHECKED_BAGGAGE: 'מטען בדוק',
    BAGGAGE_INFO: 'מידע על כבודה',
    SELECT_ONE_OPTION: 'בחר אפשרות אחת',
    NO_CHECKED_BAGGAGE: 'אין כבודה רשומה',
    TIP: 'עֵצָה',
    FLIGHT_TIP_TXT: 'סע בבטחה על ידי הוספת תיק נשלח במקום. אתה יכול לקחת פחות על הסיפון ולהימנע מלהסתובב בתא הנוסעים.',
    PASSANGERS: 'נוסעים',
    TOTAL_ORDER_PRICE: 'מחיר הזמנה כולל',
    PASSENGERS_PASSPORT_DETAILS: 'נוסעים פרטי דרכון',
    ORDER_SUMMARY_TXT: 'הזמנה בתהליך - אישור הזמנה יישלח תוך 48 שעות',
    CHEAPEST_FLIGHTS: 'הטיסות הזולות ביותר',
    LAST_MINUTE_FLIGHTS: 'טיסות ברגע האחרון',
    DATE_RANGE: 'טווח תאריכים',
    DURATION: 'מֶשֶׁך',

  },
  COMMUNICATION: {
    BEST_SELLERS: 'הנמכרים ביותר',
    MESSAGES: 'הודעות',
    PRICE_USE_ABROAD_ISRAEL: "מחיר לשימוש בחו''ל ובארץ",
    OTHER_1: 'אחר 1',
    OTHER_2: 'אחר 2',
    SURFING: 'גלישה',
    GIGA_ANOTHER_2: 'גיגה אחר 2',
    VALID_ON_DAYS: 'תוקף בימים',
    PRODUCT_WITH_NO_VAT: 'ללא מע"מ',
  },
  TOURISM_SUPPLIER: {
    TITLE: 'ניהול ספקים תיירות',
    ARKIO: 'ארקיע',
    HP: 'ח.פ',
    PROFIT_PERCENTAGES: 'אחוזי רווח',
    CONTACTS: 'אנשי קשר',
    BANK_DETAILS: 'פרטי בנק',
    PROVIDER_NUMBER: 'מספר ספק',
    SUPPLIER_CODE: 'קוד ספק באמדאוס',
    NAME_PROVIDER: 'שם ספק',
    HP_DEALER: 'ח.פ/עוסק',
    CONTACT: 'איש קשר',
    MOBILE_CONTACT: 'נייד איש קשר',
    DISPLAY: 'תצוגה',
    FLIGHTS: 'טיסות',
    VACATIONERS: 'נופשונים',
    ORGANIZED: 'מאורגנים',
  },
  DESTINATION: {
    TITLE: 'ניהול יעדי תיירות',
    ALL_AIRPORTS: 'כל שדות התעופה',
    CONFIRM: 'אשר',
    COUNTRY: 'מדינה',
    DESTINATION_NAME: 'שם יעד',
    AIRPORTS: 'שדות תעופה',
    TAGS: 'תגיות',
    ORDER: 'סדר',
    VISA: 'ויזה',
    VIDEO_LINKS: 'קישורי וידאו',
    VIDEO: 'וִידֵאוֹ',
  },
  TOURISM_PRODUCT: {
    SHARE_OVER: 'שתף',
    FLIGHT_DETAILS: 'פרטי הטיסה',
    SIXTH: 'שישי',
    SABBATH: 'שבת',
    WHAT_INCLUDE: 'מה כלול בטיסה',
    OVERTIME: 'שעות נוספות',
    INVITATION: 'הזמנה',
    BOOKING_MODAL_TXT: 'לצורך השלמת ההזמנה אנא מלא את הפרטים הבאים',
    WAY_PAYMENT: 'אופן התשלום',
    CASH: 'מזומן',
    CREDIT: 'אשראי',
    DISCOUNT_MODAL_TITLE: 'שינוי מחיר חבילה ללקוח',
    DISCOUNT_MODAL_TXT: 'ניתן לייקר או לתת הנחה ללקוח',
    ORIGINAL_PACKAGE_COST: 'עלות חבילה מקורי',
    DISCOUNT_PERCENTAGE: 'אחוז הנחה',
    INCREASE: 'הוספה',
    DISCOUNT: 'הנחה',
    DISCOUNT_EXP: 'ניתן להוסיף ללא הגבלה ולתת הנחה עד לסכום של',
    PRICE_UPTO_DATE: 'מחיר מעודכן',
    GIVE_DISCOUNT: 'תן הנחה',
    GIVE_INCREASE: 'תן עלייה',
    FRONTAL_SERVICE: 'שירות פרונטלי',
    FRONTAL_CUT: 'השירות הקדמי שלך',
    BASE_COMMISSION: 'נציבות הבסיס שלך',
    TOTAL_COMMISSION: 'הנציבות הכוללת שלך',
    YOUR_COMMISSION: 'העמלה שלך',
    ROOMS: 'חדרים',
    BABIES: 'תינוקות',
    ROOM: 'חדר',
    THINGS_DOAREA_TXT: 'דברים לעשות בסביבה',
    OUT_OF_COMMISSION: 'מתוך זה עמלה',
    HOTEL_CHANGE: 'שינוי מלון',
    ADD_ROOM: 'הוסף חדר',
    FACILITIES: 'מתקנים',
    ROOM_TYPE: 'סוג חדר',
    HOSTING_BASE: 'בסיס אירוח',
    ACCOMMODATION_LEVEL: 'רמת אירוח',
    SEATS_LEFT: 'נשארו {num} מקומות',
    NO_STAR: 'ללא כוכבים',
    YOUR_SERVICE_FEE: 'עמלת השרות שלך',
    YOUR_BASE_FEE: 'עמלת בסיס שלך',
    YOUR_TOTAL_COMMISSION: 'סה"כ העמלה שלך',
    SEAT_LIMIT_TXT: 'ניתן להוסיף חדרים עד {num} אנשים',
    REMOVE_ROOM: 'הסר חדר',
    MAX_DECREASE_VALUE: 'מקסימום ירידה בערך',
    GENERAL_RULES: "חוקים כלליים",
    TRIP_ROUTE: "מסלול טיול",
    DETAILED_TOUR_PLAN: "תוכנית טיול מפורטת",
    MANUALLY_DISCOUNT: "הנחה ידנית",
    CHANGE_PRICE: "לשנות מחיר",
    CHNAGE_COMMISSION_TXT: "אתה יכול להוסיף או להקטין את העמלה שלך",
    ADD_TO_PRICE: "להוסיף למחיר",
    CURRENT_TOTAL_PRICE: "המחיר הכולל הנוכחי",
    CONTINUE_WITH_ORIGINAL_PRICE: "המשך עם המחיר המקורי",
    NO_LIMITS_FOR_ADDING: "אין גבולות להוספה",
    DISCOUNT_IS_UP_TO: "ההנחה היא עד",
    DISCOUNT_PLACEHOLDER_TXT: "הזן את סכום ההנחה עד",
    ADD_PRICE_PLACEHOLDER_TXT: "הזן את הסכום שברצונך להוסיף למחיר",
    DEAL_SUMMARY: "סיכום עסקה",
    PLACE_ORDER: "בצע הזמנה"
  },
  GIFTCARDSUP: {
    ADDITIONAL_PRODUCTS: 'מוצרים נוספים',
  },
  GIFTCARD_PRODUCT: {
    PHONE_NUMBER: 'מספר טלפון',
    PHONE_VERIFY: 'אימות מספר טלפון',
    MORE_DETAILS: 'פרטים נוספים',
    SIMILAR_PRODUCTS: 'מוצרים דומים',
    GIFTCARD: 'כרטיס מתנה',
    VERIFY_MODAL_TITLE: 'ממתין לאימות הנייד של הלקוח',
    VERIFY_MODAL_TEXT:
      'להשלמת ההזמנה, אנא העבירו את קוד האימות החד-פעמי ללקוח, כך שהוא יקליד אותו בקישור הייעודי שקיבל באמצעות הסמס שנשלח לנייד שלו',
    ONE_VCODE: 'קוד אימות חד פעמי',
    APPROVAL: 'אישור',
    CANCEL_RESERVATION: 'ביטול הזמנה',
  },
  GIFTCARD_PAYMENT_RESULT: {
    INVITATION_ACCEPTED: 'הזמנה אושרה',
    IN_TEL_AVIV: 'בתל-אביב',
    AGENT: 'סוכן',
    HAIM_LEVIATHAN: 'חיים לויתן',
    PRODUCT: 'מוצר',
    QUANTITY: 'כמות',
    TOTAL: 'סה"כ',
    VAT: 'מע"מ',
    TOTAL_PAYMENT: 'סה"כ כולל מע"מ',
  },
  GIFTCARD_PAYMENT_RESPUB: {
    TITLE: 'הקוד נקלט בהצלחה',
    ORDER_NUMBER: 'מספר הזמנה',
    BUSINESS_NAME: 'שם בית העסק',
    SUC_TEXT: 'קוד רכישה יתעדכן כאן בדקות הקרובות',
    PURCHASE_CODE: 'קוד רכישה',
    PURCHASE_EXTERNAL_ID: 'קוד כרטיס',
    SERIAL_NUMBER: "מספר סידורי",
  },
  GIFTCARD_PAYMENT_VERIPUB: {
    ONETIME_CODE: 'קוד חד פעמי',
    DESC: 'הזן את הקוד החד פעמי שניתן לך בחנות',
  },
  PAGE404: {
    ERROR_TEXT: 'אופס! הדף לא נמצא!',
  },
  MANAGE_TRANSLATION: {
    TITLE: 'נהל תרגום',
    ENGLISH: 'אנגלית',
    HEBREW: 'עִברִית',
    ARABIC: 'עֲרָבִית',
    GROUP: 'קְבוּצָה',
    KEY: 'מַפְתֵחַ',
  },
  VALIDATION: {
    REQUIRED_FIELD: 'זהו תחום חובה.',
    INVALID_VALUE: 'ערך לא תקין',
    MIN_VALUE: 'הערך המינימלי הוא {value}',
    MAX_VALUE: 'הערך המקסימלי הוא {value}',
    INTEGER: 'הערך צריך להיות מספר שלם',
    EXACT_LENGTH: 'אורך הערך צריך להיות {value}',
    CURRENT_BALANCE: 'היתרה הנוכחית היא {value}',
  },
  ALERT: {
    OPERATION_SUCCESS: 'מבצע מבצע',
    SUCCESS: 'הצלחה',
    INFORMATION: 'מידע',
    ERROR: 'שגיאה',
    MESSAGE: 'הודעה',
    NO_FILTER_DATA: 'אין נתוני סינון',
    SUCCESS_MSG: 'הפעולה בוצעה בהצלחה',
    CLOSURE: 'סגירה',
    REQUIRE_PASSPORT_TXT: 'אנא מלא את כל פרטי הדרכון תחילה',
    ERROR_PASSPORT_UPLOAD_TXT: 'אירעה שגיאה בהוצאת פרטי הדרכון מהתמונה',
    TRY_AGAIN: 'נסה שוב',
    DELETE_CONFIRM_TXT: "האם אתה בטוח שברצונך למחוק את {NAME}?"
  },
  TOUR_ORDER: {
    TRANSACTION_DETAILS: 'פרטי העסקה',
    TRANSACTION_DETAILS_TXT: 'פרטי ההזמנה נוסעים תאריכים יעד אקסטרות',
    SWITCH_TO_PAYMENT: 'עבור לתשלום',
    METHODS_OF_PAYMENT: 'אמצעי תשלום',
    TOUR_PAYMENT_TXT: 'אנא הזן את פרטי האשראי לצורך תשלום',
    TICKET_NUMBER: 'מספר כרטיס',
    VALIDITY: 'תוקף',
    DIGITS_ON_CARD: '3 ספרות בגב הכרטיס',
    CARDHOLDER_NAME: 'שם בעל הכרטיס',
    TOUR_PAYMENT_BOTTOMTXT: 'תוכל לעבור על ההזמנה לפני אישור התשלום',
    PASSPORTS_PLEASE: 'דרכונים בבקשה',
    TOUR_PASSPORT_TXT: 'צלם את הדרכונים של הנוסעים',
    PASSPORT_PHOTO: 'צילום דרכון',
    TOUR_PASSPORT_TXTCANT: 'אין באפשרותך לצלם ?',
    ENTER_MANUALLY: 'הזנת פרטים ידנית',
    TOUR_PASSPORT_MTITLE: 'נוסע זה כבר צולם',
    TOUR_PASSPORT_MTXT: 'אנא צלם דרכון נוסף שלא צולם',
    PASSENGER: 'נוסע',
    ANOTHER_PASSPORT_PHOTO: 'צילום דרכון נוסף',
    PASSPORT_INFOR_VERIFICATION: 'אימות פרטי דרכון',
    TOUR_PERSON_TXT: 'אנא בדוק את פרטי הנוסע שצולמו',
    FITST_NAME_EN: 'שם פרטי באנגלית',
    LAST_NAME_EN: 'שם משפחה באנגלית',
    NUMBER_OF_PASSPORT: 'מספר דרכון',
    ID_NUMBER: 'מספר תעודת זהות',
    DATE_OF_BIRTH: 'תאריך לידה',
    PASSPORT_BASE_COUNTRY: 'מדינה בה הונפק הדרכון',
    GENDER: 'מגדר',
    MAN: 'גבר',
    WIFE: 'אישה',
    SHOOT_AGAIN: 'צלם שוב',
    WHICH_PASSENGER_CUSTOMER: 'מי מהנוסעים הוא המזמין',
    TOUR_CONFIRM_TXT: 'פרטי ההזמנה נוסעים תאריכים יעד אקסטרות',
    ORDER_CONFIRMATION: 'אישור הזמנה',
    TOUR_CONFIRM_TXTBOTTOM: 'בלחיצה על אישור הזמנה אתה מאשר את',
    SITE_POLICY: 'תקנון האתר',
    TOUR_SUCCESS_TXT: 'ניתן לעקוב אחר ההזמנה',
    TOUR_SUCCESS_LINK: 'באיזור האישי',
    PASSPORT_UPLOAD_STATE: '{{num1}} מתוך {{num2}} דרכונים שהועלו',
    PRICE_PER_PASSENGER: 'מחיר לנוסע',
    TOTAL_PRICE: 'מחיר כולל',
    PRICE_BEFORE_DISCOUNT: 'מחיר לפני הנחה',
    PRICE_BEFORE_INCREASE: 'מחיר לפני עלייה',
    PRICE_TO_PAID: 'מחיר לתשלום',
    CANCEL_TRANSACTION: 'ביטול עסקה',
    CURRENCEY_EXCHANGE_RATE: 'שער המרת מטבע',
    TOTAL_PRICE_TXT: 'מחיר הסה"כ מוצג בשקלים ומעוגל',
    ROUND_TRIP_FLIGHT: 'טיסה הלוך חזור',
    ACCOMMODATION: 'מקום אירוח',
    PASSENGERS_VERHICLE_ROOMS: 'נוסעים והרכב חדרים',
    TRANSPORTATION: 'הסעות',
    TICKETS: 'כרטיסים',
    TV_SET: 'טלויזיה',
    FREE_PARKING: 'חניה חינם',
    BATHROOM: 'אמבטיה',
    FULL_DETAIL_HOTEL: 'לחץ לפרטים מלאים אודות המלון',
    TRANSPORTATION_TITLE: 'הסעות: איסוף וחזרה משדה התעופה',
    GO: 'הלוך',
    DISTANCE: 'מרחק',
    ESTIMATED_TRANSPORTATION_TIME: 'זמן הסעה משוער',
    TEL_AVIV: 'תל אביב',
    SELECT_CUSTOMER_TXT: 'אנא בחר את הלקוח',
    PASSPORT_EXPIRATION_DATE: 'תוקף דרכון',
    PASSENGER_PASSPORT_DETAILS: 'פרטי דרכון נוסע',
  },
  GENERAL_SETTING: {
    TOURISM_SETTINGS: 'הגדרות תיירות',
    OFF_REPLACEMENT_TXT: 'הצע חבילות חליפיות כאשר המחיר יקר עד',
    PERCENT: 'אחוז',
    PERCENT_ABSORPTION_TXT: 'אחוז ספיגת התייקרות לרכישה במזומן',
    DAYS_MARKETER_TXT: 'מספר ימים לזכאות משווק לעמלה',
    MINIMUM_PROFIT_TXT: 'אחוז רווח מינימלי לפלאפייד',
    ADD_PENNY_TXT: 'הוספת אגורות לעמלת המרת מטבע',
    DAYS: 'ימים',
    PENNIES: 'אגורות',
    ORGANIZED_GENERAL_RULES: "חוקים כלליים מאורגנים",
    ORGANIZED_CANCELLATION_DETAILS: "פרטי ביטול מאורגן",
    PASSPORT: "דַרכּוֹן",
    ACURRA:"אקורה",
    MRZ_SCANNER:"סורק mrz",
  },
  TOURISM_PROFIT_PERCENTAGES: {
    DISTRIBUTOR: 'מפיץ',
    MARKETER: 'משווק',
    CLIENT_NO: 'מס לקוח',
    MOBILE_PHONE: 'טלפון נייד',
    HP_AUTHORIZED_DEALER: 'ח.פ / עוסק מורשה',
    PLOTTERS: 'פלאפייד',
    PLATORS_ALL_RESELLERS: 'פלאפייד כלל המשווקים',
    SEARCH_CUSTOMER_NUMBER: 'מספר לקוח לחיפוש',
    MOBILE_PHONE_SEARCHING: 'טלפון נייד לחיפוש',
    PERCENTAGE_CASH_PROFIT: 'אחוז רווח מזומן',
    DIRECT_SALES_COMMISSION: 'עמלת מכירה ישירה',
    REFERRAL_SALES_COMMISSION: 'עמלת מכירה הפניה',
    CREDIT_PROFIT_PERCENTAGE: 'אחוז רווח אשראי',
    SALES_COMMISSION_DIRECT_LINK: 'עמלת מכירה קישור ישיר',
    INDIRECT_LINK_SALES_COMMISSION: 'עמלת מכירה קישור עקיף',
    FRONTAL_REPRESENTATIVE_FEE: 'עמלת נציג פרונטלי',
    FRONTAL_TXT: 'סכום אוטומטי לפי מטבע העסקה',
  },
  RESELLER_TOUR_SETTINGS: {
    RESELLER_TOUR_SETTINGS: 'הגדרות תירות משווקים',
    RESELLER_ID: 'מזהה משווק',
    DIRECT_SALE_FEE: 'דמי מכירה ישירה',
    AFFILIATE_SALE_FEE: 'עמלת מכירת שותפים',
    CREDIT_SALE_FEE: 'עמלת מכירת אשראי',
    LINK_SALE_FEE: 'דמי מכירה של קישור',
    SECONDLINK_SALE_FEE: 'מחיר מכירת קישור שני',
    FRONT_SALE_FEE: 'דמי מכירה קדמיים',
    DIRECTFRONT_SALE_FEE: 'מחיר מכירה ישיר קדמי',
    EDIT_RESELLER_SETTINGS: 'ערוך את הגדרות המשווק',
    ADD_RESELLER_SETTINGS: 'הוסף הגדרות מפיץ',
  },
  REGISTER_RESELLER: {
    MARKETER_REGISTRATION_BUSINESS: 'רישום משווק: פרטי העסק',
    WELCOME_ENTER_TXT: 'ברוכים הבאים, נא הזינו את הפרטים הבאים',
    EMAIL_ACCOUNTING: 'דואר אלקטרוני הנהלת חשבונות',
    VENDOR_PROFILE: 'פרופיל ספקים',
    PROFILE_PERCENTAGE_PROFILE: 'פרופיל אחוזי רווח',
    FEES_PROFILE: 'פרופיל עמלות',
    TOURISM_PERCENTAGE_PROFILE: 'פרופיל אחוזי תיירות',
    BUSINESS_NAME: 'שם העסק',
    AUTHORIZED_DEALER_HP: 'עוסק מורשה/ח.פ',
    TYPE_OF_BUSINESS: 'סוג עסק',
    BUSINESS_PHONE: 'טלפון בעסק',
    MOBILE_PHONE_CONTACT: 'טלפון נייד איש קשר',
    ARTICLE: '{num} סעיף',
    FRIDAY_HOLIDAY_EVES: "יום ו' וערבי חג",
    SATURDAYS_HOLIDAYS: 'שבתות וחגים',
    SUNDAYS_THURSDAYS: "ימים א'-ה'",
    TITLE2: 'רישום משווק: פרטי בעלים והתחברות',
    ENTER_FOLLOWING_DETAILS: 'אנא הזינו את הפרטים הבאים',
    ADDING_ADDITIONAL_OWNERS: 'הוספת בעלים נוספים',
    TITLE3: 'רישום משווק: אבטחה ופרטי תשלום',
    LINE_OF_CREDIT: 'מסגרת אשראי',
    BANK_ACCOUNT_INFORMATION: 'פרטי חשבון בנק',
    BANK: 'בנק',
    BANK_NUMBER: 'מספר בנק',
    ACCOUNT_NUMBER: 'מספר חשבון',
    BRANCH: 'סניף',
    COLLECTION_DAYS: 'ימי גביה',
    A: 'א',
    B: 'ב',
    C: 'ג',
    D: 'ד',
    E: 'هـ',
    F: 'ו',
    REMARKS: 'הערות',
    TITLE4: 'רישום משווק: ניהול מסמכים',
    AUTHORIZED_DEALER_CERTIFICATE: 'תעודת עוסק מורשה',
    COMMUNICATION_CONTRACT: 'חוזה התקשרות',
    PERSONAL_GUARANTEE: 'ערבות אישית',
    HAWK_APPROVAL: 'אישור הו"ק',
    ADD_A_DOCUMENT: 'הוסף מסמך',
    FINISHED: 'סיים',
    DOCUMENT_TYPE: 'סוג מסמך',
    UPLOAD_DOCUMENT: 'העלאת מסמך',
    CHIC_PHOTO: 'תצלום שיק',
    AGENT_RESPONSIBLE: 'סוכן אחראי',
    DEFINE_SELLER_AS_A_DISTRIBUTOR: 'הגדר את המוכר כמפיץ',
    AS_DISTRIBUTE_WARNING_TXT: '* תשומת הלב! לא ניתן לבטל את החיסכון.',
  },
  REPORT_SALES: {
    SALES: 'מכירות',
    SALES_REPORT: 'דוח מכירות',
    TIME_FILTERING: 'סינון זמנים',
    FROM_DATE: 'מתאריך',
    UNTIL: 'עד תאריך',
    START_HOUR: 'משעה',
    END_HOUR: 'עד שעה',
    WITHOUT_TIME_LIMITS: 'ללא מגבלות זמן',
    RANGE_OF_DATES: 'טווח תאריכים',
    GENERAL_FILTERING: 'סינון כללי',
    FORM_OF_PAYMENT: 'סוג תשלום',
    MOBILE: 'נייד',
    CONSUMER_PRICE: 'מחיר לצרכן',
    COST_PRICE: 'מחיר עלות',
    STATUS: 'סטטוס',
    REQUEST_FOR_CANCELLATION: 'בקשה לביטול',
    CELLCOM: 'סלקום',
    AMAZON: 'אמזון',
    NETFLIX: 'נטפליקס',
    MATT: 'מתמ',
    COMPLETED: 'הושלם',
    BY_INVITATION: 'בהזמנה',
    OUT_OF: 'אזל',
    UTILIZED: 'נוצל',
    APPLY: 'הגש בקשה',
    IN_TREATMENT: 'בטיפול',
    CANCELED: 'בוטל',
    PARTIALLY_CANCELED: 'בוטל חלקי',
    FOR_FULL_DETAILS: 'לפרטים מלאים',
    NUMBER_OF_PASSENGERS: 'כמות נוסעים',
    HOSTING: 'אירוח',
    STARS: 'כוכבים',
    HOTEL: 'מלון',
    DESTINED: 'יעד',
    CUSTOMER_NAME: 'שם לקוח',
    TRANSACTION_TYPE: 'סוג עסקה',
    NUMBER_OF_TRANSACTION: 'מספר עסקה',
    EXPORT_TO_EXCEL: 'יצוא לאקסל',
    FOR_PRINTING: 'להדפסה',
    SEARCH: 'חפש',
    EVERYTHING: 'הכל',
    START_DATE: 'תאריך התחלה',
    END_DATE: 'תאריך סיום',
    SUPPLIER_ID: 'ספק',
    EXTERNAL_ORDER_ID: 'מזהה הזמנה חיצוני',
    GIFT_CARD_CODE: 'קוד כרטיס מתנה',
    CONTRACT_NUMBER: 'מספר חוזה',
    BILL_NUMBER: 'מספר חשבון',
    CANCELLED: 'מבוטל',
    OPENDED: 'נפתח',
    SUCCESS: 'הַצלָחָה',
    PENDING: 'ממתין ל',
    FAILED: 'נִכשָׁל',
    INVOICES_RECEIPTS: 'חשבוניות וקבלות',
    VIEWING: 'צפייה',
    DOWNLOAD: 'הורדה',
    RECEIPT_TAX_INVOICE: 'מס קבלה/ חשבונית',
    RAV_KAV_TRANSACTION: 'מספר טרנזקציה ברב קו',
    RAV_KAV_CARD_NUMBER: 'מספר כרטיס',
    CONTRACT_TYPE: 'סוג חוזה',
    CONTRACT_NAME: 'שם חוזה',
    CANCEL_STATUS: 'סטטוס ביטול עסקה',
    AWAITING: 'ממתין',
    REJECT: 'נדחה',
    NO_CANCEL_REQUEST: 'לא הוגשה בקשה לביטול',
    DISTRIBUTER_FILTERING: 'סינון לפי משווק',
    DISTRIBUTER: 'מפיץ',
    BUSINESS_NAME: 'שם עסק',
    CLIENT_NO: 'מס לקוח',
    AGENT_CODE: 'קוד סוכן',
    PHONE_NUMBER: 'מספר טלפון',
    AUTHORIZED_DEALER_HP: 'עוסק מורשה/ח.פ',
    IP: 'IP',
    TAGS: 'תגיות',
    SELLER_POINTS: 'נקודות משווק',
    DISTRIBUTER_PRICE: 'מחיר מפיץ',
    AGENT: 'סוכן',
    CITY: 'עיר',
    CUSTOMER_ID: 'מס. לקוח',
    DEAL_ID: 'מס. עסקה',
    BUSINESS_PRICE: 'מחיר משווק',
    AGENT_ID: 'מס. סוכן',
    GAME_CARD_CODE: 'קוד כרטיס משחק',
    SERIAL_NUMBER: "מספר סידורי",
    PIN_CODE: "קוד סודי",
    NOT_CANCELLED: 'לא בוטל',
    REJECTED: 'נִדחֶה',
    CANCEL_ACTION: 'בטל פעולה',
    STATUS_DATE: 'תאריך סטטוס',
    STATUS_START_DATE: 'תאריך התחלה של סטטוס',
    STATUS_END_DATE: 'תאריך סיום סטטוס'
  },
  CARMEL_TUNNELS: {
    PAYMENT_CARMEL_TUNNELS: 'תשלום מנהרות הכרמל',
    ID_NUMBER: 'מספר ת.ז/ח.פ',
    INVOICE_LAST_DIGITS: '6 ספרות אחרונות של מספר חשבונית',
    INVOICE_SUM: 'סכום חשבונית',
    PAYMENT_SUM: 'סכום לתשלום',
    MOBILE_PHONE_NUMBER: 'מספר טלפון נייד',
    RESULT_TXT: 'בסיום התשלום צריך לספק ללקוח קבלה בלבד!',
    WHOLE: 'שלם',
    VERIFICATION_BEFORE_LOADING: 'אימות פרטי לקוח לפני ביצוע הטעינה',
    DETAILS_PLACE_TRANSACTION: 'פרטי מקום העסקה',
    TRANSACTION_APPROVAL: 'מספר אישור עסקה',
    INVOICE_DETAILS: 'פרטי החשבונית',
    NUMBER_CLEARINGHOUSE: 'מספר מסלקה',
    SERVICE_FEE: 'עמלת שרות',
    AFTER_VERIFY_TXT: 'לאחר אימות הפרטים לא ניתן לבטל את העסקה!',
    CONFIRMATION_PURCHASE: 'אישור פרטים וביצוע רכישה',
  },
  SUPPLIER_PAYMENT: {
    BACK: 'חזור',
    PRICE: 'מחיר',
    ORDER: 'הזמן',
    RESULTS: 'תוצאות',
    CANCEL: 'בטל',
    CANCEL_PAYMENT: 'בטל עסקה',
    TRANSACTION_DETAILS: 'פרטי העסקה',
    CONTRACT_TYPE: 'סוג חוזה',
    CONTRACT_DETAILS: 'פרטי החוזה',
    CONTRACT_PRICE: 'סכום העסקה',
    OPERATOR: 'מפעיל',
    PROFILE: 'סוג פרופיל',
    CONTRACT_TIME: 'זמן ביצוע העסקה',
    PURCHASE_DESCRIPTION: 'סיכום פרטי הרכישה',
    PURCHASE: 'לרכישה',
    APPROVAL: 'אישור',
    NO_CARD: 'אין בקורא כרטיס רב קו, נא להכניס',
    LOAD_MULTILINE_CARD: 'טעינת כרטיס רב-קו',
    RAVKAV_READER_DISCONNECTED: 'לא נמצא קורא כרטיסים מחובר - אנא חבר קורא כרטיסים ונסה שנית',
    RAVKAV_NO_CARD: 'לא נמצא כרטיס בקורא כרטיסים - אנא הכנס כרטיס רב קו לקורא הכרטיסים ונסה שנית',
    RAVKAV_FAILURE_CARD_EXTRACTED: 'כרטיס הרב קו הוצא מקורא הכרטיסים - אנא הכניסו את הכרטיס ונסו שנית',
    MULTILINE_CARD_TXT1: 'הכנס כרטיס רב-קו לקורא הכרטיסים שהשבב מופנה מעלה',
    MULTILINE_CARD_TXT2:
      'אם תוכנת רב קו אינה מותקנת על המחשב, יש להוריד אותה ולהתקין לפני השימוש.',
    CANCELATION: 'ביטול',
    CLICK_VIEW_CHARGE_CARDS: 'לחץ להצגת חוזי טעינה לכרטיס',
    CLICK_OPEN_POP_UP: 'לחץ לפתיחת החלון הקופץ',
    POPUP_MUST_CONFIRMED: 'חובה לאשר את החלון שקופץ',
    WINDOWS: 'ווינדוס',
    UBUNTU: 'אובונטו',
    MACINTOSH: 'מקינטוש',
    MONTHLY_SENSES: 'חופשי חודשי',
    FREE_DAILY: 'חופשי יומי',
    FREE_WEEKLY: 'חופשי שבועי',
    FREE_FLEXIBLE: 'חופשי גמיש',
    FREE_MONTHLY: 'חופשי חודשי',
    ACCUMULATED_VALUE: 'ערך צבור',
    JERUSALEM: 'ירושלים',
    MORE_FILTERS: 'עוד סינונים',
    RAVKAV_CONFIRM_TXT: 'טעינת כרטיס הרב-קו בוצע בהצלחה',
    MULTILINE_CARD_NUMBER: 'מספר כרטיס רב-קו',
    CARD_TYPE: 'סוג כרטיס',
    CARD_LOADING: 'טעינה בכרטיס',
    FINISHED_AND_CLOSED: 'סיים וסגור',
    BUS_CARD: 'רב-קו',
    UPGRADE_TO: 'שדרג ל',
    MULTILINE_TRAVEL_CONTRACTS: 'חוזי נסיעה בכרטיס הרב-קו',
    EXPIRED: 'לא בתוקף',
    CLAIM: 'טען',
    PAYMENT_INSTANT_INVOICE: 'תשלום חשבונית בזק',
    ID_NUMBER: 'מספר תז',
    INVOICES: 'חשבוניות',
    AMOUNT_OF_INVOICES: 'סכום חשבוניות',
    CHECK: 'בדוק',
    INTERNET_SUBSCRIPTION_NUMBER: 'מספר מנוי אינטרנט',
    ID_OWNER_HP: 'ת.ז של בעל הקו/ח.פ',
    BEZEQ_TXT1: 'לפי רשומי בזק, חשבוניות להלן טרם שולמו',
    BEZEQ_TXT2: 'תוכלו להסיר סימון מחשבוניות שתרצו לשלם במועד אחר',
    DEADLINE_FOR_PAYMENT: 'תאריך אחרון לתשלום',
    INVOICING: 'חשבונית',
    TOTAL_SELECTED_INVOICES: 'סך חשבוניות נבחרות',
    TOTAL_PAYMENT: 'סה״כ לתשלום',
    BEZEQ_TXT3: '* ישנה עמלה של 7 ש"ח בגין כל חשבונית בזק',
    BEZEQ_FOOTER_TXT: 'לאחר ביצוע התשלום לא ניתן לבטל את העסקה!',
    PAYMENT_POLICE_REPORTS: 'תשלום דוחות משטרה',
    PAYER_CUSTOMER_NUMBER: 'מספר משלם/לקוח',
    REPORT_NUMBER: 'מספר דוח/ת',
    POLICE_REPORT_NUMBER: 'מספר דוח משטרה',
    POLICE_REPORT: 'דוח משטרה',
    ID: 'ת.ז',
    COMPANY: 'חברה',
    PASSPORT: 'דרכון',
    DRIVING_LICENSE: 'רשיון נהיגה',
    POLICE_DATABASE_TXT: 'במאגר המשטרה נמצאו 3 דוחות פתוחים לתשלום',
    PORTFOLIO_VOUCHER_TAX: 'מס תיק מרכז/מס שובר בהסדר',
    DEBT_FILE_FACTOR: 'מספר תיק גורם מטיל החוב',
    DATE_OF_PAYMENT: 'תאריך לתשלום',
    FOUNDATION: 'קרן',
    ARREARS_AND_EXPENSES: 'פיגורים והוצאות',
    NUMBER_SELECTED_REPORT: 'כמות דוחות שנבחרו',
    TOTAL_SUM_ACCOUNTS: 'סה"כ סכום חשבונות',
    MOBILE_PHONE_RECEPTION: 'מספר טלפון נייד לקבלה',
    POLICE_FOOTER_TXT1: 'שים לב ! הלקוח מודע',
    POLICE_FOOTER_TXT2:
      'שאם קיים על שמך חוב מסוג פיצוי לנפגע עבירה בהליך פלילי, כל סכום שישולם ייזקף תחילה לטובת כיסוי חוב זה',
    PURCHASE_POWER_CODE_TXT: 'רכישת קוד טעינת חשמל',
    PAY_ATTENTION_TXT:
      'שימו לב!! לפי הנחיות חברת החשמל, אין לחייב את הלקוח בעמלה',
    COMMISSION_DES_TXT:
      '** עמלה בגין תשלום זה במערכת הינו [commission_amount] שח',
    CUSTOMERS_NAME: 'שם הלקוח',
    PURCHASE_AMOUNT: 'סכום רכישה',
    TOTAL_NET_EXCLUDING_VAT: 'סה"כ סכום נטו (ללא מע"מ)',
    KWH: 'קוט"ש',
    PAYMENT_DEBTS_TXT: 'סכום עבור תשלום חובות בפריסת תשלומים',
    TOTAL_CHARGES_ADDITIONAL_CREDITS: 'סך חיובים - זיכויים נוספים',
    BUSINESS_ADDRESS: 'כתובת עסק',
    LOADING_CODE: 'קוד טעינה',
    MUNICIPAL_TXT1: 'תשלום לרשויות - ארנונה באר שבע',
    MUNICIPAL_TXT2: 'תשלום לרשויות - ארנונה באר שבע בוצע בהצלחה',
    PAYER_CUSTOMER_ID: 'מספר משלם/לקוח/ת.ז',
    CLEARANCE_VOUCHER_APPENDIX_NUMBER: 'מספר מסלקה/שובר/ספח',
    PAYMENT_TO_AUTHORITIES: 'תשלום לרשויות',
    SELECT_PERMISSION: 'בחר רשות',
    TYPE_PAYMENT_AUTHORITY: 'סוג תשלום ברשות',
    PAYMENT_6CROSSES_NORTH: 'תשלום 6 חוצה צפון',
    PAYMENT6_SUCCESS_TXT: 'תשלום 6 חוצה צפון בוצע בהצלחה',
    REQUESTED_PRICE: 'מחיר מבוקש',
    COMMISSION: 'עמלה',
    TOTAL_PRICE: 'מחיר סופי',
    LICENSE: 'רישיון',
    SELECT_ALL: 'בחר הכל',
    ROAD6_COMMISSION_DESCRIPTION:
      'ישנה עמלה של [commission_amount_text] עבור כל חשבונית',
    ROAD6_TOTAL_PRICE_DESCRIPTION:
      'נבחרו [selected_options_count_text] בסכום כולל של [selected_options_price] - [total_price_text]',
    PHONE_NUMBER: 'אנא הזן מספר טלפון',
    PAYMENT_FAILED: "התשלום נכשל, אנא צור קשר עם התמיכה או נסה שוב מאוחר יותר",
    SOMETHING_WENT_WRONG: "משהו השתבש, אנא צור קשר עם התמיכה או נסה שוב מאוחר יותר",
    INCORRECT_CODE: "קוד שגוי",
    PAYMENT: 'תַשְׁלוּם',
    PAY: 'לְשַׁלֵם',
    PAID: 'שולם',
    METER_NUMBER: 'מספר מטר',
    APPROVAL_NUMBER: 'מספר אישור'
  },
  TOURISM_REPORT: {
    TRANSACTION_DETAILS: 'פרטי עסקה',
    PURCHASE_DATE: 'תאריך רכישה',
    PAYMENT: 'תשלום',
    A_CONFIRMATION: 'ת.אישור',
    NO_OF_PASSENGERS: "מס' נוסעים",
    TOTAL: 'סה"כ',
    SELLING_RESELLER: 'משווק מוכר',
    PASSPORT_DETAIL_TXT: 'אנא בדוק את פרטי הנוסע שצולמו תקנו/אשרו במידת הצורך',
  },
  PAYMENT_REPORT: {
    AMOUNT_RECEIVED: 'סכום שהתקבל',
    TYPE_OF_PAYMENT: 'סוג התשלום',
    FOR: 'עבור',
    RECEPTION_NUMBER: 'מספר קבלה',
    PAYMENT_REPORT: 'דוח תשלומים',
  },
  SALES_PAMENT_REPORT: {
    SALES_PAMENT_REPORT: 'דוח מכירות ותשלומים',
    TAX_ACCEPTANCE_TRANSACTION: 'מס. עסקה קבלה',
    CONSUMER_PRICE: 'מחיר צרכן',
    CURRENT_BALANCE: 'יתרה נוכחית',
  },
  FACILITY: {
    AIRCONDITION: 'מיזוג אוויר',
    HAIRDRYER: 'מייבש שיער',
    PHARMACY: 'בֵּית מִרקַחַת',
    BABYSITTING: 'ישיבה לתינוקות',
    HAIRDRESSER: 'מעצבת שיער',
    PHONEDIRDIAL: 'טלפון Dirdial',
    BAR: 'בָּר',
    HANDICAP: 'מִגבָּלָה',
    RESTAURANTS: 'מסעדות',
    BEACH: 'החוף',
    HEALTHCLUB: 'מועדון בריאות',
    ROOMSERVICE: 'שירות חדרים',
    BEAUTYPARLOUR: 'מכון יופי',
    ICEMACHINES: 'מכונות קרח',
    SAFEDEPBOX: 'Safe Dep Box',
    BUSINESSCTR: 'שיעור קליקים עסקי',
    JACUZZI: "ג'קוזי",
    SAUNA: 'סָאוּנָה',
    CARRENTAL: 'השכרת רכב',
    KITCHEN: 'מִטְבָּח',
    SIGHTSEEING: 'סִיוּר',
    CASINO: 'קָזִינוֹ',
    LAUNDRYSVC: 'מכבסה SVC',
    SNOWSKIING: 'סקי שלג',
    COFFEESHOP: 'בית קפה',
    LOUNGE: 'טְרַקלִין',
    SOLARIUM: 'סולריום',
    CONVENTIONCTR: 'שיעור קליקים בכנסים',
    MASSAGE: 'הוֹדָעָה',
    SPA: 'ספָּא',
    DISCO: 'דִיסקוֹ',
    MEETINGROOMS: 'חדרי ישיבות',
    SWIMMINGPOOL: 'בריכת שחיה',
    SWIMMINGPOOLINDOOR: 'בריכת שחייה מקורה',
    ELEVATOR: 'מַעֲלִית',
    MINIBAR: 'מיניבר',
    TENNIS: 'טֶנִיס',
    EXCHANGEFAC: 'החלף FAC',
    MOVIECHANNELS: 'ערוצי סרטים',
    TELEVISION: 'טֵלֶוִיזִיָה',
    EXECUTIVEFLR: 'FLR מנהלים',
    NONSMOKINGRMS: 'RMS ללא עישון',
    VALETPARKING: 'שירות חניה',
    FAXFACINROOM: 'חדר פנים בפקס',
    PARKING: 'חֲנָיָה',
    WATERSPORTS: 'ספורט מים',
    GIFTSHOP: 'חנות מתנות',
    PCHOOKUPINRM: 'מחשב מחובר ב- RM',
    WOMENSGSTRMS: 'נשים GSTR נשים',
    GOLF: 'גוֹלף',
    PETSACCEPTED: 'מקבלים חיות מחמד',
  },
  TOUR_PLACES: {
    RECOMMENDED_TOURIST_SITES: 'אתרי תיירות מומלצים',
    SHOPPING: 'שופינג',
    FOOD_AND_RESTAURANTS: 'אוכל ומסעדות',
    MUSEUMS: 'מוזיאונים',
    READ_MORE: 'קרא עוד',
    LOCAL_NAME: 'שם מקומי',
    LOCATION: 'מיקום',
    MORE_INFO_CONTACT: 'מידע נוסף ויצירת קשר',
    WIKIPEDIA: 'ויקיפדיה',
    OFFICIAL_SITE: 'אתר רשמי',
    ENTRANCE_FEE: 'דמי כניסה',
    COORDINATES: 'קואורדינטות',
    REDUCED: 'מופחת',
    CHILDREN_LABEL: 'ילדים (מתחת לגיל 18)',
    FREE: 'חינם',
  },
  ROOM: {
    REGULAR: 'רגיל',
    CLASSIC_ROOM: 'חדר קלאסי',
    DELUXE: 'דלוקס',
    JUNIOR_SUITE: "סוויטת ג'וניור",
    REGULAR_ROOM_STANDARD: 'תקן חדר רגיל',
    SEA_VIEW_STANDARD: 'תקן נוף לים',
    STANDARD_ROOM: 'חדר סטנדרטי',
    APARTMENT_ONE_BEDROOM: 'דירה עם חדר שינה אחד',
    APARTMENT_TWO_BEDROOMS: 'דירה עם שני חדרי שינה',
    STUDIO: 'סטוּדִיוֹ',
    STANDARD: 'תֶקֶן',
    STANDART: "STANDART",
    FAMILY_ROOM: 'חדר משפחה',
    DELUXE_ROOM: 'חדר דלוקס',
    SUPERIOR_ROOM: 'חדר ליגה',
  },
  FARE_BASIS: {
    AI: "AI",
    BB: "BB",
    "F+": "F+",
    FB: "FB",
    HB: "HB",
    "HB+": "HB+",
    KH: "KH",
    KS: "KS",
    RO: "RO",
    UA: "UA",
    UAI: "UAI",
    UI: "UI",
  },
  COMMUNICATION_PRODUCT: {
    CONFIRMATION_PHONE_NUMBER_MODAL_TITLE: 'אישור טלפון נייד',
    CONFIRMATION_PHONE_NUMBER_MODAL_TEXT:
      'אנא אשר את המספר [phone_number] הנכון',
  },
  RESELLER_DETAILS: {
    TEMPORARY_FRAMEWORK: 'מסגרת זמנית',
    BALANCE_FOR_UTILIZATION: 'יתרה לניצול',
    BALANCE_UPDATE: 'עדכון יתרה',
    SUM: 'סכום',
    PAYMENT_METHODS: 'אופן תשלום',
    UPDATE_BALANCE: 'עדכן יתרה',
  },
  ADS_MANAGEMENT: {
    BANNER_MANAGEMENT: 'ניהול באנרים',
    BANNERS: 'באנרים',
    ADVERTISER: 'מפרסם',
    BANNER_STATUS: 'סטטוס באנר',
    TYPE: 'סוג',
    THERE: 'שם',
    LANGUAGE: 'שפה',
    LENGTH: 'אורך',
    A_BEGINNING: 'ת.התחלה',
    A_END: 'ת.סיום',
    HOURS: 'שעות',
    PRESENTATION: 'הצגה',
    EDITING: 'עריכה',
    BOUNCE: 'הקפץ',
    ADVERTISERS: 'מפרסמים',
    NEW_BANNER_EDIT: 'באנר חדש / עריכה',
    NAME_PUBLICATION: 'שם הפרסום',
    NUMBER_OF_BOUNCES: 'כמות הקפצות',
    PAGE: 'דף',
    LINK_TO_PAGE: 'קישור לדף',
    CHOOSE_POINT_RESELLER: 'בחר משווקים נקודתיים',
    ADVERTISING_START_TIME: 'שעת התחלת פרסום',
    POST_TIME_PUBLICATION: 'שעת סיום פרסום',
    ADVERTISING_HOURS: 'שעות פרסום',
    PRICING: 'תמחור',
    PRICING_LIST: 'רשימת תמחורים',
    ADVERTISING: 'פרסומת',
    PAYMENT_METHOD: 'שיטת תשלום',
    PREVIEW: 'תצוגה מקדימה',
    THE_SIZE_PUBLICATION_IS: 'גודל הפרסום הינו',
    SHOW: 'הצג',
    PAID_UP: 'שולם',
    OPEN: 'פתוח',
    IMAGE: 'תמונה',
    VIDEO: 'סרטון',
    BANNERS_ADVERTISERS: 'באנרים - מפרסמים',
    PHONES: 'טלפונים',
    RESPONSIBLE_ACCOUNTING: 'אחראי הנה"ח',
    ACCOUNTING_TELEPHONE: 'טלפון הנה"ח',
    OPEN_DEBT: 'חוב פתוח',
    A_REGISTRATION: 'ת.רישום',
    BAIL: 'ערבות',
    CLOSE_CHARGE: 'חיוב קרוב',
    DETAIL_EDITING: 'פרטים ועריכה',
    NEW_PUBLISHER: 'מפרסם חדש',
    BACK_TO_BANNER: 'חזורה לבאנרים',
    NEW_PUBLISHER_EDITOR: 'מפרסם חדש / עריכה',
    ADVERTISER_NAME: 'שם המפרסם',
    SITE: 'אתר',
    DATE_OF_ESTABLISHMENT: 'תאריך הקמה',
    FROM_GOOGLE: 'מגוגל',
    ACCOUNTANTS: 'אנשי הנה"ח',
    TELEPHONE_BOOKS: 'טלפונים הנה"ח',
    CREDIT_DETAILS: 'פרטי אשראי',
    ID: 'ת"ז',
    LIST_ADVERTISEMENTS_PRICES: 'רשימת פרסומות ומחירים',
    DATE_PLEASE: 'תאריך בקשה',
    PAYMENT_STATUS: 'סטטוס תשלום',
  },
  MANAGE_PRODUCT: {
    SELECT_CATEGORY: 'בחר קטגוריה',
    SELECT_PROVIDER: 'בחר ספק',
    CHOOSE_PRODUCT: 'בחר מוצר',
    PRODUCT_NAME_HEBREW: 'שם המוצר: עברית',
    PRODUCT_NAME_ENGLISH: 'שם המוצר: אנגלית',
    PRODUCT_NAME_ARABIC: 'שם המוצר: ערבית',
    PRODUCT_DESCRIPTION_HEBREW: "תיאור מוצר: עברית",
    PRODUCT_DESCRIPTION_ENGLISH: "תיאור מוצר: אנגלית",
    PRODUCT_DESCRIPTION_ARABIC: "תיאור מוצר: ערבית",
    TRANSLATION_ADDITIONAL_LANGUAGES: 'תרגום שפות נוספות',
    PRODUCT_DETAILS: 'פרטי מוצר',
    MINUTES_OF_CONVERSATION: 'דקות שיחה',
    SMS_MESSAGES: 'הודעות SMS',
    MOBILE_BROWSING: 'גלישה סלולרית',
    GENERAL_BUDGET: 'תקציב כללי',
    AUTHORIZED_FOR_REVOCATION: 'מורשה לביטול',
    DEFAULT_IN_SETTING_RESELLER: 'ברירת מחדל בהקמת משווק',
    PRODUCT_WITHOUT_VAT: 'מוצר ללא מע"מ',
    PRODUCT_SETTINGS: 'הגדרות מוצר',
    ID_AT_VENDOR: 'מזהה אצל הספק',
    ICOUNT_PRODUCT_NUMBER: 'iCount מספר מוצר ב',
    PROFIT_MODEL: 'מודל רווח',
    DISTRIBUTION_FEE: 'עמלת הפצה',
    PURCHASE_PRICE: 'מחיר קניה',
    CURRENCY: 'מטבע',
    MINUTES_AVAILABLE: 'דקות לרשות',
    INTERNET: 'אינטרנט',
    UNLIMITED: 'ללא הגבלה',
    DEFINE_VIEW_ADDITIONAL_DETAIL: 'הגדרה והצגת פרטים נוספים',
    ADDITIONAL_DETAILS: 'פירוט נוסף',
    DEFAULT_LANGUAGE: 'שפת ברירת מחדל'
  },
  MANAGE_SHANIV: {
    DUTY_FREE: 'ללא מע"מ',
    SHANIV_ID: 'מזהה שניב',
    EXTPART_PRODUCT_CODE: 'קוד מוצר EXTPART',
    PARTNAME: 'PARTNAME',
    BARCODE_BARCODE: 'ברקוד BARCODE',
    PRICE_PRICE: 'מחיר PRICE',
    PRICE_INCLUDING_VAT: 'מחיר כולל מעמ',
    PRODUCT_DESCRIPTION_HE: 'תיאור המוצר: עברית',
    PRODUCT_DESCRIPTION_EN: 'תיאור המוצר: אנגלית',
    PRODUCT_DESCRIPTION_AR: 'תיאור המוצר: ערבית',
    CATEGORY: 'קטגוריה',
    SUBCATEGORY: 'תת קטגוריה',
    CATEGORY_NUMBER: 'מספר קטגוריה',
    MATERIAL: 'חומר',
    BRAND: 'מותג',
    PARKING: 'אריזה',
    SELECT_IMAGE: 'בחר תמונה',
    NAPKINS: 'מפיות',
    TOWELS: 'מגבות',
    HANDKERCHIEFS: 'ממחטות',
    TOILET: 'טואלט',
    SHANIV: 'שניב',
    SASATK: 'סאסאטק',
    DESCRIPTION: 'תיאור מוצר',
    NUMBER_UNITS_PACKAGE: 'מספר יחידות במארז',
    NUMBER_UNITS_PER_SURFACE: 'מספר יחידות במשטח',
    SKU: 'מק״ט',
    BARCODE: 'ברקוד',
    ADD_TO_BASKET: 'הוסף לסל',
    ADD_BOTH_ITEMS_TO_CART: 'הוסף לסל את שני הפריטים',
    PRICE_PER_UNIT: 'מחיר ליחידה',
    DISCOUNT_PURCHASE_SURFACE: 'הנחה של {num}% ברכישת משטח',
    RECOMMENDED_RETAIL_PRICE: 'מחיר מומלץ לצרכן',
    PACKAGE_OFFER: 'הצעת חבילה',
    SAVE: 'חסכו',
    ADDED_TO_PACKAGE: 'נוסף לחבילה',
    ONLY: 'בלבד',
    PRODUCTS_PURCHASED_TOGETHER_OFTEN: 'מוצרים שנרכשים יחד לעיתים קרובות',
    ADD_SELECTED_ITEMS_TO_CART: 'הוסף פריטים שנבחרו לסל',
    CUSTOMERS_ALSO_SAW: 'לקוחות ראו גם',
    SURFACE: 'משטח',
    PRODUCT_PROMOTIONS: 'מבצעי מוצרים',
    OPERATION_NUMBER: 'מספר מבצע',
    ITEM: 'פריט',
    ITEM1: 'פריט 1',
    ITEM_QUANTITY1: 'כמות פריט 1',
    ITEM2: 'פריט 2',
    ITEM_QUANTITY2: 'כמות פריט 2',
    ITEM3: 'פריט 3',
    ITEM_QUANTITY3: 'כמות פריט 3',
    NORMAL_PRICE: 'מחיר רגיל',
    SALES_PRICE: 'מחיר מבצע',
    OPERATION_DATE: 'תאריך מבצע',
    OPERATION_IMAGE: 'תמונת מבצע',
    OPERATION_NAME: 'שם המבצע',
    TYPE_OF_OPERATION: 'סוג המבצע',
    ADD_PRODUCTS_FROM_LIST: 'הוסף מוצרים מהרשימה',
    VOID: 'בטל',
    VALID_UNTIL: 'בתוקף עד',
    START_TIME: 'שעת התחלת',
    END_TIME: 'שעת סיום',
    PRICE_FROM_LOW_TO_HIGH: 'מחיר מנמוך לגבוהה',
    PRICE_LISTS: 'מחירונים',
    USER_MANUAL: 'הוראות שימוש',
    AMOUNT_TO_ORDER: 'סכום להזמנה',
    ADD_TO_CART_PROMOTION: 'הוסף לסל את המבצע',
    AGENT: 'סוכן',
    AGENT_NAME: 'שם סוכן',
    AGENT_PHONE: 'מספר הטלפון של הסוכן',
    SET_PACKAGE_QUANTITY_MANUALLY: 'הגדר כמות חבילה',
    MANUAL_PACKAGE_QUANTITY: 'כמות החבילה',
    SET_PALLET_QUANTITY_MANUALLY: 'הגדר כמות משטחים',
    MANUAL_PALLET_QUANTITY: 'כמות המזרן',
    IMPORT_SASTECH_QUANTITY_EXCEL: 'כמות יבוא sastech',
    IMPORT_FILE: 'ייבוא קובץ',
    IMPORT: 'יְבוּא',
  },
  BUSINESS_DETAILS: {
    BUSINESS_DETAILS: 'פרטי עסק',
    PLAFAID_RESELLER: 'משווק פלאפייד',
    SHANIV_MARKETER: 'משווק שניב',
    SHENIV_CUSTOMER_TAX: 'מס לקוח שניב',
    FREEZE: 'הקפאה',
    UNFREEZE: 'לְהַפְשִׁיר',
    SHOE: 'נעל',
    LOCK: 'לנעול',
    UNLOCK: 'לבטל נעילה',
    SEARCH_SHANIV_CUSTOMERS: 'חיפוש לקוחות שניב',
    CUSTOMER_SEARCH: 'חיפוש לקוחות',
    MULTILINE_POSITION: 'עמדת רב קו',
    ACTIVITY_HOURS: 'בחר שעות פעילות  ',
    COMMENTS: 'הערות',
    SHANIV_PLNAME: 'PLNAME ({company})',
    SHANIV_CUSTNAME: 'CUSTNAME ({company})',
    YOU_WANT_DELETE_BUSINESS: 'האם אתה רוצה למחוק את העסק הזה ({business})?',
  },
  CUSTOMER_LISTS: {
    CUSTOMER_LISTS: 'רשימות לקוחות',
    TAX_CLIENT: 'מס. לקוח',
    SECTOR: 'מגזר',
    AREA_CODE: 'קוד אזור',
    REGION_NAME: 'שם אזור',
    AUTHORIZED_DEALER_HP: 'עוסק מורשהח.פ',
    AGENT_CODE: 'קוד סוכן',
    AGENT_NAME: 'שם סוכן',
    OBLIGO: 'אובליגו',
    PLATOK_CUSTOMER: 'לקוח פלאפייד',
    EXPLAND_FULL_TABLE: 'הרחב לטבלה מלאה',
    UPLOADING_NEW_LIST: 'העלאה של רשימה חדשה',
    BECOME_CUSTOMER: 'הפוך ללקוח',
    LIST_NAME: 'שם רשימה',
    EXISTING_CUSTOMER_FLAPAID: 'לקוח קיים בפלאפייד',
    FLAPPY_CUSTOMER_TAX: 'מס לקוח פלאפייד',
    CHECK_ALL: 'סמן הכל',
    CREATE_USER: 'צור משתמש',
    PAYDES: 'PAYDES'
  },
  SASTECH: {
    ADDED_TO_CART: 'נוסף לעגלה',
    TO_BOX_OFFICE: 'לקופה',
    OUT_OF_ITEMS: '{num1} מתוך {num2} פריטים',
    TOTAL_ADDED_TXT: 'סה"כ נוסף לעגלת הקניות',
    CONTENT: 'תכולה',
    TOTAL_IN_CART: 'סה"כ בעגלה',
    HOT_SALES: 'מבצעים חמים',
    COMPLEMENT: 'משלימים',
    INSTITUTIONAL: 'מוסדי',
    PAPER_PRODUCTS: 'נייר ומוצריו',
    DISH_WASHING: 'הדחת כלים',
    BLEACH: 'אקונומיקה',
    DISINFECTION_CLEANING: 'חיטוי וניקיון',
    FLOOR_CLEANING: 'ניקוי רצפות',
    OPERATES_PACKAGES: 'מבצע חבילות',
    CHOOSE_COMPANY: 'בחר חברה',
    BEFORE_DISCOUNT: "לפני הנחה",
    ALL_PRODUCTS: "כל המוצרים",
    PLEASE_SELECT_QUANTITY: 'בבקשה תבחרו כמות',
    SHOPPING_CART: 'סל הקניות',
    DELIVERY_DATE: 'תאריך אספקה',
    PAYMENT_METHOD: 'אופן בתשלום',
    PROMOTIONS: 'מבצעים והטבות',
    MAKE_RESERVATION: 'בצע הזמנה',
    PACKAGES: 'חבילות',
    ADD_PRODUCTS: 'הוסף מוצרים',
    ADD_PACKAGES: 'הוסף חבילות',
    PACKAGE_QUANTITY: 'כמות במארז',
    BARCODE: 'ברקוד',
    UNIT_PRICE: 'מחיר ליחידה',
    PACKAGE_PRICE: 'מחיר למארז',
    ITEMS: 'פריטים',
    FRAMEWORK_EXPLOITATION_SASSATECH: 'מסגרת לניצול בסאסאטק',
    MINIMUM_ORDER: 'הזמנה מינימום',
    PRODUCTS: 'מוצרים',
    CLEAR_FILTERS: 'נקה פילטרים',
    SORT_DEFAULT: 'מיון ברירת מחדל',
    SORT_PRICE: 'מחיר:',
    SORT_DIRECTION_ASC: 'נמוך עד גבוה',
    SORT_DIRECTION_DESC: 'גבוה לנמוך',
    PAPER_PAPER_PRODUCTS: 'נייר ומוצריו',
    SPRAYS: 'תרסיסים',
    LAUNDRY: 'כביסה',
    CULTIVATION: 'טיפוח',
    ONE_TIME: 'חד פעמי',
    TOILET_BATHROOMS: 'אסלות וחדרי רחצה',
    APPLY_FILTER: 'החל מסנן',
    CLEAR: 'ברור',






  },
  MANAGEMENT: {
    TIMING_VAT_CHANGE: 'תזמון שינוי מע"מ',
    NEW_VAT: 'מע"מ חדש',
    MAXIMUM_PROFIT_COMMISSION_PERCENTAGE: 'אחוז רווח/ עמלה מקסימלי',
    COMMISSION_LEVEL: 'מדרגת עמלה',
    POINTS: 'נקודות',
    MAXIMUM_AMOUNT_ALLOWED: 'סכום מקסימלי מורשה',
    CREDIT_PAYMENT_LIMIT: 'מגבלת תשלום באשראי',
    PREPAID_PROFIT_PERCENTAGE: 'אחוזי רווח פריפייד',
    CUSTOMER_TYPE: 'סוג לקוח',
    PROFIT_PRO: '% רווח',
    APPLY_PROFIT_TO_RESELLERS: 'החל % רווח על כל המשווקים',
    REMOVE_PROFIT_ON_DISTRIBUTORS: 'הסר % רווח על כל המפיצים',
    APPLY_GIFT_TO_MARKETERS: 'החל נקודות מתנה על כל המשווקים',
    REMOVE_GIFT_ON_DISTRIBUTORS: 'הסר נקודות מתנה על כל המפיצים',
    ACCOUNT_FEES_PROFIT_COMMISSIONS: 'עמלות רווח תשלומי חשבונות',
    COMMISSIONER_DISTRIBUTOR_COMMISSION: 'עמלה למשווק/מפיץ',
    CONSUMER_COMMISSION: 'עמלת צרכן',
    APPLY_PROFIT_COMMISSION_TO_MARKETERS: 'החל עמלת רווח על כל המשווקים',
    REMOVE_PROFIT_MARGIN_ON_DISTRIBUTORS: 'הסר עמלת רווח על כל המפיצים',
    APPLY_CONSUMER_FEE_TO_RESELLERS: 'החל עמלת צרכן על כל המשווקים',
    REMOVE_CONSUMER_FEE_ON_DISTRIBUTORS: 'הסר עמלת צרכן על כל המפיצים',
    CONSUMER_COMMISSION_LEVEL: 'גובה מדרגת עמלת צרכן',
    COPY_PROFIT_PERCENTAGE_TO_PRODUCT: 'העתקת אחוז רווח למוצר',
    ORIGINAL_PRODUCT: 'מוצר מקור',
    TARGET_PRODUCT: 'מוצר יעד',
    COPY_VENDOR_PERMISSIONS: 'העתקת הרשאות ספקים',
    SOURCE_PROVIDER: 'ספק מקור',
    TARGET_PROVIDER: 'ספק יעד',
    COPY_RESELLER_PROFILE: 'העתקת פרופיל משווק',
    SOURCE_MARKETER: 'משווק מקור',
    TARGET_MARKETER: 'משווק יעד',
    TAG_TYPE: 'סוג תגית',
    TAG_NAME: 'שם תגית',
    FILTER_NAME: 'סינון',
    APPEARS_ON_PRODUCT: 'מופיע על המוצר',
    DELETION: 'מחיקה',
    CREATE_NEW_TYPE: 'צור סוג חדש',
    CREATE_NEW_TAG: 'צור תגית חדשה',
    CREATE_EDIT_CATEGORY: 'הקמה או שינוי קטגוריה',
    CATEGORY_NAME: 'שם קטגוריה',
    BACKGROUND_COLOR_PICKER: 'צבע רקע קטגוריה',
    CATEGORY_TEXT_COLOR: 'צבע טקסט לקטגוריה',
    CREATE_CHANGE_TAG: 'הקמה או שינוי תגית',
    EXTRA_CONVERTIBLE_PENNIES: 'תוספת אגורות להמרה',
    SUPPLIER_NAME: 'שם הספק',
    SUPPLIER_TYPE: 'סוג ספק',
    ANOTHER_PHONE: 'טלפון נוסף',
    SUPPLIER_LOGO: 'לוגו ספק',
    FONT_COLOR: 'צבע גופן',
    LOGO_BACKGROUND: 'רקע לוגו',
    PRODUCT_CARD_BACKGROUND: 'רקע כרטיס מוצר',
    SELECTED_PRODUCT_CARD_BG: 'רקע כרטיס מוצר נבחר',
    OPERATING_PRODUCT_FONT: 'גופן מוצר מבצע',
    BACKGROUND_PRODUCT_CARD_SALE: 'רקע כרטיס מוצר מבצע',
    MAX_MARKET_PROFIT_COMMISSION: 'רווח / עמלה מקסימלי למשווק %',
    MIN_ADDITIONAL_COMMISSION_CONSUMER: 'עמלת תוספת מינימלית לצרכן',
    MAX_AMOUNT_ALLOWED_PAYMENT: 'סכום מקסימלי מותר לתשלום',
    LIMIT_AUTO_PAYMENT_CREDIT: 'הגבלת תשלום אוטומטי באשראי',
    CONSUMER_COMMISSION_STAIRS: 'מדרגות עמלה לצרכן',
    CREDIT_CARD_PROVIDE: 'כרטיס אשראי לספק',
    HOW_TO_CANCEL: 'אופן הביטול',
    EMAIL_TO_CANCEL: 'מייל לביטול',
    DARK: 'כהה',
    BRIGHT: 'בהיר',
    SELECT_PROFILE: 'בחר פרופיל',
    NEW_PROFILE: 'פרופיל חדש',
    EDIT_PROFILE: 'ערוך פרופיל',
    APPROVED_FOR_USE: 'מאושר לשימוש עבור',
    DIRECTOR: 'מנהל',
    TV: 'טלוויזיה',
    USING_DISTRIBUTION_FEE: 'שימוש בעמלת הפצה',
    AS_DEFAULT_PROFILE: 'כפרופיל ברירת מחדל',
    TYPE_OF_PRODUCT: 'סוג המוצר',
    APPLY_PROFIT_ON_ALL_PRODUCTS: 'החל % רווח על כל המוצרים',
    APPLY_GIFT_POINTS_TO_ALL_PRODUCTS: 'החל נקודות מתנה על כל המוצרים',
    CARD_TYPE: 'סוג הכרטיס',
    PERCENTAGE_PROFIT: 'אחוז רווח',
    A_DISTRIBUTION: 'ע.הפצה',
    ENABLED: 'מאופשר',
    PROFIT_COMMISSION_TO_MARKETER: 'עמלה רווח למשווק',
    EXTRA_CHARGE_TO_CONSUMER: 'תוספת תשלום לצרכן',
    APPLY_PROFIT_COMMISSION_TO_PRODUCTS: 'החל עמלת רווח על כל המוצרים',
    APPLY_ADDITIONAL_PRICE_TO_CONSUMER: 'החל תוספת מחיר לצרכן',
    ADDITIONAL_TO_CONSUMER: 'תוספת לצרכן',
    COMMISSIONERS_COMMISSION: 'עמלה למשווק',
    TOURISM_PROFILE: 'פרופיל תיירות',
    MIN_FRONTAL_REPRESENTATIVE_FEE: 'מינימום עמלת נציג פרונטלי',
    SKUS: 'מקט"ים',
    NAME_OF_SKU: 'שם מק"ט',
    REQUIRED: 'חובה',
    SELECT_SKU: 'בחר מק"ט',
    OFF: 'לא פעיל',
    OPTIONAL: 'לא חובה',
    ENTER_NEW_PART_NUMBER_THERE: 'הזן שם מק"ט חדש',
    MANUAL_TICKETS: 'כרטיסים ידניים',
    ACTIVE_CARDS: 'כרטיסים פעילים',
    TICKETS_USED: 'כרטיסים בשימוש',
    TICKETS_ABOUT_EXPIRE: 'כרטיסים שתוקפם עומד לפוג',
    EXPIRED_UNUSED_CARDS: 'כרטיסים שפג תוקפם ולא היו בשימוש',
    DATE_OF_INCOME: 'תאריך הכנסה',
    INSERTED_BY: 'הוכנס ע"י',
    DATE_TIME_OF_INCOME: 'תאריך ושעת הכנסה',
    EXPIRY_DATE: 'תאריך תפוגה',
    USED: 'בשימוש',
    CARD_WAS_CREATED_SUCCESSFULLY: 'הכרטיס נוצר בהצלחה',
    GIFTS: 'מתנות',
    GIFT_CATEGORY: 'קטגוריית מתנה',
    GIFT_LIST: 'רשימת מתנות',
    NAME_OF_GIFT: 'שם המתנה',
    POINTS_TO_BE_UTILIZED: 'נקודות לניצול',
    PRODUCT_IMAGE: 'תמונת מוצר',
    DETAIL_ON_WAITING: 'פרטים על המתנה',
    ACTIVE_GIFT: 'מתנה פעילה',
    INVOICE_MANAGEMENT: 'ניהול חשבוניות',
    AUTHORIZED_PROVIDER_TO_PROVIDER: 'הרשאות מספק לספק',
    PROFIT_COMMISSIONS: 'עמלות רווח',
    NEW_MANUAL_TICKETS: 'כרטיסים ידניים חדש',
    GENERAL_SETTINGS: 'הגדרות כלליות',
    MILES: 'מייל',
    BASE: 'בסיס',
    TEMPORARY_ADDITION: 'תוספת זמנית',
    DATE_TEMPORARY_ADDITION: 'תאריך לתוספת זמני',
    VACATION: 'נופשון',
    ORGANIZED: 'מאורגן',
    CRUZ: 'קרוז',
    ROLE: 'תפקיד',
    SECURITY_DEPOSIT: 'פקדון',
    THERE_IS: 'יש',
    TOURISM_PROVIDER: 'ספק תיירות',
    PACKAGE_REFRESH_TIME_DAYS_AHEAD: 'זמן רענון חביולת {num} יום קדימה',
    REFRESH_TIME_FORWARD_EACH_DAY: 'זמן רענון {num} יום קדימה כל יום',
    AT: 'בשעה',
    DAYS: 'ימים',
    EXCEPT_FOR_REFRESH_DAY: 'למעט ביום רענון {NUM} יום',
    SETTING: 'הגדרת',
    API_SETTING: 'API הגדרת',
    APPROVED_SUPPLIERS: 'ספקים מאושרים',
    PAYMENT_FEES: 'עמלות תשלומים',
    USERS: 'משתמשים',
    NUMBER: 'מספר',
    USERNAME: 'שם המשתמש',
    CELLPHONE_NUMBER: 'מספר נייד',
    OWNER: 'בעלים',
    ADD_USER: 'הוסף משתמש',
    NEW_USER: 'משתמש חדש',
    PERMISSIONS: 'הרשאות',
    SEVERAL_SECURE_DEVICES: 'מספר מכשירים מאובטחים',
    IP_NUMBER_ADDRESSES: 'IP מספר כתובות',
    IP_ADDRESS: 'IP כתובת',
    FRIENDLY_NAME: 'שם ידידותי',
    ACTIONS: 'פעולות',
    PASSWORD_RESET: 'איפוס סיסמה',
    CHANGE_PASSWORD: 'שינוי סיסמה',
    UNDO_FREEZE: 'בטל הקפאה',
    DESTINATION_DESCRIPTION: 'תיאור יעד',
    HELP: 'עזרה',
    FONT_LOGO_SUPPLIER: 'פונט לוגו ספק',
    PRODUCT_FONT: 'פונט מוצר',
    SELECTED_PREFERRED_PRODUCT_FONT: 'פונט מוצר נבחר/מועדף',
    DOUBT_BACKGROUND: 'רקע ספק',
    PRODUCT_BACKGROUND: 'רקע מוצר',
    SELECTED_PREFERRED_PRODUCT_BACKGROUND: 'רקע מוצר נבחר/מועדף',
    BRIGHT_LOGO_FONT: 'פונט לוגו בהיר',
    BRIGHT_LOGO_BACKGROUND: 'רקע לוגו בהיר',
    STAR_COLOR_FAVORITES: 'צבע כוכב מועדפים',
    SIDE_LOGO_BACKGROUND: 'רקע לוגו צד',
    TERMS_OF_CALLS: 'תנאי שיחות',
    MANUAL_PRICE: 'מחיר ידני',
    ALTERNATIVE_PRODUCT_RECOMMENDED: 'מוצר חלופי (מומלץ)',
    PRODUCT_TYPE: 'סוג מוצר',
    VIRTUAL: 'וירטואלי',
    MANUAL: 'ידני',
    BILLS_PAYMENT: 'תשלום חשבונות',
    SUCURE_IP: 'IP מאובטח',
    CHANGE_USER_PASSWORD_TXT: 'שינוי לסיסמה חדשה למשתמש {UNAME} לעסק {BNAME}',
    ENTER_NEW_PASSWORD: 'הזן סיסמה חדשה',
    PASSWORD_AUTHENTICATION: 'אימות סיסמה',
    CHANGE_PASSWORD_DESC: 'בסיום השינוי הסיסמה החדשה תשלח למשתמש במסרון, למספר {MNUM} ובעל העסק יעודכן במסרון על השינוי בסיסמה, למספר {MNUM}',
    RESET_PASSWORD_DESC: 'פעולה זו תייצר סיסמה חדשה למשתמש {UNAME} לעסק {BNAME} הסיסמה החדשה תשלח למשתמש במסרון, למספר {MNUM} בעל העסק יעודכן במסרון על השינוי בסיסמה, למספר {OMNUM}',
    LOGIN_HISTORY: 'היסטוריית התחברות',
    PAYMENTS_CANCELLATION: 'ביטול תשלומים',
    SYNC_NOW: "סנכרן עכשיו",
    SYNC_STARTED: "הסנכרון התחיל",
    SHANIV_CUSTOMER_NUMBER: 'מספר לקוח שניב',
    SASA_CUSTOMER_NUMBER: 'מספר לקוח סאסא',
    NEW_BUSINESS: 'עסק חדש',
    PRODUCTS: 'מוצרים',
    PROFILE_NAME: "שם פרופיל",
    PRODUCT_ACTION_WARNING_TXT: "פעולה זו תוביל לשינויים בכל המוצרים, כולל אלה שלא הוצגו לאחר הסינון! האם אתה בטוח שאתה רוצה להמשיך?",
    PERMISSIONS_PROFILE: 'פרופיל הרשאות',
    CAN_ASSIGNED: "ניתן להקצות",
    IMPORT_PERMISSIONS: "הרשאות ייבוא",
    PERMITTED_TO: "רשאי ל",
    ROLE_NAME: "שם התפקיד",
    GIFTS_NEW: "מתנות - חדש",
    AS_SHANIV_DEFAULT_PROFILE: "כברירת מחדל של שניב",
    ADD_VAT: 'הוסף VAT',
    PRODUCTS_SOURCE: 'מקור המוצרים',
    POINTS_USER: 'משתמש נקודות',
    LOCK: 'לנעול',
    CANCELLATION_FREEZE: 'הקפאת ביטול',
    UNLOCK: 'לבטל נעילה',
    LINUX: 'לינוקס',
    WINDOWS: 'ווינדוס',


  },
  CANCELLATION: {
    CANCELLATION_SYSTEM: 'מערכת ביטולים',
    DEAL_NUMBER: 'מס.עסקה',
    DATE_TIME_OF_LOADING: 'תאריך ושעת הטעינה',
    DATE_TIME_OF_APPLICATION: 'תאריך ושעת הבקשה',
    AMOUNT_CANCELED: 'סכום שבוטל',
    ALL_RESELLERS: 'כל המשווקים',
    CHARGING_DATE: 'תאריך טעינה',
    CANCELLATION_DATE: 'תאריך ביטול',
    SHOW_CANCELLATIONS_IN_SYSTEM_ONLY: 'הצג ביטולים במערכת בלבד',
    SHOW_OPEN_CANCELLATIONS_ONLY: 'הצג ביטולים פתוחים בלבד',
    CANCEL_FREE_CHARGE_LOADING: 'ביטול טעינת פרייפייד',
    LOADING_DETAILS: 'פרטי הטעינה',
    CANCELLATION_IN_SYSTEM: 'ביטול במערכת',
    AMOUNT_TO_BE_CANCELED: 'סכום לביטול',
    CANCELLATION_SUCCESSFUL_TXT: 'הביטול הצליח',
    PARTIALLY_SUCCESSFUL_TXT: 'הביטול הצליח חלקי',
    CANCELLATION_FAILED: 'הביטול נכשל',
    CLOSED: 'סגור',
    CANCELLATION_DETAILS: 'פרטי הביטול',
    HOWTO_REQUEST_CANCELLATION: 'אופן בקשת הביטול',
    DATE_CANCELLATION1: 'תאריך ושעת הגשת הביטול',
    DATE_CANCELLATION2: 'תאריך ושעת הביטול',
    CANCELLATION_OPERATION: 'מבצע הביטול',
    SUPPLIER_TRANSACTION_NUMBER: 'מספר עסקה בספק',
    VENDOR_CONFIRMATION_NUMBER: 'מספר אישור ספק',
    CANCELLATION_TXT: 'שימו לב!! ביטול זה יעשה בפלאפייד בלבד ולא מול הספק*',
    WHICH_CANCELLATION: 'אשר ביטול',
    PREPAID_CANCELLATION: 'ביטול פרייפייד',
    CANCELLATION: 'ביטול',
    TRANSACTION_NUMBER: 'מספר העסקה',
    BUSINESS_NUMBER: 'מספר העסק',
    CANCELLATION_AMOUNT: 'סכום ביטול',
    PROVIDER_APPROVAL_NUNBER: 'מספר אישור הספק',
    TRANSACTION: 'עִסקָה',
    BUSINESS_ID: 'תעודת זהות עסקית',
    PROVIDER: 'ספק',
    CHARGING_TIME: 'זמן טעינה',
    CELLULAR_NUMBER: 'מספר סלולרי',
    CANCEL_CONFIRMATION_NUMBER: 'בטל את מספר האישור',
    PARTIAL_CANCEL: 'ביטול חלקי',
    SALE_TRANSACTION_PRICE: 'מחיר עסקת מכירה',
    CANCELLATION_NOTE: 'הערת ביטול',
    CANCELED_PRICE: 'המחיר בוטל',
    CANCELATION_SUCCESSFUL: 'הביטול הצליח',
    PARTIAL_CANCELATION_OF_NUM: 'ביטול חלקי של {NUM}',
    CAN_NOT_BE_CANCELED: 'לא ניתן לבטל',
    RAVKAV_CARD: 'כרטיס רבקב',
    RAVKAV_TRANSACTTION: 'עסקת רבקב',
    CANCEL_VIA_PROVIDER: 'בטל באמצעות ספק',
    CANCEL_LOCALY: 'בטל באופן מקומי',


  },
  COMPONENT: {
    SELECT_NUMBER_NIGHT_RANGE: 'בחר את מספר הלילות בטווח',
    SELECT_DATES: 'בחר תאריכים',
    EXACTLY_SELECTED_DATES: 'תאריכים שנבחרו בדיוק',
    STAY: 'שָׁהוּת',
    NIGHTS: 'לילות',
    THIS_WEEKEND: 'בסוף השבוע הזה',
    NEXT_WEEKEND: 'סוף שבוע הבא',
    THIS_WEEK: 'השבוע',
    NEXT_WEEK: 'שבוע הבא',
    THIS_MONTH: 'החודש',
    NEXT_MONTH: 'חודש הבא',
    CUSTOM: 'המותאם אישית'


  },
  CART: {
    CREDIT_LINE_FOR_UTILIZATION: 'מסגרת אשראי לניצול',
    MINIMUM_AMOUNT_PER_ORDER: 'סכום מינימום להזמנה {num}',
    MINIMUM_AMOUNT_PER_ORDER_PER_COMPANY: 'סכום מינימום להזמנה {num} ({company})',
    ORDER_SUMMARY: 'סיכום הזמנה',
    ORDER_VALUE: 'ערך הזמנה',
    SAVINGS: 'חיסכון',
    ITEMS_IN_SHOPPING_CART: 'פריטים בעגלת הקניות',
    CLEARANCE_OF_INVENTORY: 'חיסול מלאי',
    REMOVE_ALL_ITEMS: 'הסר את כל הפריטים',
    REPURCHASE: 'קנייה חוזרת',
    OPEN_INVITATIONS: 'הזמנות פתוחות',
    PREVIOUS_ORDER: 'הזמנות קודמות',
    SHOPPING_CART: 'עגלת הקניות',
    INCLUDING_VAT: 'כולל מע"מ',
    PACKAGE_QUANTITY: 'כמות במארז',
    FULL_PRICE: 'מחיר מלא',
    DAY_ORDER_ARRIVES:'יום הגעת ההזמנה',
    SAVE_COMPLETE_ORDER_LATER: 'שמור והשלם הזמנה אח"כ',
    ORDER_HISTORY: 'היסטורית הזמנות',
    ORDER_DATE: 'תאריך הזמנה',
    DELIVERY_DATE: 'תאריך הספקה',
    QUANTITY_OF_ITEMS_ORDERED: 'כמות פריטים מוזמנים',
    ORDER_AMOUNT: 'סכום הזמנה',
    QUANTITY_OF_ITEMS_PROVIDED: 'כמות פריטים שסופקו',
    FINAL_AMOUNT: 'סכום סופי',
    BOOK_AGAIN: 'הזמן שוב',
    DETAILS: 'פרטים',
    MOVE_TO_CART: 'העבר לסל',
    REMOVE: 'הסר',
    ORDER_NUMBER: 'הזמנה מספר',
    DONE_SUCCESSFULLY: 'בוצע בהצלחה',
    AMOUNT_OF_ITEMS: 'כמות פריטים',
    MOVE_CART_WARNING_TXT: 'הסט הנוכחי של פריטי עגלה יאבד. לשמור את העגלה הנוכחית שלך כהזמנה זמנית?',
    ASIDE_SHOPPING_CART: 'עגלת קניות',
    SOME_PRODUCTS_NOT_ORDERED: 'שימו לב שחלק מהמוצרים לא הוזמנו!',
    SOME_PRODUCTS_OUT_OF_STOCK: 'שימו לב שחלק מהמוצרים אזלו מהמלאי!',
    YOU_ARE_NOT_A_CUSTOMER_OF_COMPANY: 'The order cannot be placed - you are not a customer of {company}. Please contact the office for more details - * 5409',
    SEARCH_A_PRODUCT: 'חפש מוצר',
    ITEMS_IN_YOUR_SHOPPING_CART: '{NUM} פריטים בעגלת הקניות שלך',
    REMOVE_ALL: 'להסיר את כל',
    EXPAND_ALL: 'הרחב הכל',
    SAVE_FOR_LATER: 'לחסוך למועד מאוחר יותר',
    DEAL_DETAILS: 'פרטי העסקה',
    OUT_OF_STOCK: 'נגמר המלאי',
    SELECT_CREDIT_CARD: 'בחר כרטיס אשראי',
    PAYMENT_DETAIL: 'פרטי תשלום',
    CARDHOLDER_NAME: 'שם מחזיק כרטיס',
    CARDHOLDER_ID: 'מזהה בעל כרטיס',
    CARD_NUMBER: 'מספר כרטיס',
    EXPIRATION: 'תפוגה',
    CVV: 'CVV',
    SAVE_CREDIT_CARD_INFORMATION: 'שמור פרטי כרטיס אשראי',
    CHECKOUT: 'לבדוק',
    NEW_CREDIT_CARD: 'כרטיס אשראי חדש',
    PAYMENT_TO_TXT: 'כדי להשלים את ההזמנה, עליך להשלים את התשלום הפתוח עבור הזמנה זו',
    PRESS_TO_PAYMENT: 'לחץ כאן העבר לדף התשלום',


  },
  SHANIV_ORDER_STATUS: {
    created_on_our_side: 'נוצר בצד שלנו',
    sent_to_priority: 'נשלח לעדיפות',
    created_on_priority_side: 'נוצר בצד עדיפות',
    packed_in_transit: 'מאוכזב / במעבר',
    delivered: 'נמסר',
    error: 'שְׁגִיאָה',
    partly: 'חֶלקִית'
  },
  REPORTS: {
    OBLIGATION_REPORT: 'דוח אובליגו',
    AGENT_TAX: 'מס סוכן',
    MARKETER_DISTRIBUTOR: 'משווק/מפיץ',
    COLLECTION_DAY: 'יום גביה',
    MISSING_DOCUMENTS: 'מסמכים חסרים',

  },
  FLIGHTS: {
    PLEASE_USE_ALL_FILTERS: 'אנא השתמשו בכל המסננים',
    TYPE: 'סוג',
    TYPE_ROUND: 'הלוך חזור',
    TYPE_ONEWAY: 'כיוון אחד',
    CABIN_CLASS: 'מחלקה',
    CABIN_CLASS_ANY: 'כל המחלקות',
    CABIN_CLASS_ECONOMY: 'מחלקת תיירים',
    CABIN_CLASS_ECONOMY_PREMIUM: 'מחלקת תיירים פרימיום ',
    CABIN_CLASS_BUSINESS: 'מחלקת עסקים',
    CABIN_CLASS_FIRST: 'מחלקה ראשונה',
    MAX_STOPOVERS: 'עצירות ביניים',
    MAX_STOPOVERS_0: 'טיסה ישירה בלבד',
    MAX_STOPOVERS_1: 'עד עצירה אחת',
    MAX_STOPOVERS_2: 'עד שתי עצירות',
    PASSENGERS: 'נוסעים',
    ADULTS: 'מבוגרים',
    OVER_11: 'מעל גיל 11 ',
    CHILDREN: 'ילדים',
    AGE_2_11: 'בין 2-11',
    INFANTS: 'פעוטות',
    UNDER_2: 'מתחת ל 2',
    BAGGAGE: 'כבודה',
    HOLD_BAGS: 'מזוודה',
    HAND_BAGS: 'כבודת יד',
    FROM: 'מיעד',
    TO: 'ליעד',
    FOR_EXAMPLE: 'לדוגמא, {dest_name}',
    LONDON: 'לונדון',
    NEW_YORK: 'ניו יורק',
    BERLIN: 'ברלין',
    ROME: 'רומא',
    FLIGHTS: 'טיסות',
    VIEW_ALL_FLIGHTS: 'הצג הכל',
    FLIGHT_TO: 'טיסות ל',
    STARTING_FROM: 'מתחיל מ',
    FULL_DETAILS: 'כל הפרטים',
    PRICE_SPECIFIC_FLIGHT_SHOWN: 'מחיר טיסה',
    DAYS: 'ימים',
    PLEASE_SEARCH: 'חיפוש',
    NO_FLIGHTS_FOUND: 'לא נמצאו טיסות',
    TRIP_SUMMARY: 'פרטי הטיסה',
    NIGHTS_IN: 'לילות ב',
    BOOK_FLIGHT: 'הזמן טיסה',
    CONNECTION_PROTECTED_BY_CARRIER: 'טיסת המשך על ידי המוביל',
    BAGS_RECHECK_REQUIRED: 'קבלה מחודשת של כבודה',
    LAYOVER: 'קונקשן קצר',
    ARRIVED_DESTINATION: 'יעד',
    FREE: 'חינם',
    PAID_OPTION: 'אפשרויות נוספות בתשלום',
    NOT_AVAILABLE: 'לא זמין',
    PERSONAL_ITEM: 'תיק אישי',
    CABIN_BAGGAGE: 'כבודת יד',
    CHECKED_BAGGAGE: 'שליחת מזוודות',
    THEN_DEPART_FROM: 'עוזב מ',
    BAGGAGE_INFO: 'פרטי הכבודה',
    AIRLINES: 'חברות תעופה',
    PRICE: 'מחיר',
    LIST_ALL: 'הצג הכל',
    DEPARTURE_DATE: 'תאריך יציאה',
    RETURN_DATE: 'תאריך חזרה',
    DURATION: 'משך',
    TRAVELERS: 'נוסעים',
    TIP: 'טיפ',
    FLIGHT_TIP_TXT: 'Travel safely by adding a checked bag instead. You can take less on board and avoid moving around the cabin.',
    ORDER_DETAILS: 'פרטי הזמנה',
    SELECT_ONE_OPTION: 'בחר אפשרות אחת',
    NO_CHECKED_BAGGAGE: 'ללא כבודה',
    ORDER: 'הזמן',
    PRICE_PER_PASSENGER: 'מחיר לנוסע בודד',
    TOTAL_ORDER_PRICE: 'סהכ מחיר',
    TOTAL: 'סהכ',
    PASSENGER: 'נוסע',
    PASSENGER_CATEGORY_ADULTS: 'מבוגר',
    PASSENGER_CATEGORY_CHILDREN: 'ילד',
    PASSENGER_CATEGORY_INFANTS: 'תינוק',
    FIRST_NAME: 'שם פרטי',
    LAST_NAME: 'שם משפחה',
    NATIONALITY: 'מדינה',
    GENDER: 'מין',
    GENDER_MALE: 'זכר',
    GENDER_FEMALE: 'נקבה',
    DATE_OF_BIRTH: 'תאריך לידה',
    CARD_NUMBER: 'מספר כרטיס אשראי',
    CARD_EXPIRATION: 'תוקף',
    FLIGHT_IS_NOT_AVAILABLE: 'טיסה לא זמינה',
    PRICE_CHANGED: 'עדכון מחיר!',
    PHONE: 'טלפון',
    EMAIL: 'דואר אלקטרוני',
    ERROR: 'טעות',
    PLEASE_CHECK_EMAIL_AND_PHONE: 'בדוק את מספר הטלפון והדואר האלקטרוני',
    EDIT: 'עריכה',
    SAVE: 'שמירה',
    CURRENT_STATUS: 'סטטוס',
    STATUS_CREATED: 'נוצר',
    STATUS_CREATED_DESC: 'ממתין לאישור הלקוח',
    STATUS_CONFIRMED_BY_CLIENT: 'מאושר ע״י הלקוח',
    STATUS_CONFIRMED_BY_CLIENT_DESC: 'אושר ע״י הלקוח, ממתין לאישור הספק',
    STATUS_ORDERED: 'הוזמן',
    STATUS_ORDERED_DESC: 'ההזמנה נוצרה ע״י הספק',
    STATUS_PAID: 'שולם',
    STATUS_PAID_DESC: 'הלקוח שילם, ממתין לאישור הספק',
    STATUS_CONFIRMED: 'מאושר',
    STATUS_CONFIRMED_DESC: 'אושר ע״י הספק',
    STATUS_COMPLETED: 'הושלם',
    STATUS_COMPLETED_DESC: 'ההזמנה הושלמה',
    STATUS_CANCELED: 'בוטל',
    STATUS_CANCELED_DESC: 'ההזמנה הוטלה',
    STATUS_FAILED: 'נכשלה',
    STATUS_FAILED_DESC: 'ההזמנה נכשלה',
    BOOK: 'הזמן',
    BOOK_WITHOUT_CLIENT_CONFIRMATION: 'הזמן ללא אישור לקוח',
    CANCEL: 'ביטול',
    CONFIRM_PAYMENT: 'אשר תשלום',
    ARE_YOU_SURE: 'האם אתה בטוח',
    OPEN_ETICKETS: 'פתח כרטיס אלקטרוני',
    BACK_TO_SEARCH: 'חזרה לחיפוש',
  }
};
