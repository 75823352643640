import { sellerTourismPercentageService } from '../services';

const state = {
  distributeData: [],
  businessData: [],
  resellerSettingData: {},
  filterBusinessData: [],
  tags: [],
  businessProfileData: [],
  suppliersData: [],
  businessProductData: [],
  businessCommissionData: [],
  userList: [],
  rolesList: [],
  activitySessions: [],
  agentsResponsible: [],
  loading: false,
};

const actions = {
  update_resellerSetting({ dispatch, commit }, { id, params }) {
    commit('setLoadingTrue');
    return sellerTourismPercentageService
      .updateResellerSetting(id, params)
      .then(
        (receipt_data) => {
          commit('setLoadingFalse');
          if (receipt_data == 'Success') {
            dispatch('alert/success', '', { root: true });
          }
        },
        (error) => {
          commit('setLoadingFalse');
        },
      );
  },
  getDistributes({ commit }) {
    commit('setLoadingTrue');
    return sellerTourismPercentageService.getDistributes().then(
      (receipt_data) => {
        commit('setLoadingFalse');
        if (receipt_data) {
          commit('setDistributeData', { receipt_data: receipt_data });
        }
      },
      // error => {}
    );
  },
  getBusiness({ commit }, { id }) {
    commit('setLoadingTrue');
    return sellerTourismPercentageService.getBusiness(id).then(
      (receipt_data) => {
        commit('setLoadingFalse');
        if (receipt_data) {
          commit('setBusinessData', { receipt_data: receipt_data });
          return receipt_data;
        }
      },
      // error => {}
    );
  },
  filterSellerPercentage({ dispatch, commit }, { filter }) {
    commit('setLoadingTrue');
    return sellerTourismPercentageService.filterSellerPercentage(filter).then(
      (receipt_data) => {
        commit('setLoadingFalse');
        if (receipt_data) {
          if (receipt_data.length == 0) {
            dispatch('alert/info', '', { root: true });
          }
          commit('setFilterBusinessData', { receipt_data: receipt_data });
        }
      },
      // error => {}
    );
  },
  getResellerSetting({ commit }, { id }) {
    commit('setLoadingTrue');
    return sellerTourismPercentageService.getResellerSetting(id).then(
      (receipt_data) => {
        commit('setLoadingFalse');
        commit('setResellerSetting', { receipt_data: receipt_data || {} });
      },
      (error) => {
        commit('setLoadingFalse');
          commit('setResellerSetting', { receipt_data: {} });
      },
    );
  },
  getTags({ commit }) {
    commit('setLoadingTrue');
    return sellerTourismPercentageService.getTags().then(
      (receipt_data) => {
        commit('setLoadingFalse');
        if (receipt_data) {
          commit('setTags', { receipt_data: receipt_data });
        }
      },
      (error) => {
        commit('setLoadingFalse');
      },
    );
  },
  getBusinessProfile({ commit }) {
    commit('setLoadingTrue');
    return sellerTourismPercentageService.getBusinessProfile().then(
      (receipt_data) => {
        commit('setLoadingFalse');
        if (receipt_data) {
          commit('setBusinessProfile', { receipt_data: receipt_data });
        }
      },
      (error) => {
        commit('setLoadingFalse');
      },
    );
  },
  updateBusinessData({ dispatch, commit }, { id, params }) {
    commit('setLoadingTrue');
    return sellerTourismPercentageService.updateBusinessData(id, params).then(
      (receipt_data) => {
        commit('setLoadingFalse');
        if (receipt_data) {
          dispatch('alert/success', '', { root: true });
        }
      },
      (error) => {
        commit('setLoadingFalse');
      },
    );
  },
  async deleteBusiness({ dispatch, commit }, { id }) {
    commit('setLoadingTrue');
    return sellerTourismPercentageService.deleteBusiness(id).then(
      (response_data) => {
        commit('setLoadingFalse');
        if (response_data) {
          dispatch('alert/success', '', { root: true });
        }
      },
      (error) => {
        commit('setLoadingFalse');
        dispatch('alert/error', '', { root: true });
      },
    );
  },
  async freezeBusiness({ dispatch, commit }, { id }) {
    commit('setLoadingTrue');
    return sellerTourismPercentageService.freezeBusiness(id).then(
      (response_data) => {
        commit('setLoadingFalse');
        if (response_data) {
          dispatch('alert/success', '', { root: true });
        }
      },
      (error) => {
        commit('setLoadingFalse');
        dispatch('alert/error', '', { root: true });
      },
    );
  },
  async lockBusiness({ dispatch, commit }, { id }) {
    commit('setLoadingTrue');
    return sellerTourismPercentageService.lockBusiness(id).then(
      (response_data) => {
        commit('setLoadingFalse');
        if (response_data) {
          dispatch('alert/success', '', { root: true });
        }
      },
      (error) => {
        commit('setLoadingFalse');
        dispatch('alert/error', '', { root: true });
      },
    );
  },
  async activateBusiness({ dispatch, commit }, { id }) {
    commit('setLoadingTrue');
    return sellerTourismPercentageService.activateBusiness(id).then(
      (response_data) => {
        commit('setLoadingFalse');
        if (response_data) {
          dispatch('alert/success', '', { root: true });
        }
      },
      (error) => {
        commit('setLoadingFalse');
        dispatch('alert/error', '', { root: true });
      },
    );
  },
  async newRavkavStation(
    { commit, dispatch },
    { id, city, address, opening_hour, closing_hour, comment },
  ) {
    let remote_id = `ravkav${id}`;
    let activity_hours = `${opening_hour}-${closing_hour}`;
    let fullAddress = `${address},${city},ישראל`;

    let lat = null,
      lon = null;
    try {
      const coordinates = await sellerTourismPercentageService.findLatLon(
        fullAddress,
      );

      lat = coordinates.lat;
      lon = coordinates.lng;
    } catch (error) {
      dispatch(
        'alert/error',
        'Incorrect address, RavKav station was not created\nהכתובת אינה תקינה, תחנת הרב קו לא הוקמה',
        { root: true },
      );
      return;
    }

    return sellerTourismPercentageService
      .newRavkavStation(
        city,
        address,
        activity_hours,
        comment,
        lat,
        lon,
        remote_id,
      )
      .then(
        () => {},
        (error) => {
          commit('setLoadingFalse');
        },
      );
  },
  getSuppliers({ commit }, { id }) {
    commit('setLoadingTrue');
    return sellerTourismPercentageService.getSuppliers(id).then(
      (receipt_data) => {
        commit('setLoadingFalse');
        if (receipt_data) {
          commit('setSuppliersData', { receipt_data: receipt_data });
        }
      },
      (error) => {
        commit('setLoadingFalse');
      },
    );
  },
  updateSuppliers({ dispatch, commit }, { params }) {
    commit('setLoadingTrue');
    return sellerTourismPercentageService.updateSuppliers(params).then(
      (receipt_data) => {
        commit('setLoadingFalse');
        if (receipt_data) {
          dispatch('alert/success', '', { root: true });
        }
      },
      (error) => {
        commit('setLoadingFalse');
      },
    );
  },
  getBusinessProduct({ commit }, { id }) {
    commit('setLoadingTrue');
    return sellerTourismPercentageService.getBusinessProduct(id).then(
      (receipt_data) => {
        commit('setLoadingFalse');
        if (receipt_data) {
          commit('setBusinessProduct', { receipt_data: receipt_data });
        }
      },
      (error) => {
        commit('setLoadingFalse');
      },
    );
  },
  getBusinessCommission({ commit }, { id }) {
    commit('setLoadingTrue');
    return sellerTourismPercentageService.getBusinessCommission(id).then(
      (receipt_data) => {
        commit('setLoadingFalse');
        if (receipt_data) {
          commit('setBusinessCommission', { receipt_data: receipt_data });
        }
      },
      (error) => {
        commit('setLoadingFalse');
      },
    );
  },
  getUserList({ commit }, params) {
    commit('setLoadingTrue');
    return sellerTourismPercentageService.getUserList(params).then(
      (receipt_data) => {
        commit('setLoadingFalse');
        if (receipt_data) {
          commit('setUserList', { receipt_data: receipt_data.result });
        }
      },
      (error) => {
        commit('setLoadingFalse');
      },
    );
  },
  getRoles({ commit }) {
    commit('setLoadingTrue');
    return sellerTourismPercentageService.getRoles().then(
      (receipt_data) => {
        commit('setLoadingFalse');
        if (receipt_data) {
          commit('setRolesList', { receipt_data: receipt_data });
        }
      },
      (error) => {
        commit('setLoadingFalse');
      },
    );
  },
  getAgentsResponsible({ commit }) {
    commit('setLoadingTrue');
    return sellerTourismPercentageService.getAgentsResponsible().then(
      (receipt_data) => {
        commit('setLoadingFalse');
        if (receipt_data) {
          commit('setAgentsResponsible', { receipt_data: receipt_data });
        }
      },
      (error) => {
        commit('setLoadingFalse');
      },
    );
  },
  getActivitySession({ commit }, { id }) {
    commit('setLoadingTrue');
    return sellerTourismPercentageService.getActivitySession(id).then(
      (receipt_data) => {
        commit('setLoadingFalse');
        if (receipt_data) {
          commit('setActivitySession', { receipt_data: receipt_data });
        }
      },
      (error) => {
        commit('setLoadingFalse');
      },
    );
  },
  deleteActivitySessionIp({ dispatch, commit }, { id }) {
    commit('setLoadingTrue');
    return sellerTourismPercentageService.deleteActivitySessionIp(id).then(
      (receipt_data) => {
        commit('setLoadingFalse');
        if (receipt_data) {
          dispatch('alert/success', '', { root: true });
        }
      },
      (error) => {
        commit('setLoadingFalse');
      },
    );
  },
  deleteActivitySessionToken({ dispatch, commit }, { id }) {
    commit('setLoadingTrue');
    return sellerTourismPercentageService.deleteActivitySessionToken(id).then(
      (receipt_data) => {
        commit('setLoadingFalse');
        if (receipt_data) {
          dispatch('alert/success', '', { root: true });
        }
      },
      (error) => {
        commit('setLoadingFalse');
      },
    );
  },
  updateBusinessProduct({ dispatch, commit }, params) {
    commit('setLoadingTrue');
    return sellerTourismPercentageService.updateBusinessProduct(params).then(
      (receipt_data) => {
        commit('setLoadingFalse');
        if (receipt_data) {
          dispatch('alert/success', '', { root: true });
        }
      },
      (error) => {
        commit('setLoadingFalse');
        dispatch(
          'alert/error',
          error,
          { root: true },
        );
      },
    );
  },
  updateProductProfit({ dispatch, commit }, { params }) {
    commit('setLoadingTrue');
    return sellerTourismPercentageService.updateProductProfit(params).then(
      (receipt_data) => {
        commit('setLoadingFalse');
        if (receipt_data) {
          dispatch('alert/success', '', { root: true });
        }
      },
      (error) => {
        commit('setLoadingFalse');
      },
    );
  },
  updateProductPoint({ dispatch, commit }, { params }) {
    commit('setLoadingTrue');
    return sellerTourismPercentageService.updateProductPoint(params).then(
      (receipt_data) => {
        commit('setLoadingFalse');
        if (receipt_data) {
          dispatch('alert/success', '', { root: true });
        }
      },
      (error) => {
        commit('setLoadingFalse');
      },
    );
  },
  updateDistributionFee({ dispatch, commit }, { params }) {
    commit('setLoadingTrue');
    return sellerTourismPercentageService.updateDistributionFee(params).then(
      (receipt_data) => {
        commit('setLoadingFalse');
        if (receipt_data) {
          dispatch('alert/success', '', { root: true });
        }
      },
      (error) => {
        commit('setLoadingFalse');
      },
    );
  },
  updateProfileFlags({ dispatch, commit }, { params }) {
    commit('setLoadingTrue');
    return sellerTourismPercentageService.updateProfileFlags(params).then(
      (receipt_data) => {
        commit('setLoadingFalse');
        if (receipt_data) {
          dispatch('alert/success', '', { root: true });
        }
      },
      (error) => {
        commit('setLoadingFalse');
      },
    );
  },
  updateCommissionProfit({ dispatch, commit }, { params }) {
    commit('setLoadingTrue');
    return sellerTourismPercentageService.updateCommissionProfit(params).then(
      (receipt_data) => {
        commit('setLoadingFalse');
        if (receipt_data) {
          dispatch('alert/success', '', { root: true });
        }
      },
      (error) => {
        commit('setLoadingFalse');
      },
    );
  },
  updateAdditionalPrice({ dispatch, commit }, { params }) {
    commit('setLoadingTrue');
    return sellerTourismPercentageService.updateAdditionalPrice(params).then(
      (receipt_data) => {
        commit('setLoadingFalse');
        if (receipt_data) {
          dispatch('alert/success', '', { root: true });
        }
      },
      (error) => {
        commit('setLoadingFalse');
      },
    );
  },
  activateUser({ dispatch, commit }, id) {
    commit('setLoadingTrue');
    return sellerTourismPercentageService.activateUser(id).then(
      (receipt_data) => {
        commit('setLoadingFalse');
        if (receipt_data) {
          dispatch('alert/success', '', { root: true });
        }
      },
      (error) => {
        commit('setLoadingFalse');
      },
    );
  },
  freezeUser({ dispatch, commit }, id) {
    commit('setLoadingTrue');
    return sellerTourismPercentageService.freezeUser(id).then(
      (receipt_data) => {
        commit('setLoadingFalse');
        if (receipt_data) {
          dispatch('alert/success', '', { root: true });
        }
      },
      (error) => {
        commit('setLoadingFalse');
      },
    );
  },
  updateUserPassword({ dispatch, commit }, params) {
    commit('setLoadingTrue');
    return sellerTourismPercentageService.updateUserPassword(params).then(
      (receipt_data) => {
        commit('setLoadingFalse');
        if (receipt_data) {
          dispatch('alert/success', '', { root: true });
        }
      },
      (error) => {
        dispatch('alert/error', error.response.data[0], { root: true });
        commit('setLoadingFalse');
      },
    );
  },
  updateUserData({ dispatch, commit }, { id, params }) {
    commit('setLoadingTrue');
    return sellerTourismPercentageService.updateUserData(id, params).then(
      (receipt_data) => {
        commit('setLoadingFalse');
        if (receipt_data) {
          dispatch('alert/success', '', { root: true });
        }
      },
      (error) => {
        commit('setLoadingFalse');
      },
    );
  },
  cancelActivitySessionIp({ dispatch, commit }, params) {
    commit('setLoadingTrue');
    return sellerTourismPercentageService.cancelActivitySessionIp(params).then(
      (receipt_data) => {
        commit('setLoadingFalse');
        if (receipt_data) {
          dispatch('alert/success', '', { root: true });
        }
      },
      (error) => {
        commit('setLoadingFalse');
      },
    );
  },
  cancelActivitySessionToken({ dispatch, commit }, params) {
    commit('setLoadingTrue');
    return sellerTourismPercentageService.cancelActivitySessionToken(params).then(
      (receipt_data) => {
        commit('setLoadingFalse');
        if (receipt_data) {
          dispatch('alert/success', '', { root: true });
        }
      },
      (error) => {
        commit('setLoadingFalse');
      },
    );
  },
  activateSessionIp({ dispatch, commit }, params) {
    commit('setLoadingTrue');
    return sellerTourismPercentageService.activateSessionIp(params).then(
      (receipt_data) => {
        commit('setLoadingFalse');
        if (receipt_data) {
          dispatch('alert/success', '', { root: true });
        }
      },
      (error) => {
        commit('setLoadingFalse');
      },
    );
  },
  activateSessionToken({ dispatch, commit }, params) {
    commit('setLoadingTrue');
    return sellerTourismPercentageService.activateSessionToken(params).then(
      (receipt_data) => {
        commit('setLoadingFalse');
        if (receipt_data) {
          dispatch('alert/success', '', { root: true });
        }
      },
      (error) => {
        commit('setLoadingFalse');
      },
    );
  },
};

const mutations = {
  setLoadingTrue(state) {
    state.loading = true;
  },
  setLoadingFalse(state) {
    state.loading = false;
  },
  setDistributeData(state, { receipt_data }) {
    state.distributeData = receipt_data;
  },
  setResellerSetting(state, { receipt_data }) {
    state.resellerSettingData = {...receipt_data};
  },
  setBusinessData(state, { receipt_data }) {
    state.businessData = receipt_data;
  },
  setFilterBusinessData(state, { receipt_data }) {
    state.filterBusinessData = receipt_data;
  },
  setTags(state, { receipt_data }) {
    state.tags = receipt_data;
  },
  setBusinessProfile(state, { receipt_data }) {
    state.businessProfileData = receipt_data;
  },
  setSuppliersData(state, { receipt_data }) {
    state.suppliersData = receipt_data;
  },
  setBusinessProduct(state, { receipt_data }) {
    state.businessProductData = receipt_data;
  },
  setBusinessCommission(state, { receipt_data }) {
    state.businessCommissionData = receipt_data;
  },
  setUserList(state, { receipt_data }) {
    state.userList = receipt_data;
  },
  setRolesList(state, { receipt_data }) {
    state.rolesList = receipt_data;
  },
  setActivitySession(state, { receipt_data }) {
    state.activitySessions = receipt_data;
  },
  setAgentsResponsible(state, { receipt_data }) {
    state.agentsResponsible = receipt_data;
  },
};

export const seller_tourism_percentage = {
  namespaced: true,
  state,
  actions,
  mutations,
};
