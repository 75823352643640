// ENGLISH
export const locale = {
  COMMON: {
    NIS: 'NIS',
    SELECT: "Select",
    DATES: "Dates",
    DATE: "Date",
    NO_DATA: "No data",
    OFFERS: "Offers",
    SEARCH: "Search",
    SAVE: "Save",
    EDIT: "Edit",
    CANCEL: "Cancel",
    DELETE: "Delete",
    PHONE: "Phone",
    NEW: "New",
    ACTIVE: "Active",
    INACTIVE: "Inactive",
    YES: "Yes",
    NO: "No",
    ADD: "Add",
    PRICE: "Price",
    FIND: "Find",
    KG: "Kg",
    TIME: "Time",
    PLACE: "Place",
    INVOICE: "Invoice",
    CODE: "Code",
    CONTINUE: "Continue",
    OK: "Ok",
    BACK: "Back",
    ACTION: "Action",
    E_MAIL: "E-mail",
    CITY: "City",
    ADDRESS: "Address",
    POSTAL_CODE: "Postal Code",
    ACTIVITY_TIME: "Activity time",
    FIRST_NAME: "First name",
    LAST_NAME: "Last Name",
    ID_NUMBER_PASSPORT: "ID number / passport",
    HOME_ADDRESS: "Home address",
    PASSWORD: "Password",
    SECURE: "Secure",
    SECURE_DEVICE: "Secure device",
    PAYMENT_METHOD: "Payment method",
    ID: "ID",
    HOUR: "Hour",
    MORE: "More",
    LESS: "Less",
    COST: "Cost",
    BALANCE: "Balance",
    NAME: "Name",
    BIRTHDAY: "Birthday",
    RETURN: "Return",
    CLOSE: 'Close',
    UPLOAD_IMAGE: 'Upload images',
    HIDE: 'Hide',
    DAY: 'Day',
    MONDAY: 'Monday',
    TUESDAY: 'Tuesday',
    WEDNESDAY: 'Wednesday',
    THURSDAY: 'Thursday',
    FRIDAY: 'Friday',
    SATURDAY: 'Saturday',
    SUNDAY: 'Sunday',
    COMMENTS: 'Comments',
    DURATION: 'Duration',
    VIEW: 'View',
    COVID_TXT: 'Please check your itinerary, including layovers, for <b>travel restrictions</b> prior to booking. A displayed itinerary is not confirmation of your eligibility to travel.',
    CONFIG: 'Config',
    CERTIFICATE: 'Certificate',
  },
  DATE: {
    SUN: "Sun",
    MON: "Mon",
    TUE: "Tue",
    WED: "Wed",
    THU: "Thu",
    FRI: "Fri",
    SAT: "Sat"
  },
  CURRENCY: {
    USD: "$",
    EUR: "€"
  },
  LOGIN: {
    ENTRANCE: "Login",
    LOGIN_TEXT: "Welcome, please enter a username and password to log in",
    USERNAME: "Username",
    PASSWORD: "Password",
    REMEMBER_ME: "Remember me",
    FORGOT_PASSWORD: "Forgot Password",
    LOGIN: "Log in",
    CONTACT_US: "Contact Us",

  },
  AUTHFOOTER: {
    SUPPORT: "Support",
    HOLIDAY_HOURS: "On Fridays and evenings on holiday hours",
    ANSWER_HOURS: "Answer A to T between the hours",
    SCREEN_SHARING: "For support by screen sharing",
    START_SUPPORT: "Start Support",
    PHONE_SUPPORT: "For support with a representative on the phone",
    CHAT_INTRO_DESC: "To chat with a representative click on the support icon in the lower corner",

  },
  ASIDE: {
    MAIN: "Main",
    TOURISM: "Tourism",
    COMMUNICATION: "Communication",
    PAYMENTS: "Payments",
    GIFT_CARD: "Gift Card",
    REPORTS: "Reports",
    SECOND_LEVEL: "Second level",
    SECOND_LEVEL_OPEN: "Second level open",
    THIRD_LEVEL: "Third level",
    THIRDLY: "Thirdly",
    CANCEL_SALE: "Cancellation of sale",
    SYSTEM_SETTINGS: "System settings",
    MANAGEMENT: "Management",
    CATEGORIES_PRODUCTS: "Categories & Products",
    VENDORS: "Vendors",
    PRODUCTS: "Products",
    TOURISM_PROVIDERS: "Tourism providers",
    TOURISM_PRODUCTS: "Tourism products",
    TOURIST_DESTINATIONS: "Tourist destinations",
    HOTELS: "Hotels",
    SPORT: "Sport",
    EXHIBITIONS: "Exhibitions",
    IMPRESSIONS: "Impressions",
    MARKETERS: "Marketers",
    PRIVATE_CUSTOMERS: "Private Customers",
    SALES_REPORTS: "Sales reports",
    BOOKKEEPING: "Bookkeeping",
    ANALYSIS_STATISTICS: "Analysis and Statistics",
    MANAGE_TRANSLATION: "Manage Translation",
    GENERAL: "General",
    TOURISM_PROFIT_PERCENTAGES: "Tourism Profit Percentages",
    ROFIT_COMMISSIONS: "Profit commissions",
    COPY_PROFIT_PERCENTAGE_TO_PRODUCT: "Copy Profit Percentage to Product",
    AUTHORIZES_PROVIDER_TO_PROVIDER: "Authorizes Provider to Provider",
    COPY_RESELLER_PROFILE: "Copy Reseller Profile",
    RESELLER_TOUR: "Reseller Tour",
    TOURISM_REPORT: "Tourism Report",
    SALES_AND_PAYMENTS: "Sales and payments",
    TAG_MANAGMENT: "Tag Managment",
    CONTRACT_DATA: "Contract data",
    SHANIV_PRODUCTS: 'Shaniv products',
    SHANIV_PACKAGES: 'Shaniv packages',
    SHANIV_CUSTOMERS: 'Shaniv customers',
    SALES: 'Sales',

  },
  HEADER: {
    CUSTOMER_NUMBER: "Customer number",
    UPDATES_ANNOUNCEMENTS: "Updates and announcements",
    PERSONAL_AREA: "Personal Area",
    LOG_OFF: "Log off",
    SHORTCUT_SHANIV: 'Shortcut Shaniv',
    SHORTCUT_SHORTCUT: 'Shortcut shortcut',
    CREATE_SHORTCUT: 'Create shortcut',

  },
  DASHBOARD: {
    DIAL_CARDS: "Dial cards",
    STARTING_NIS: "Starting at NIS {num}",
    VACATION_PACKAGES: "Vacation Packages",
    STARTING_VACATION: "Starting at $ 199",
    GIFT_CARDS: "Gift Cards",
    PRODUCT_NAME: "Product Name",
    PARIS: "Paris",
    BERLIN: "Berlin",
    ROME: "Rome",
    LONDON: "London",
    WEEKEND_HOTEL: "Weekend {num} star hotel",
    PRODUCT_DESCRIPTION: "Product Description",
    FAVORITES: "Favorites",
    CALLING_CARD: "Calling Card",
    GAMES: "Games"
  },
  TOURISM: {
    FROM: "From",
    TO: "To",
    PASSENGERS: "Passengers",
    ADULTS: "Adults",
    ADULT: "Adult",
    CHILD: "Child",
    CHILDREN: "Children",
    BOY: "Boy",
    BUDGET: "Budget",
    DEGREE_HOSPITALITY: "Degree of hospitality",
    PROMOTIONS: "Promotions",
    CASINO: "Casino",
    EXHIBITIONS: "Exhibitions",
    IMPRESSIONS: "Impressions",
    SUNBATHING: "Sunbathing",
    SUN_FUN: 'Sun & Fun',
    LAST_MOMENT: "Last moment",
    SYLVESTER: "Sylvester",
    TO_ALL_FAMILY: "To all the family",
    ATTRACTIVENESS: "Attractiveness",
    RECOMMENDED: "Recommended",
    POPULAR: "Popular",
    STARTING_FROM: "Starting from",
    TOUR: "Tour",
    TYPE_OF_DEAL: 'Type of Deal',
    PRICE_SHOWN_FOR_PACKAGE_DEAL_ON_DATES: 'The price shown is for a package deal on dates',
    PRICE_SHOWN_FOR_ORGANIZED_TOUR_ON_DATES: 'The price shown is for an organized tour on dates',
    VACATION_PACKAGES_TO_DESTINATION_ON_VARIETY_DATES: 'Vacation packages to {DES} on a variety of dates',
    ORGANIZED_TOUR_TO_DESTINATION_ON_VARIETY_DATES: 'Organized tour to {DES} on a variety of dates',
    FIND_DATES: 'Find dates',
    CHOOSE_VACATION_PACKAGE: 'Choose a vacation package',
    DES_VACATION_FOR_NIGHTS: '{DES} Vacation package for {NUM} nights',
    SHOW_MORE: 'Show more',
    ANYWHERE: 'Anywhere',
    NOFSHON: 'NOFSHON',
    ORGANIZED_TOUR: 'Organized Tour',
    ORGANIZED_TOURS: 'Organized Tours',
    ALL: 'All',
    ALL_DESTINATIONS: 'All destinations',
    VIEW_ALL_DEAL_DESTINATION: 'View all deal destination',
    PACKAGE_DEALS: 'Package deals',
    SORT: 'Sort',
    FILTER: 'Filter',
    DEPARTURE_DATE: 'Departure date',
    LOW_TO_HIGH: 'low to high',
    HIGH_TO_LOW: 'high to low',
    NON_STOP: 'non-stop',
    STOP_NUM: '{NUM} stop',
    STOPS: 'Stops',
    NUMBER_OF_NIGHTS: 'Number of nights',
    ACCOMMODATION_STAR_RATING: 'Accommodation Star rating',
    NON: 'Non',
    BOARD_BASIS: 'Board basis',
    ROOM_ONLY: 'Room only',
    BED_BREAKFAST: 'Bed & Breakfast',
    HALF_BOARD: 'Half Board',
    FULL_BOARD: 'Full Board',
    PRICE_RANGE: 'Price range',
    TIMES: 'Times',
    TAKE_OFF: 'Take-off',
    LANDING: 'Landing',
    TAKE_OFF_FROM_PLACE: 'Take-off from {PLACE}',
    LANDING_FROM_PLACE: 'Landing from {PLACE}',
    CLEAR_FILTERS: 'Clear filters',
    DEAL_CHANGED: 'Please note that selected deal was changed',
    PLEASE_CHECK_OTHER_DEALS: 'Sorry you missed the Package deal - Please check the other deals',
    SPORT_EVENTS: 'Sport & Events',
    WORKING_WITH: 'Working with',
    TOP_CATEGORIES_QUICK_SELECT: 'Top categories quick select',
    SEARCH_YOUR_DESIRE: 'Search your desire',
    ORGANIZED_TOURS_TO_SELECTED_DESTINATIONS: 'Organized tours to selected destinations',
    TRADE_SHOWS: 'Trade shows',
    FREE_SEARCH: 'Free Search',
    RECOMMENDED_TRADE_SHOWS: 'Recommended trade shows',
    UPCOMING_TRADE_SHOWS: 'Upcoming trade shows',
    INFO: 'Info',
    MORE_DATES: 'More dates',
    TRAVELERS: 'Travelers',
    ADVERTISEMENT: 'Advertisement',
    EXHIBITION_FOR_ADVERTISING_PROMOTIONAL_ITEMS: 'Exhibition for advertising and promotional items',
    ADVERTISING_TRADE_SHOWS: 'Advertising Trade Shows',
    CHOOSE_VACATION_DEALS: 'Choose Vacation Deal',
    VACATION_PACKAGES_TO: 'Vacation Packages to {DES}',
    MARKETING_TRADE_SHOWS: 'Marketing Trade Shows',
    TRADE_SHOW_CONTACT: 'Trade Show Contact',
    AUDIENCE: 'Audience',
    CYCLE: 'Cycle',
    LOCAL_TIME: 'Local time',
    FAIR_LOCATION: 'Fair location',
    OPEN_IN_MAP: 'Open in Map',
    FAIR_ORGANIZER: 'Fair organizer',
    FAIRS_OF_THESE_SECTORS: 'Fairs of these Sectors',
    HOTEL_DETAILS: 'Hotel details',
    RECOMMENDED_VACATION_PACKAGE: 'Recommended vacation package',
    BOOK: 'Book',
    DISCLAIMER: 'Disclaimer',
    CHOOSE_DIFFERENCT_RECOMMENDED_VACATION_PACKAGE: 'Choose a different recommended vacation package',
    VIEW_ALL_FLIGHTS: 'View all Flights',
    FLIGHTS_TO: 'Flights to',
    FULL_DETAILS: 'Full details',
    EXPLORE_MORE_DATES: 'Explore more Dates',
    FLIGHTS_SELECTED_DESTINATIONS: 'Flights to selected destinations',
    VIEW_ALL_VACATIONS_DEALS: "View all Vacations deals",
    VACATION_PACKAGES_TO_SELECTED_DESTINATIONS: "Vacation packages to selected destinations",
    VIEW_ALL_ORGANIZED_DEALS: 'View all organized deals',
    PRICE_SPECIFIC_FLIGHT_SHOWN: 'The price is for the specific flight shown',
    GO_TO_DEAL: 'Go to deal',
    BOOK_FLIGHT: 'Book Flight',
    BOOK_A_DEAL: 'Book a deal',
    ORDER_DETAILS: 'Order Details',
    TRIP_SUMMARY: 'Trip Summary',
    PERSONAL_ITEM: 'Personal Item',
    CABIN_BAGGAGE: 'Cabin Baggage',
    CHECKED_BAGGAGE: 'Checked Baggage',
    BAGGAGE_INFO: 'Baggage info',
    SELECT_ONE_OPTION: 'Select one option',
    NO_CHECKED_BAGGAGE: 'No checked baggage',
    TIP: 'Tip',
    FLIGHT_TIP_TXT: 'Travel safely by adding a checked bag instead. You can take less on board and avoid moving around the cabin.',
    TOTAL_ORDER_PRICE: 'Total order price',
    PASSENGERS_PASSPORT_DETAILS: 'Passengers Passport details',
    ORDER_SUMMARY_TXT: 'Order in process - Order confirmation will be sent within 48 hours',
    CHEAPEST_FLIGHTS: 'Cheapest flights',
    LAST_MINUTE_FLIGHTS: 'Last minute flights',
    DATE_RANGE: 'Date range',
    DURATION: 'Duration',

  },
  COMMUNICATION: {
    BEST_SELLERS: "Best Sellers",
    MESSAGES: 'Messages',
    PRICE_USE_ABROAD_ISRAEL: "Price use abroad Israel",
    OTHER_1: 'Other 1',
    OTHER_2: 'Other 2',
    SURFING: 'Surfing',
    GIGA_ANOTHER_2: 'Giga another 2',
    VALID_ON_DAYS: 'Valid on days',
    PRODUCT_WITH_NO_VAT: 'No VAT',
  },
  TOURISM_SUPPLIER: {
    TITLE: "Tourism Supplier Management",
    ARKIO: "Arkio",
    HP: "Hp",
    PROFIT_PERCENTAGES: "Profit percentages",
    CONTACTS: "Contacts",
    BANK_DETAILS: "Bank details",
    PROVIDER_NUMBER: "Provider number",
    SUPPLIER_CODE: "Supplier code in Amadeus",
    NAME_PROVIDER: "Name provider",
    HP_DEALER: "HP/Dealer",
    CONTACT: "Contact",
    MOBILE_CONTACT: "Mobile Contact",
    DISPLAY: "Display",
    FLIGHTS: "Flights",
    VACATIONERS: "Vacationers",
    ORGANIZED: "Organized"
  },
  DESTINATION: {
    TITLE: "Management of tourist destinations",
    ALL_AIRPORTS: "All airports",
    CONFIRM: "Confirm",
    COUNTRY: "Country",
    DESTINATION_NAME: "Destination name",
    AIRPORTS: "Airports",
    TAGS: "Tags",
    ORDER: "Order",
    VISA: "Visa",
    VIDEO_LINKS: "Video links",
    VIDEO: "Video"
  },
  TOURISM_PRODUCT: {
    SHARE_OVER: "Share over",
    FLIGHT_DETAILS: "Flight details",
    SIXTH: "Sixth",
    SABBATH: "Sabbath",
    WHAT_INCLUDE: "What is included in the flight",
    OVERTIME: "Overtime",
    INVITATION: "Invitaion",
    BOOKING_MODAL_TXT: "To complete the order please fill in the following details",
    WAY_PAYMENT: "Way of payment",
    CASH: "Cash",
    CREDIT: "Credit",
    DISCOUNT_MODAL_TITLE: "Change package price to customer",
    DISCOUNT_MODAL_TXT: "You can price or give a discount to the customer",
    ORIGINAL_PACKAGE_COST: "Original package cost",
    DISCOUNT_PERCENTAGE: "Discount percentage",
    INCREASE: "Increase",
    DISCOUNT: "Discount",
    DISCOUNT_EXP: "You can add unlimited and give a discount up to the amount of",
    PRICE_UPTO_DATE: "Price up to date",
    GIVE_DISCOUNT: "Give discount",
    GIVE_INCREASE: "Give a increase",
    FRONTAL_SERVICE: "Frontal Service",
    FRONTAL_CUT: "Your Frontal Service Cut",
    BASE_COMMISSION: "Your Base Commission",
    TOTAL_COMMISSION: "Your Total Commission",
    YOUR_COMMISSION: "Your commission",
    ROOMS: "Rooms",
    BABIES: "Babies",
    ROOM: "Room",
    THINGS_DOAREA_TXT: "Things to do in the area",
    OUT_OF_COMMISSION: "Out of this a commission",
    HOTEL_CHANGE: "Hotel change",
    ADD_ROOM: "Add room",
    FACILITIES: "Facilities",
    ROOM_TYPE: "Room type",
    HOSTING_BASE: "Hosting base",
    ACCOMMODATION_LEVEL: "Accommodation level",
    SEATS_LEFT: "{num} seats left",
    NO_STAR: "No star",
    YOUR_SERVICE_FEE: "Your service fee",
    YOUR_BASE_FEE: "Your base fee",
    YOUR_TOTAL_COMMISSION: "Your total commission",
    SEAT_LIMIT_TXT: "You can add  rooms for up to {num} people",
    REMOVE_ROOM: "Remove room",
    MAX_DECREASE_VALUE: "Max Decrease Value",
    GENERAL_RULES: "General rules",
    TRIP_ROUTE: "Trip route",
    DETAILED_TOUR_PLAN: "Detailed tour Plan",
    MANUALLY_DISCOUNT: "Manually discount",
    CHANGE_PRICE: "Change Price",
    CHNAGE_COMMISSION_TXT: "You can add or reduce your commission",
    ADD_TO_PRICE: "Add to price",
    CURRENT_TOTAL_PRICE: "Current Total price",
    CONTINUE_WITH_ORIGINAL_PRICE: "Continue with original price",
    NO_LIMITS_FOR_ADDING: "No limits for adding",
    DISCOUNT_IS_UP_TO: "Discount is up to",
    DISCOUNT_PLACEHOLDER_TXT: "Enter the Discount amount up to",
    ADD_PRICE_PLACEHOLDER_TXT: "Enter the amount you want to add to the price",
    DEAL_SUMMARY: "Deal Summary",
    PLACE_ORDER: "Place order"
  },
  GIFTCARDSUP: {
    ADDITIONAL_PRODUCTS: "Additional products"
  },
  GIFTCARD_PRODUCT: {
    PHONE_NUMBER: "Phone number",
    PHONE_VERIFY: "Phone number verification",
    MORE_DETAILS: "More details",
    SIMILAR_PRODUCTS: "Similar Products",
    GIFTCARD: "Giftcard",
    VERIFY_MODAL_TITLE: "Waiting for public mobile verification",
    VERIFY_MODAL_TEXT: "To complete the order, please pass the one-time verification code to the customer, so that they enter it at the dedicated link received via their mobile SMS",
    ONE_VCODE: "One-time verification code",
    APPROVAL: "Approval",
    CANCEL_RESERVATION: "Cancel reservation"
  },
  GIFTCARD_PAYMENT_RESULT: {
    INVITATION_ACCEPTED: "Invitation accepted",
    IN_TEL_AVIV: "In Tel Aviv",
    AGENT: "Agent",
    HAIM_LEVIATHAN: "Haim Leviathan",
    PRODUCT: "Product",
    QUANTITY: "Quantity",
    TOTAL: "Total",
    VAT: "VAT",
    TOTAL_PAYMENT: "Total payment including VAT"
  },
  GIFTCARD_PAYMENT_RESPUB: {
    TITLE: "The code was successfully received",
    ORDER_NUMBER: "Order Number",
    BUSINESS_NAME: "Business name",
    SUC_TEXT: "A purchase code will be updated here in the next few minutes",
    PURCHASE_CODE: "Purchase code",
    PURCHASE_EXTERNAL_ID: "Card Code",
    SERIAL_NUMBER: "Serial Number",
  },
  GIFTCARD_PAYMENT_VERIPUB: {
    ONETIME_CODE: "One-time code",
    DESC: "Enter the one-time code given to you in the store"
  },
  PAGE404: {
    ERROR_TEXT: "Ooops! Page not found!"
  },
  MANAGE_TRANSLATION: {
    TITLE: "Manage Translation",
    ENGLISH: "English",
    HEBREW: "Hebrew",
    ARABIC: "Arabic",
    GROUP: "Group",
    KEY: "Key"
  },
  VALIDATION: {
    REQUIRED_FIELD: "This is a required field.",
    INVALID_VALUE: 'Invalid value',
    MIN_VALUE: 'Min value is {value}',
    MAX_VALUE: 'Max value is {value}',
    INTEGER: 'Value should be integer',
    EXACT_LENGTH: 'Value length should be {value}',
    CURRENT_BALANCE: 'Current balance is {value}',
  },
  ALERT: {
    OPERATION_SUCCESS: "Operation Success",
    SUCCESS: "Success",
    INFORMATION: "Information",
    ERROR: "Error",
    MESSAGE: "Message",
    NO_FILTER_DATA: "No filter data",
    SUCCESS_MSG: "The transaction completed successfully",
    CLOSURE: "Closure",
    REQUIRE_PASSPORT_TXT: "Please fill in all passport details first",
    ERROR_PASSPORT_UPLOAD_TXT: "There is an error extracting the passport details from the picture",
    TRY_AGAIN: "Try again",
    DELETE_CONFIRM_TXT: "Are you sure you want to delete {NAME}?"
  },
  TOUR_ORDER: {
    TRANSACTION_DETAILS: "Transaction details",
    TRANSACTION_DETAILS_TXT: "Booking details travel dates Extras Destination",
    SWITCH_TO_PAYMENT: "Switch to payment",
    METHODS_OF_PAYMENT: "Methods of Payment",
    TOUR_PAYMENT_TXT: "Please enter your credit information for payment",
    TICKET_NUMBER: "Ticket Number",
    VALIDITY: "Validity",
    DIGITS_ON_CARD: "3 digits on the back of the card",
    CARDHOLDER_NAME: "Cardholder's name",
    TOUR_PAYMENT_BOTTOMTXT: "You can go through the order before confirming the payment",
    PASSPORTS_PLEASE: "Passports please",
    TOUR_PASSPORT_TXT: "Take photos of the passports of travelers",
    PASSPORT_PHOTO: "Passport photo",
    TOUR_PASSPORT_TXTCANT: "Can't take photos?",
    ENTER_MANUALLY: "Enter details manually",
    TOUR_PASSPORT_MTITLE: "This passenger has already been photographed",
    TOUR_PASSPORT_MTXT: "Please take another passport not taken",
    PASSENGER: "Passenger",
    ANOTHER_PASSPORT_PHOTO: "Another passport photo",
    PASSPORT_INFOR_VERIFICATION: "Passport information verification",
    TOUR_PERSON_TXT: "Please check the passenger information taken",
    FITST_NAME_EN: "First name in English",
    LAST_NAME_EN: "Last name in English",
    NUMBER_OF_PASSPORT: "Number of passport",
    ID_NUMBER: "ID number",
    DATE_OF_BIRTH: "Date of birth",
    PASSPORT_BASE_COUNTRY: "A country in which the passport was issued",
    GENDER: "Gender",
    MAN: "Man",
    WIFE: "Wife",
    SHOOT_AGAIN: "Shoot again",
    WHICH_PASSENGER_CUSTOMER: "Which of the passengers is the customer",
    TOUR_CONFIRM_TXT: "Booking details travel dates Extras Destination",
    ORDER_CONFIRMATION: "Order Confirmation",
    TOUR_CONFIRM_TXTBOTTOM: "By clicking on an order confirmation you confirm",
    SITE_POLICY: "Site's Policy",
    TOUR_SUCCESS_TXT: "The order can be tracked",
    TOUR_SUCCESS_LINK: "In the personal area",
    PASSPORT_UPLOAD_STATE: "{{num1}} of {{num2}} passports uploaded",
    PRICE_PER_PASSENGER: "Price per passenger",
    TOTAL_PRICE: "Total price",
    PRICE_BEFORE_DISCOUNT: "Price before discount",
    PRICE_BEFORE_INCREASE: "Price before increase",
    PRICE_TO_PAID: "Price to be paid",
    CANCEL_TRANSACTION: "Cancel Transaction",
    CURRENCEY_EXCHANGE_RATE: "Currency exchange rate",
    TOTAL_PRICE_TXT: "The total price is shown in shekels and rounded",
    ROUND_TRIP_FLIGHT: "Round trip flight",
    ACCOMMODATION: "Accommodation",
    PASSENGERS_VERHICLE_ROOMS: "Passengers and vehicle rooms",
    TRANSPORTATION: "Transportation",
    TICKETS: "Tickets",
    TV_SET: "TV set",
    FREE_PARKING: "Free parking",
    BATHROOM: "Bathroom",
    FULL_DETAIL_HOTEL: "Click for full details about the hotel",
    TRANSPORTATION_TITLE: "Transportation: Pick up and return from the airport",
    GO: "Go",
    DISTANCE: "Distance",
    ESTIMATED_TRANSPORTATION_TIME: "Estimated transportation time",
    TEL_AVIV: "Tel Aviv",
    SELECT_CUSTOMER_TXT: "Please, select the customer",
    PASSPORT_EXPIRATION_DATE: "Passport expiration date",
    PASSENGER_PASSPORT_DETAILS: "Passenger passport details"
  },
  GENERAL_SETTING: {
    TOURISM_SETTINGS: "Tourism Settings",
    OFF_REPLACEMENT_TXT: "Offer replacement packages when the price is expensive up to",
    PERCENT: "Percent",
    PERCENT_ABSORPTION_TXT: "Percentage absorption price increase for cash purchases",
    DAYS_MARKETER_TXT: "Number of days a marketer is eligible for a commission",
    MINIMUM_PROFIT_TXT: "Minimum profit percentage for Plators",
    ADD_PENNY_TXT: "Adding pennies to a currency exchange fee",
    DAYS: "Days",
    PENNIES: "Pennies",
    PASSPORT: "Passport",
    ACURRA:"Acurra",
    MRZ_SCANNER:"mrz-scanner",
    ORGANIZED_GENERAL_RULES: "Organized General Rules",
    ORGANIZED_CANCELLATION_DETAILS: "Organized Cancellation Details"
  },
  TOURISM_PROFIT_PERCENTAGES: {
    DISTRIBUTOR: "Distributor",
    MARKETER: "Marketer",
    CLIENT_NO: "Client No",
    MOBILE_PHONE: "Mobile Phone",
    HP_AUTHORIZED_DEALER: "H.P. / Authorized Dealer",
    PLOTTERS: "Plotters",
    PLATORS_ALL_RESELLERS: "Plators all resellers",
    SEARCH_CUSTOMER_NUMBER: "Search customer number",
    MOBILE_PHONE_SEARCHING: "Mobile phone for searching",
    PERCENTAGE_CASH_PROFIT: "Percentage of cash profit",
    DIRECT_SALES_COMMISSION: "Direct sales commission",
    REFERRAL_SALES_COMMISSION: "Referral sales commission",
    CREDIT_PROFIT_PERCENTAGE: "Credit profit percentage",
    SALES_COMMISSION_DIRECT_LINK: "Sales commission direct link",
    INDIRECT_LINK_SALES_COMMISSION: "Indirect link sales commission",
    FRONTAL_REPRESENTATIVE_FEE: "Frontal representative fee",
    FRONTAL_TXT: "Automatic amount according to the currency of the transaction",
  },
  RESELLER_TOUR_SETTINGS: {
    RESELLER_TOUR_SETTINGS: "Reseller Tour Settings",
    RESELLER_ID: "Reseller ID",
    DIRECT_SALE_FEE: "Direct Sale Fee",
    AFFILIATE_SALE_FEE: "Affiliate Sale Fee",
    CREDIT_SALE_FEE: "Credit Sale Fee",
    LINK_SALE_FEE: "Link Sale Fee",
    SECONDLINK_SALE_FEE: "SecondLink Sale Fee",
    FRONT_SALE_FEE: "Front Sale Fee",
    DIRECTFRONT_SALE_FEE: "DirectFront Sale Fee",
    EDIT_RESELLER_SETTINGS: "Edit Reseller Settings",
    ADD_RESELLER_SETTINGS: "Add Reseller Settings"
  },
  REGISTER_RESELLER: {
    MARKETER_REGISTRATION_BUSINESS: "Marketer Registration: Business Details",
    WELCOME_ENTER_TXT: "Welcome, please enter the following details",
    EMAIL_ACCOUNTING: "Email Accounting",
    VENDOR_PROFILE: "Vendor profile",
    PROFILE_PERCENTAGE_PROFILE: "Profit Percentage Profile",
    FEES_PROFILE: "Fees profile",
    TOURISM_PERCENTAGE_PROFILE: "Tourism Percentage Profile",
    BUSINESS_NAME: "Business name",
    AUTHORIZED_DEALER_HP: "Authorized Dealer / H.P.",
    TYPE_OF_BUSINESS: "Type of business",
    BUSINESS_PHONE: "Business phone",
    MOBILE_PHONE_CONTACT: "Mobile phone contact",
    ARTICLE: "Article {num}",
    FRIDAY_HOLIDAY_EVES: "Friday and holiday eves",
    SATURDAYS_HOLIDAYS: "Saturdays and holidays",
    SUNDAYS_THURSDAYS: "Sundays-Thursdays",
    TITLE2: "Marketer Registration: Owner Details and Login",
    ENTER_FOLLOWING_DETAILS: "Please enter the following details",
    ADDING_ADDITIONAL_OWNERS: "Adding additional owners",
    TITLE3: "Marketer Registration: Security and Payment Details",
    LINE_OF_CREDIT: "Line of credit",
    BANK_ACCOUNT_INFORMATION: "Bank account information",
    BANK: "Bank",
    BANK_NUMBER: "Bank number",
    ACCOUNT_NUMBER: "Account Number",
    BRANCH: "Branch",
    COLLECTION_DAYS: "Collection days",
    A: "A",
    B: "B",
    C: "C",
    D: "D",
    E: "E",
    F: "F",
    REMARKS: "Remarks",
    TITLE4: "Marketer Registration: Document Management",
    AUTHORIZED_DEALER_CERTIFICATE: "Authorized Dealer Certificate",
    COMMUNICATION_CONTRACT: "Communication contract",
    PERSONAL_GUARANTEE: "Personal guarantee",
    HAWK_APPROVAL: "Hawk approval",
    ADD_A_DOCUMENT: "Add a document",
    FINISHED: "Finished",
    DOCUMENT_TYPE: "Document type",
    UPLOAD_DOCUMENT: "Upload a document",
    CHIC_PHOTO: "Chic photo",
    AGENT_RESPONSIBLE: 'Agent Responsible',
    DEFINE_SELLER_AS_A_DISTRIBUTOR: 'Define Seller as a Distributor',
    AS_DISTRIBUTE_WARNING_TXT: '* Attention! Impossible to cancel the saving.',

  },
  REPORT_SALES: {
    SALES: "Sales",
    SALES_REPORT: "Sales report",
    TIME_FILTERING: "Time filtering",
    FROM_DATE: "From date",
    UNTIL: "Until",
    START_HOUR: "Start hour",
    END_HOUR: "End hour",
    WITHOUT_TIME_LIMITS: "Without time limits",
    RANGE_OF_DATES: "Range of dates",
    GENERAL_FILTERING: "General filtering",
    DOUBT: "Doubt",
    FORM_OF_PAYMENT: "Form of payment",
    MOBILE: "Mobile",
    CONSUMER_PRICE: "Consumer price",
    COST_PRICE: "Cost Price",
    STATUS: "Status",
    REQUEST_FOR_CANCELLATION: "Request for cancellation",
    CELLCOM: "Cellcom",
    AMAZON: "Amazon",
    NETFLIX: "Netflix",
    MATT: "Matt",
    COMPLETED: "Completed",
    BY_INVITATION: "By invitation",
    OUT_OF: "Out of",
    UTILIZED: "Utilized",
    APPLY: "Apply",
    IN_TREATMENT: "In treatment",
    CANCELED: "Canceled",
    PARTIALLY_CANCELED: "Partially canceled",
    FOR_FULL_DETAILS: "For full details",
    NUMBER_OF_PASSENGERS: "Number of passengers",
    HOSTING: "Hosting",
    STARS: "Stars",
    HOTEL: "Hotel",
    DESTINED: "Destined",
    CUSTOMER_NAME: "Customer name",
    TRANSACTION_TYPE: "Transaction type",
    NUMBER_OF_TRANSACTION: "Number of transaction",
    EXPORT_TO_EXCEL: "Export to Excel",
    FOR_PRINTING: "For printing",
    SEARCH: "Search",
    EVERYTHING: "Everything",
    START_DATE: "Start Date",
    END_DATE: "End Date",
    SUPPLIER_ID: "Supplier ID",
    EXTERNAL_ORDER_ID: "External Order ID",
    GIFT_CARD_CODE: "Gift Card Code",
    CONTRACT_NUMBER: "Contract number",
    BILL_NUMBER: "Bill number",
    CANCELLED: "Cancelled",
    OPENDED: "Opened",
    SUCCESS: "Success",
    PENDING: "Pending",
    FAILED: "Failed",
    INVOICES_RECEIPTS: 'Invoices and receipts',
    VIEWING: 'Viewing',
    DOWNLOAD: 'Download',
    RECEIPT_TAX_INVOICE: 'Receipt tax / Invoice',
    RAV_KAV_TRANSACTION: 'Multi-line transaction number',
    RAV_KAV_CARD_NUMBER: 'Ticket Number',
    CONTRACT_TYPE: 'Contract Type',
    CONTRACT_NAME: 'Contract name',
    CANCEL_STATUS: 'Transaction cancellation status',
    AWAITING: 'Waiting',
    REJECT: 'Rejected',
    NO_CANCEL_REQUEST: 'No cancellation request was submitted',
    DISTRIBUTER_FILTERING: 'Filter By Distributer',
    DISTRIBUTER: 'Distributer',
    BUSINESS_NAME: 'Business Name',
    CLIENT_NO: 'Client No.',
    AGENT_CODE: 'Agent Code',
    PHONE_NUMBER: 'Phone Number',
    AUTHORIZED_DEALER_HP: 'Authorized Dealer',
    IP: 'IP',
    TAGS: 'tags',
    SELLER_POINTS: 'Seller points',
    DISTRIBUTER_PRICE: 'Dist Price',
    AGENT: 'agent',
    CITY: 'city',
    CUSTOMER_ID: 'Client No.',
    DEAL_ID: 'Deal No.',
    BUSINESS_PRICE: 'Business price',
    AGENT_ID: 'Agent No.',
    GAME_CARD_CODE: "Game Card Code",
    SERIAL_NUMBER: "Serial Number",
    PIN_CODE: "PIN CODE",
    NOT_CANCELLED: 'Not cancelled',
    REJECTED: 'Rejected',
    CANCEL_ACTION: 'Cancel action',
    STATUS_DATE: 'Status Date',
    STATUS_START_DATE: 'Status Start Date',
    STATUS_END_DATE: 'Status End Date'
  },
  CARMEL_TUNNELS: {
    PAYMENT_CARMEL_TUNNELS: "Payment of the Carmel Tunnels",
    ID_NUMBER: "ID number",
    INVOICE_LAST_DIGITS: "Invoice 6 last digits",
    INVOICE_SUM: "Invoice sum",
    PAYMENT_SUM: "Payment sum",
    MOBILE_PHONE_NUMBER: "Pay",
    RESULT_TXT: "At the end of the payment need to give client receipt",
    WHOLE: "Whole",
    VERIFICATION_BEFORE_LOADING: "Verification of customer information before loading",
    DETAILS_PLACE_TRANSACTION: "Details of the place of transaction",
    TRANSACTION_APPROVAL: "Transaction approval number",
    INVOICE_DETAILS: "Invoice details",
    NUMBER_CLEARINGHOUSE: "Number clearinghouse",
    SERVICE_FEE: "Service fee",
    AFTER_VERIFY_TXT: "After verifying the details the transaction can not be canceled!",
    CONFIRMATION_PURCHASE: "Confirmation of details and purchase"
  },
  SUPPLIER_PAYMENT: {
    CANCEL: 'Cancel',
    CANCEL_PAYMENT: 'Cancel a transaction',
    LOAD_MULTILINE_CARD: "Load a multi-line card",
    RAVKAV_READER_DISCONNECTED: 'Card reader not found - please connect it and try again',
    RAVKAV_NO_CARD: 'Card not found in card reader - please insert the card and try again',
    RAVKAV_FAILURE_CARD_EXTRACTED: 'The card was extracted from the card reader - please insert the card and try again',
    MULTILINE_CARD_TXT1: "Insert a multi-line card into the card reader with the chip facing up",
    MULTILINE_CARD_TXT2: "If multi-line software is not installed on the computer, it should be downloaded and installed before use.",
    CANCELATION: "Cancelation",
    CLICK_VIEW_CHARGE_CARDS: "Click to view card charge cards",
    POPUP_MUST_CONFIRMED: "The pop-up window must be confirmed",
    WINDOWS: "Windows",
    UBUNTU: "Ubuntu",
    MACINTOSH: "Macintosh",
    MONTHLY_SENSES: "Monthly senses",
    FREE_DAILY: "Free daily",
    FREE_WEEKLY: "Free weekly",
    FREE_FLEXIBLE: "Free flexible",
    ACCUMULATED_VALUE: "Accumulated value",
    JERUSALEM: "Jerusalem",
    MORE_FILTERS: "More filters",
    RAVKAV_CONFIRM_TXT: "The multi-line card was loaded successfully",
    MULTILINE_CARD_NUMBER: "Multi-line card number",
    CARD_TYPE: "Card Type",
    CARD_LOADING: "Card loading",
    FINISHED_AND_CLOSED: "Finished and closed",
    BUS_CARD: "Bus Card",
    UPGRADE_TO: "Upgrade to",
    MULTILINE_TRAVEL_CONTRACTS: "Multi-line travel contracts",
    EXPIRED: "Expired",
    CLAIM: "Claim",
    PAYMENT_INSTANT_INVOICE: "Payment of an instant invoice",
    ID_NUMBER: "ID number",
    INVOICES: "Invoices",
    AMOUNT_OF_INVOICES: "Amount of invoices",
    CHECK: "Check",
    INTERNET_SUBSCRIPTION_NUMBER: "Internet subscription number",
    ID_OWNER_HP: "ID number of the owner of the line / H.P.",
    BEZEQ_TXT1: "According to Bezeq records, invoices below have not yet been paid",
    BEZEQ_TXT2: "You can uncheck invoices that you would like to pay at another time",
    DEADLINE_FOR_PAYMENT: "Deadline for payment",
    INVOICING: "Invoicing",
    TOTAL_SELECTED_INVOICES: "Total selected invoices",
    TOTAL_PAYMENT: "Total payment",
    BEZEQ_TXT3: "* There is a fee of 7 NIS for each Bezeq invoice",
    BEZEQ_FOOTER_TXT: "Once the payment is made the transaction cannot be canceled!",
    PAYMENT_POLICE_REPORTS: "Payment of police reports",
    PAYER_CUSTOMER_NUMBER: "Payer / customer number",
    REPORT_NUMBER: "Report number",
    POLICE_REPORT_NUMBER: "Police report number",
    POLICE_REPORT: "Police report",
    ID: "ID",
    COMPANY: "Company",
    PASSPORT: "Passport",
    DRIVING_LICENSE: "Driving License",
    POLICE_DATABASE_TXT: "3 open reports for payment were found in the police database",
    PORTFOLIO_VOUCHER_TAX: "Central portfolio tax / voucher tax in the arrangement",
    DEBT_FILE_FACTOR: "Debt file factor factor",
    DATE_OF_PAYMENT: "Date of payment",
    FOUNDATION: "Foundation",
    ARREARS_AND_EXPENSES: "Arrears and expenses",
    NUMBER_SELECTED_REPORT: "Number of selected reports",
    TOTAL_SUM_ACCOUNTS: "Total sum of accounts",
    MOBILE_PHONE_RECEPTION: "Mobile phone number for reception",
    POLICE_FOOTER_TXT1: "Note ! The customer is aware",
    POLICE_FOOTER_TXT2: "That if there is a debt of the type of compensation for the victim of an offense in a criminal proceeding, any amount paid will first be credited in favor of covering this debt",
    PURCHASE_POWER_CODE_TXT: "Purchase a power charging code",
    PAY_ATTENTION_TXT: "Pay attention!! According to the IEC guidelines, the customer should not be charged a fee",
    COMMISSION_DES_TXT: "** Commission for this payment in the system is [commission_amount] NIS",
    CUSTOMERS_NAME: "Customer's name",
    PURCHASE_AMOUNT: "Purchase amount",
    TOTAL_NET_EXCLUDING_VAT: 'Total net amount',
    KWH: 'KWh',
    PAYMENT_DEBTS_TXT: "Amount to debts",
    TOTAL_CHARGES_ADDITIONAL_CREDITS: "Total charges - additional credits",
    BUSINESS_ADDRESS: "Business address",
    LOADING_CODE: "Loading code",
    MUNICIPAL_TXT1: "Payment to the authorities - property tax Beer Sheva",
    MUNICIPAL_TXT2: "Payment to the authorities - property tax Beer Sheva was carried out successfully",
    PAYER_CUSTOMER_ID: "Payer / customer / ID number",
    CLEARANCE_VOUCHER_APPENDIX_NUMBER: "Clearance / voucher / appendix number",
    PAYMENT_TO_AUTHORITIES: "Payment to authorities",
    SELECT_PERMISSION: "Select permission",
    TYPE_PAYMENT_AUTHORITY: "Type of payment in the authority",
    PAYMENT_6CROSSES_NORTH: "Payment 6 crosses north",
    PAYMENT6_SUCCESS_TXT: "Payment 6 North Cross was made successfully",
    REQUESTED_PRICE: "Requested Price",
    COMMISSION: "Commission",
    TOTAL_PRICE: "Total Price",
    PAYMENT_FAILED: "Payment failed, please contact support or try again later",
    SOMETHING_WENT_WRONG: "Something went wrong, please contact support or try again later",
    INCORRECT_CODE: "Incorrect code",
    PAYMENT: 'Payment',
    PAY: 'Pay',
    PAID: 'Paid',
    METER_NUMBER: 'Meter number',
    APPROVAL_NUMBER: 'Approval number'
  },
  TOURISM_REPORT: {
    TRANSACTION_DETAILS: "Transaction details",
    PURCHASE_DATE: "Purchase date",
    PAYMENT: "Payment",
    A_CONFIRMATION: "A. Confirmation",
    NO_OF_PASSENGERS: "No. of passengers",
    TOTAL: "Total",
    SELLING_RESELLER: "Selling reseller",
    PASSPORT_DETAIL_TXT: "Please check the passenger details taken, correct / confirm if necessary"
  },
  PAYMENT_REPORT: {
    AMOUNT_RECEIVED: "Amount Received",
    TYPE_OF_PAYMENT: "Type of payment",
    FOR: "For",
    RECEPTION_NUMBER: "Reception number",
    PAYMENT_REPORT: "Payment report"
  },
  SALES_PAMENT_REPORT: {
    SALES_PAMENT_REPORT: "Sales and payments report",
    TAX_ACCEPTANCE_TRANSACTION: "Tax. Acceptance transaction",
    CONSUMER_PRICE: "Consumer price",
    CURRENT_BALANCE: "Current balance"
  },
  FACILITY: {
    AIRCONDITION: "Air Condition",
    HAIRDRYER: "Hair Dryer",
    PHARMACY: "Pharmacy",
    BABYSITTING: "Baby Sitting",
    HAIRDRESSER: "Hair Dresser",
    PHONEDIRDIAL: "Phone Dirdial",
    BAR: "Bar",
    HANDICAP: "Handicap",
    RESTAURANTS: "Restaurants",
    BEACH: "Beach",
    HEALTHCLUB: "Health Club",
    ROOMSERVICE: "Room Service",
    BEAUTYPARLOUR: "Beauty Parlour",
    ICEMACHINES: "Ice Machines",
    SAFEDEPBOX: "Safe Dep Box",
    BUSINESSCTR: "Business CTR",
    JACUZZI: "Jacuzzi",
    SAUNA: "Sauna",
    CARRENTAL: "Car Rental",
    KITCHEN: "Kitchen",
    SIGHTSEEING: "Sightseeing",
    CASINO: "Casino",
    LAUNDRYSVC: "Laundry SVC",
    SNOWSKIING: "Snow Skiing",
    COFFEESHOP: "Coffee Shop",
    LOUNGE: "Lounge",
    SOLARIUM: "Solarium",
    CONVENTIONCTR: "Convention CTR",
    MASSAGE: "Message",
    SPA: "SPA",
    DISCO: "Disco",
    MEETINGROOMS: "Meeting Rooms",
    SWIMMINGPOOL: "Swimming Pool",
    SWIMMINGPOOLINDOOR: "Swimming Pool Indoor",
    ELEVATOR: "Elevator",
    MINIBAR: "Minibar",
    TENNIS: "Tennis",
    EXCHANGEFAC: "Exchange FAC",
    MOVIECHANNELS: "Movie Channels",
    TELEVISION: "Television",
    EXECUTIVEFLR: "Executive FLR",
    NONSMOKINGRMS: "Nonsmoking RMS",
    VALETPARKING: "Valet Parking",
    FAXFACINROOM: "Fax Facin Room",
    PARKING: "Parking",
    WATERSPORTS: "Water Sports",
    GIFTSHOP: "Gift Shop",
    PCHOOKUPINRM: "Pc Hook Up In RM",
    WOMENSGSTRMS: "Women's GSTR MS",
    GOLF: "Golf",
    PETSACCEPTED: "Pets Accepted",
  },
  TOUR_PLACES: {
    RECOMMENDED_TOURIST_SITES: "Recommended tourist sites",
    SHOPPING: "Shopping",
    FOOD_AND_RESTAURANTS: "Food and restaurants",
    MUSEUMS: "Museums",
    READ_MORE: "Read more",
    LOCAL_NAME: "Local Name",
    LOCATION: "Location",
    MORE_INFO_CONTACT: "More information and contact",
    WIKIPEDIA: "Wikipedia",
    OFFICIAL_SITE: "Official site",
    ENTRANCE_FEE: "Entrance Fee",
    COORDINATES: "Coordinates",
    REDUCED: "Reduced",
    CHILDREN_LABEL: "Children (under 18 years old)",
    FREE: "Free"
  },
  ROOM: {
    REGULAR: "Regular",
    CLASSIC_ROOM: "Classic Room",
    DELUXE: "Deluxe",
    JUNIOR_SUITE: "Junior Suite",
    REGULAR_ROOM_STANDARD: "REGULAR ROOM STANDARD",
    SEA_VIEW_STANDARD: "SEA VIEW STANDARD",
    STANDARD_ROOM: "Standard Room",
    APARTMENT_ONE_BEDROOM: "Apartment One Bedroom",
    APARTMENT_TWO_BEDROOMS: "Apartment Two Bedrooms",
    STUDIO: "Studio",
    STANDARD: "STANDARD",
    STANDART: "STANDART",
    FAMILY_ROOM: "FAMILY ROOM",
    DELUXE_ROOM: "Deluxe Room",
    SUPERIOR_ROOM: "SUPERIOR ROOM",
    RUN_OF_THE_HOUSE: "RUN OF THE HOUSE SCUKO!"
  },
  FARE_BASIS: {
    AI: "AI",
    BB: "BB",
    "F+": "F+",
    FB: "FB",
    HB: "HB",
    "HB+": "HB+",
    KH: "KH",
    KS: "KS",
    RO: "RO",
    UA: "UA",
    UAI: "UAI",
    UI: "UI",
  },
  COMMUNICATION_PRODUCT: {
    CONFIRMATION_PHONE_NUMBER_MODAL_TITLE: "Mobile phone confirmation",
    CONFIRMATION_PHONE_NUMBER_MODAL_TEXT: "Please confirm the number [phone_number] is correct"
  },
  RESELLER_DETAILS: {
    TEMPORARY_FRAMEWORK: "Temporary framework",
    BALANCE_FOR_UTILIZATION: "Balance for utilization",
    BALANCE_UPDATE: "Balance update",
    SUM: "Sum",
    PAYMENT_METHODS: "Payment Methods",
    UPDATE_BALANCE: "Update balance",
  },
  ADS_MANAGEMENT: {
    BANNER_MANAGEMENT: "Banner management",
    BANNERS: "Banners",
    ADVERTISER: "Advertiser",
    BANNER_STATUS: "Banner status",
    TYPE: "Type",
    THERE: "There",
    LANGUAGE: "Language",
    LENGTH: "Length",
    A_BEGINNING: "A. Beginning",
    A_END: "A. End",
    HOURS: "Hours",
    PRESENTATION: "Presentation",
    EDITING: "Editing",
    BOUNCE: "Bounce",
    ADVERTISERS: "Advertisers",
    NEW_BANNER_EDIT: "New banner / edit",
    NAME_PUBLICATION: "Name of publication",
    NUMBER_OF_BOUNCES: "Number of bounces",
    PAGE: "Page",
    LINK_TO_PAGE: "Link to page",
    CHOOSE_POINT_RESELLER: "Choose point-of-sale resellers",
    ADVERTISING_START_TIME: "Advertising start time",
    POST_TIME_PUBLICATION: "Post time of publication",
    ADVERTISING_HOURS: "Advertising hours",
    PRICING: "Pricing",
    PRICING_LIST: "Pricing list",
    ADVERTISING: "Advertising",
    PAYMENT_METHOD: "Payment method",
    PREVIEW: "Preview",
    THE_SIZE_PUBLICATION_IS: "The size of the publication is",
    SHOW: "Show",
    PAID_UP: "Paid up",
    OPEN: "Open",
    IMAGE: "Image",
    VIDEO: "Video",
    BANNERS_ADVERTISERS: 'Banners - Advertisers',
    PHONES: 'Phones',
    RESPONSIBLE_ACCOUNTING: 'Responsible for the accounting',
    ACCOUNTING_TELEPHONE: 'Accounting telephone',
    OPEN_DEBT: 'Open debt',
    A_REGISTRATION: 'A. Registration',
    BAIL: 'Bail',
    CLOSE_CHARGE: 'Close charge',
    DETAIL_EDITING: 'Details and editing',
    NEW_PUBLISHER: 'New publisher',
    BACK_TO_BANNER: 'Back to banners',
    NEW_PUBLISHER_EDITOR: 'New publisher / editor',
    ADVERTISER_NAME: 'Advertiser name',
    SITE: 'Site',
    DATE_OF_ESTABLISHMENT: 'Date of establishment',
    FROM_GOOGLE: 'From Google',
    ACCOUNTANTS: 'Accountants',
    TELEPHONE_BOOKS: 'Telephone books',
    CREDIT_DETAILS: 'Credit details',
    ID: 'ID',
    LIST_ADVERTISEMENTS_PRICES: 'List of advertisements and prices',
    DATE_PLEASE: 'Date please',
    PAYMENT_STATUS: 'Payment status',










  },
  MANAGE_PRODUCT: {
    SELECT_CATEGORY: "Select a category",
    SELECT_PROVIDER: "Select a provider",
    CHOOSE_PRODUCT: "Choose a product",
    PRODUCT_NAME_HEBREW: "Product name: Hebrew",
    PRODUCT_NAME_ENGLISH: "Product name: English",
    PRODUCT_NAME_ARABIC: "Product name: Arabic",
    PRODUCT_DESCRIPTION_HEBREW: "Product description: Hebrew",
    PRODUCT_DESCRIPTION_ENGLISH: "Product description: English",
    PRODUCT_DESCRIPTION_ARABIC: "Product description: Arabic",
    TRANSLATION_ADDITIONAL_LANGUAGES: "Translation of additional languages",
    PRODUCT_DETAILS: "Product Details",
    MINUTES_OF_CONVERSATION: "Minutes of conversation",
    SMS_MESSAGES: "SMS messages",
    MOBILE_BROWSING: "Mobile browsing",
    GENERAL_BUDGET: "General budget",
    AUTHORIZED_FOR_REVOCATION: "Authorized for revocation",
    DEFAULT_IN_SETTING_RESELLER: "Default in setting up a reseller",
    PRODUCT_WITHOUT_VAT: 'Product without VAT',
    PRODUCT_SETTINGS: "Product settings",
    ID_AT_VENDOR: "ID at the vendor",
    ICOUNT_PRODUCT_NUMBER: "iCount Product Number in",
    PROFIT_MODEL: "Profit model",
    DISTRIBUTION_FEE: "Distribution fee",
    PURCHASE_PRICE: "Purchase price",
    CURRENCY: "Currency",
    MINUTES_AVAILABLE: "Minutes available",
    INTERNET: "Internet",
    UNLIMITED: "Unlimited",
    DEFINE_VIEW_ADDITIONAL_DETAIL: "Define and view additional details",
    ADDITIONAL_DETAILS: 'Additional Detail',
    DEFAULT_LANGUAGE: 'Default Language'
  },
  MANAGE_SHANIV: {
    DUTY_FREE: 'Duty free',
    SHANIV_ID: "Shaniv ID",
    CATEGORY: "Category",
    SUBCATEGORY: "Subcategory",
    CATEGORY_NUMBER: "Category number",
    MATERIAL: "Material",
    BRAND: "Brand",
    PARKING: "Packing",
    SELECT_IMAGE: "Select an image",
    NAPKINS: "Napkins",
    TOWELS: "Towels",
    HANDKERCHIEFS: "Handkerchiefs",
    TOILET: "Toilet",
    SHANIV: "Shaniv",
    SASATK: "Sasatk",
    DESCRIPTION: 'Product Description',
    NUMBER_UNITS_PACKAGE: "Number of units in the package",
    NUMBER_UNITS_PER_SURFACE: "Number of units per surface",
    SKU: "SKU",
    BARCODE: "Barcode",
    ADD_TO_BASKET: "Add basket",
    ADD_BOTH_ITEMS_TO_CART: "Add both items to the cart",
    PRICE_PER_UNIT: "Price per unit",
    DISCOUNT_PURCHASE_SURFACE: "Discount of {num}% on surface purchase",
    RECOMMENDED_RETAIL_PRICE: "Recommended retail price",
    PACKAGE_OFFER: "Package offer",
    SAVE: "Save",
    ADDED_TO_PACKAGE: "Added to package",
    ONLY: "Only",
    PRODUCTS_PURCHASED_TOGETHER_OFTEN: "Products purchased together often",
    ADD_SELECTED_ITEMS_TO_CART: "Add selected items to the cart",
    CUSTOMERS_ALSO_SAW: "Customers also saw",
    SURFACE: "Surface",
    PRODUCT_PROMOTIONS: "Product promotions",
    OPERATION_NUMBER: "Operation number",
    ITEM1: "Item 1",
    ITEM_QUANTITY1: "Item quantity 1",
    ITEM2: "Item 2",
    ITEM_QUANTITY2: "Item quantity 2",
    ITEM3: "Item 3",
    ITEM_QUANTITY3: "Item quantity 3",
    NORMAL_PRICE: "Normal price",
    SALES_PRICE: "Sale price",
    OPERATION_DATE: "Operation date",
    OPERATION_IMAGE: "Operation image",
    OPERATION_NAME: "Operation name",
    TYPE_OF_OPERATION: "Type of operation",
    ADD_PRODUCTS_FROM_LIST: "Add products from the list",
    VOID: "Void",
    VALID_UNTIL: "Valid until",
    START_TIME: "Start time",
    END_TIME: "End time",
    EXTPART_PRODUCT_CODE: "EXTPART product code",
    PARTNAME: "PARTNAME",
    BARCODE_BARCODE: "BARCODE barcode",
    PRICE_PRICE: "PRICE price",
    PRICE_INCLUDING_VAT: "price including vat",
    PRODUCT_DESCRIPTION_HE: "product description: hebrew",
    PRODUCT_DESCRIPTION_EN: "product description: english",
    PRODUCT_DESCRIPTION_AR: "product description: arabic",
    PRICE_FROM_LOW_TO_HIGH: 'Price from low to high',
    PRICE_LISTS: 'Price lists',
    USER_MANUAL: 'User manual',
    AMOUNT_TO_ORDER: 'Amount to order',
    ADD_TO_CART_PROMOTION: 'Add to cart the promotion',
    CLEAR_FILTERS: 'Clear filters',
    AGENT: 'Agent',
    AGENT_NAME: 'Agent Name',
    AGENT_PHONE: 'Agent Phone number',
    SET_PACKAGE_QUANTITY_MANUALLY: 'Set package quantity',
    MANUAL_PACKAGE_QUANTITY: 'Package quantity',
    SET_PALLET_QUANTITY_MANUALLY: 'Set pallet quantity',
    MANUAL_PALLET_QUANTITY: 'Pallet quantity',
    IMPORT_SASTECH_QUANTITY_EXCEL: 'Import sastech quantity',
    IMPORT_FILE: 'Import file',
    IMPORT: 'Import',
  },
  BUSINESS_DETAILS: {
    BUSINESS_DETAILS: "Business details",
    PLAFAID_RESELLER: "Plafaid reseller",
    SHANIV_MARKETER: "Shaniv marketer",
    SHENIV_CUSTOMER_TAX: "Sheniv customer tax",
    FREEZE: 'Freeze',
    UNFREEZE: 'Unfreeze',
    SHOE: "Shoe",
    LOCK: 'Lock',
    UNLOCK: 'Unlock',
    SEARCH_SHANIV_CUSTOMERS: "Search for Shaniv customers",
    CUSTOMER_SEARCH: "Customer Search",
    MULTILINE_POSITION: 'Multi-line position',
    SHANIV_PLNAME: 'PLNAME ({company})',
    SHANIV_CUSTNAME: 'CUSTNAME ({company})',
    YOU_WANT_DELETE_BUSINESS: 'Do you want to delete this business ({business})?',
  },
  CUSTOMER_LISTS: {
    CUSTOMER_LISTS: "Customer lists",
    TAX_CLIENT: "Tax.client",
    SECTOR: "Sector",
    AREA_CODE: "Area code",
    REGION_NAME: "Region name",
    AUTHORIZED_DEALER_HP: "Authorized Dealer / H.P.",
    AGENT_CODE: "Agent code",
    AGENT_NAME: "Agent name",
    OBLIGO: "Obligo",
    PLATOK_CUSTOMER: "Platok customer",
    EXPLAND_FULL_TABLE: "Expand to full table",
    UPLOADING_NEW_LIST: "Uploading a new list",
    BECOME_CUSTOMER: "Become a customer",
    LIST_NAME: "List name",
    EXISTING_CUSTOMER_FLAPAID: "An existing customer at FlaPaid",
    FLAPPY_CUSTOMER_TAX: "Flappy customer tax",
    CHECK_ALL: 'Check All',
    CREATE_USER: 'Create user',
    PAYDES: 'PAYDES'
  },
  SASTECH: {
    ADDED_TO_CART: "Added to cart",
    TO_BOX_OFFICE: "To the box office",
    OUT_OF_ITEMS: "{num1} out of {num2} items",
    TOTAL_ADDED_TXT: 'Total added to shopping cart',
    CONTENT: "Content",
    TOTAL_IN_CART: 'Total in cart',
    HOT_SALES: "Hot sales",
    COMPLEMENT: "Complement",
    INSTITUTIONAL: "Institutional",
    PAPER_PRODUCTS: "Paper products",
    DISH_WASHING: "Dish washing",
    BLEACH: "Bleach",
    DISINFECTION_CLEANING: "Disinfection & cleaning",
    FLOOR_CLEANING: "Floor cleaning",
    BEFORE_DISCOUNT: "Before discount",
    ALL_PRODUCTS: "All products",
    PLEASE_SELECT_QUANTITY: 'Please select quantity',
    SHOPPING_CART: 'Shopping cart',
    DELIVERY_DATE: 'Delivery Date',
    PAYMENT_METHOD: 'Payment method',
    PROMOTIONS: 'Promotions',
    MAKE_RESERVATION: 'Make a Reservation',
    PACKAGES: 'Packages',
    ADD_PRODUCTS: 'Add products',
    ADD_PACKAGES: 'Add packages',
    PACKAGE_QUANTITY: 'Package quantity',
    BARCODE: 'Barcode',
    UNIT_PRICE: 'Unit price',
    PACKAGE_PRICE: 'Package price',
    ITEMS: 'Items',
    FRAMEWORK_EXPLOITATION_SASSATECH: 'Framework for exploitation in Sassatech',
    MINIMUM_ORDER: 'Minimum order',
    PRODUCTS: 'Products',
    SORT_DEFAULT: 'Default sorting',
    SORT_PRICE: 'Price:',
    SORT_DIRECTION_ASC: 'low to high',
    SORT_DIRECTION_DESC: 'high to low',
    PAPER_PAPER_PRODUCTS: 'Paper & paper products',
    SPRAYS: 'Sprays',
    LAUNDRY: 'Laundry',
    CULTIVATION: 'Cultivation',
    ONE_TIME: 'One-time',
    TOILET_BATHROOMS: 'Toilets & bathrooms',
    APPLY_FILTER: 'Apply Filter',
    CLEAR: 'Clear',


  },
  MANAGEMENT: {
    TIMING_VAT_CHANGE: 'Timing of VAT change',
    NEW_VAT: 'New VAT',
    MAXIMUM_PROFIT_COMMISSION_PERCENTAGE: "Maximum profit / commission percentage",
    COMMISSION_LEVEL: "Commission level",
    POINTS: "Points",
    MAXIMUM_AMOUNT_ALLOWED: "Maximum amount allowed",
    CREDIT_PAYMENT_LIMIT: "Credit payment limit",
    TAG_MANAGEMENT: 'Tag management',
    TAG_TYPE: 'Tag type',
    TAG_NAME: 'Tag name',
    FILTER_NAME: 'Filter name',
    APPEARS_ON_PRODUCT: 'Appears on the product',
    DELETION: 'Deletion',
    CREATE_NEW_TYPE: 'Create a new type',
    CREATE_NEW_TAG: 'Create a new tag',
    CREATE_EDIT_CATEGORY: 'Establish or change a category',
    CATEGORY_NAME: 'Category name',
    BACKGROUND_COLOR_PICKER: 'Background color category',
    CATEGORY_TEXT_COLOR: 'Category text color',
    CREATE_CHANGE_TAG: 'Create or change a tag',
    EXTRA_CONVERTIBLE_PENNIES: 'Extra convertible pennies',
    SUPPLIER_NAME: 'Name of supplier',
    SUPPLIER_TYPE: 'Supplier type',
    ANOTHER_PHONE: 'Another phone',
    SUPPLIER_LOGO: 'Supplier logo',
    FONT_COLOR: 'Font color',
    LOGO_BACKGROUND: 'Logo background',
    PRODUCT_CARD_BACKGROUND: 'Product card background',
    SELECTED_PRODUCT_CARD_BG: 'Selected product card background',
    OPERATING_PRODUCT_FONT: 'Operating product font',
    BACKGROUND_PRODUCT_CARD_SALE: 'Background Product Card Sale',
    MAX_MARKET_PROFIT_COMMISSION: 'Maximum marketer profit / commission%',
    MIN_ADDITIONAL_COMMISSION_CONSUMER: 'Minimum additional commission for the consumer',
    MAX_AMOUNT_ALLOWED_PAYMENT: 'Maximum amount allowed for payment',
    LIMIT_AUTO_PAYMENT_CREDIT: 'Limit automatic payment on credit',
    CONSUMER_COMMISSION_STAIRS: 'Consumer commission stairs',
    CREDIT_CARD_PROVIDE: 'Credit card to provide',
    HOW_TO_CANCEL: 'How to cancel',
    EMAIL_TO_CANCEL: 'Email to cancel',
    DARK: 'Dark',
    BRIGHT: 'Bright',
    SELECT_PROFILE: 'Select a profile',
    NEW_PROFILE: 'New profile',
    EDIT_PROFILE: 'Edit profile',
    APPROVED_FOR_USE: 'Approved for use for',
    DIRECTOR: 'Director',
    TV: 'TV',
    USING_DISTRIBUTION_FEE: 'Using a distribution fee',
    AS_DEFAULT_PROFILE: 'As a default profile',
    TYPE_OF_PRODUCT: 'Type of product',
    APPLY_PROFIT_ON_ALL_PRODUCTS: 'Apply % profit on all products',
    APPLY_GIFT_POINTS_TO_ALL_PRODUCTS: 'Apply gift points to all products',
    CARD_TYPE: 'Card Type',
    PERCENTAGE_PROFIT: 'Percentage Profit',
    A_DISTRIBUTION: 'A. Distribution',
    ENABLED: 'Enabled',
    PROFIT_COMMISSION_TO_MARKETER: 'Profit commission to the marketer',
    EXTRA_CHARGE_TO_CONSUMER: 'Extra charge to the consumer',
    APPLY_PROFIT_COMMISSION_TO_PRODUCTS: 'Apply a profit commission on all products',
    APPLY_ADDITIONAL_PRICE_TO_CONSUMER: 'Apply an additional price to the consumer',
    ADDITIONAL_TO_CONSUMER: 'Addition to the consumer',
    COMMISSIONERS_COMMISSION: `Commissioner's commission`,
    TOURISM_PROFILE: 'Tourism profile',
    MIN_FRONTAL_REPRESENTATIVE_FEE: 'Minimum frontal representative fee',
    SKUS: 'SKUs',
    NAME_OF_SKU: 'Name of SKU',
    REQUIRED: 'Required',
    SELECT_SKU: 'Select SKU',
    OFF: 'Off',
    OPTIONAL: 'Optional',
    ENTER_NEW_PART_NUMBER_THERE: 'Enter a new part number there',
    MANUAL_TICKETS: 'Manual tickets',
    ACTIVE_CARDS: 'Active cards',
    TICKETS_USED: 'Tickets used',
    TICKETS_ABOUT_EXPIRE: 'Tickets that are about to expire',
    EXPIRED_UNUSED_CARDS: 'Expired and unused cards',
    DATE_OF_INCOME: 'Date of income',
    INSERTED_BY: 'Inserted by',
    DATE_TIME_OF_INCOME: 'Date and time of income',
    EXPIRY_DATE: 'Expiry Date',
    USED: 'Used',
    CARD_WAS_CREATED_SUCCESSFULLY: 'The card was created successfully',
    GIFTS: 'Gifts',
    GIFT_CATEGORY: 'Gift category',
    GIFT_LIST: 'Gift list',
    NAME_OF_GIFT: 'Name of gift',
    POINTS_TO_BE_UTILIZED: 'Points to be utilized',
    PRODUCT_IMAGE: 'Product image',
    DETAIL_ON_WAITING: 'Details on waiting',
    ACTIVE_GIFT: 'Active gift',
    INVOICE_MANAGEMENT: 'Invoice management',
    AUTHORIZED_PROVIDER_TO_PROVIDER: 'Authorizes provider to provider',
    PROFIT_COMMISSIONS: 'Profit commissions',
    NEW_MANUAL_TICKETS: 'New manual tickets',
    GENERAL_SETTINGS: 'General Settings',
    MILES: 'Miles',
    BASE: 'Base',
    TEMPORARY_ADDITION: 'Temporary addition',
    DATE_TEMPORARY_ADDITION: 'Date for temporary addition',
    VACATION: 'Vacation',
    ORGANIZED: 'Organized',
    CRUZ: 'Cruz',
    ROLE: 'Role',
    SECURITY_DEPOSIT: 'security deposit',
    THERE_IS: 'there is',
    TOURISM_PROVIDER: 'Tourism provider',
    PACKAGE_REFRESH_TIME_DAYS_AHEAD: 'Package refresh time {num} day ahead',
    REFRESH_TIME_FORWARD_EACH_DAY: 'Refresh time {num} day forward each day',
    AT: 'At',
    DAYS: 'Days',
    EXCEPT_FOR_REFRESH_DAY: 'Except on refresh day {NUM} day',
    SETTING: 'Setting',
    API_SETTING: 'API Setting',
    APPROVED_SUPPLIERS: 'Approved suppliers',
    PAYMENT_FEES: 'Payment fees',
    USERS: 'Users',
    NUMBER: 'Number',
    USERNAME: 'Username',
    CELLPHONE_NUMBER: 'Cellphone number',
    OWNER: 'Owner',
    ADD_USER: 'Add user',
    NEW_USER: 'New user',
    PERMISSIONS: 'Permissions',
    SEVERAL_SECURE_DEVICES: 'Several secure devices',
    IP_NUMBER_ADDRESSES: 'IP Number of addresses',
    IP_ADDRESS: 'IP address',
    FRIENDLY_NAME: 'Friendly name',
    ACTIONS: 'Actions',
    PASSWORD_RESET: 'Password reset',
    CHANGE_PASSWORD: 'Change Password',
    UNDO_FREEZE: 'Undo Freeze',
    DESTINATION_DESCRIPTION: 'Destination Description',
    HELP: 'Help',
    FONT_LOGO_SUPPLIER: 'Font logo supplier',
    PRODUCT_FONT: 'Product font',
    SELECTED_PREFERRED_PRODUCT_FONT: 'Selected / preferred product font',
    DOUBT_BACKGROUND: 'Doubt background',
    PRODUCT_BACKGROUND: 'Product background',
    SELECTED_PREFERRED_PRODUCT_BACKGROUND: 'Selected / preferred product background',
    BRIGHT_LOGO_FONT: 'Bright logo font',
    BRIGHT_LOGO_BACKGROUND: 'Bright logo background',
    STAR_COLOR_FAVORITES: 'Star color favorites',
    SIDE_LOGO_BACKGROUND: 'Side logo background',
    TERMS_OF_CALLS: 'Terms of calls',
    MANUAL_PRICE: 'Manual Price',
    ALTERNATIVE_PRODUCT_RECOMMENDED: 'Alternative Product (recommended)',
    PRODUCT_TYPE: 'Product Type',
    VIRTUAL: 'Virtual',
    MANUAL: 'Manual',
    BILLS_PAYMENT: 'Payment of bills',
    SUCURE_IP: 'Secure IP',
    CHANGE_USER_PASSWORD_TXT: 'Change to a new user password {UNAME} for business {BNAME}',
    ENTER_NEW_PASSWORD: 'Enter a new password',
    PASSWORD_AUTHENTICATION: 'Password Authentication',
    CHANGE_PASSWORD_DESC: 'At the end of the change, the new password will be sent to the user in the message, to the number {MNUM} and the business owner will be updated in the message about the change in the password, to the number {MNUM}',
    RESET_PASSWORD_DESC: 'This action will generate a new password to {UNAME} for business {BNAME}. The new pass will be send by sms to this number  {MNUM}. The business owner will be notify for password change on this number {OMNUM}.',
    LOGIN_HISTORY: 'Login History',
    PREPAID_CANCELLATION: 'Prepaid Cancellation',
    SYNC_NOW: "Sync now",
    SYNC_STARTED: "Sync started",
    SHANIV_CUSTOMER_NUMBER: 'Shaniv customer number',
    SASA_CUSTOMER_NUMBER: 'Sasa customer number',
    NEW_BUSINESS: 'New business',
    PRODUCTS: "Products",
    PROFILE_NAME: "Profile Name",
    PRODUCT_ACTION_WARNING_TXT: "This action will lead to changes in all products, including those that not shown after filtration! Are you sure you want to continue?",
    PERMISSIONS_PROFILE: "Permissions profile",
    CAN_ASSIGNED: "Can assigned",
    IMPORT_PERMISSIONS: "Import permissions",
    PERMITTED_TO: "Permitted to",
    ROLE_NAME: "Role name",
    GIFTS_NEW: "Gifts - New",
    AS_SHANIV_DEFAULT_PROFILE: "As shaniv default",
    ADD_VAT: 'Add Vat',
    PRODUCTS_SOURCE: 'Products source',
    POINTS_USER: 'Points User',
    LOCK: 'Lock',
    CANCELLATION_FREEZE: 'Cancellation Freeze',
    UNLOCK: 'Unlock',
    LINUX: 'Linux',
    WINDOWS: 'Windows',
    
  },
  CANCELLATION: {
    CANCELLATION_SYSTEM: "Cancellation System",
    PREPAID_CANCELLATION: "Prepaid Cancellation",
    CANCELLATION_DETAILS: 'Cancellation details',
    HOWTO_REQUEST_CANCELLATION: 'How to request cancellation',
    DATE_CANCELLATION1: 'Date and time of cancellation',
    DATE_CANCELLATION2: 'Date and time of cancellation',
    CANCELLATION_OPERATION: 'Cancellation operation',
    SUPPLIER_TRANSACTION_NUMBER: 'Supplier transaction number',
    VENDOR_CONFIRMATION_NUMBER: 'Vendor confirmation number',
    CANCELLATION_TXT: 'Pay attention!! This cancellation will only be made on Platok and not with the supplier *',
    WHICH_CANCELLATION: 'Which cancellation',
    PAYMENTS_CANCELLATION: 'Payments Cancellation',
    CANCELLATION: 'Cancellation',
    TRANSACTION_NUMBER: 'Transaction number',
    BUSINESS_NUMBER: 'Business number',
    CANCELLATION_AMOUNT: 'Cancelation amount',
    PROVIDER_APPROVAL_NUNBER: 'Provider Approval number',
    TRANSACTION: 'Transaction',
    BUSINESS_ID: 'Business ID',
    PROVIDER: 'Provider',
    CHARGING_TIME: 'Charging time',
    CELLULAR_NUMBER: 'Cellular number',
    CANCEL_CONFIRMATION_NUMBER: 'Cancel confirmation number',
    PARTIAL_CANCEL: 'Partial cancel',
    SALE_TRANSACTION_PRICE: 'Sale Transaction Price',
    CANCELLATION_NOTE: 'Cancellation Note',
    CANCELED_PRICE: 'Canceled Price',
    CANCELATION_SUCCESSFUL: 'Cancelation successful',
    PARTIAL_CANCELATION_OF_NUM: 'Partial Cancelation of {NUM}',
    CAN_NOT_BE_CANCELED: 'Can not be Canceled',
    RAVKAV_CARD: 'Ravkav card',
    RAVKAV_TRANSACTTION: 'Ravkav Transaction',
    CANCEL_VIA_PROVIDER: 'Cancel via provider',
    CANCEL_LOCALY: 'Cancel localy',


  },
  COMPONENT: {
    SELECT_NUMBER_NIGHT_RANGE: 'Select the number of nights in the range',
    SELECT_DATES: 'Select dates',
    EXACTLY_SELECTED_DATES: 'Exactly selected dates',
    STAY: 'Stay',
    NIGHTS: 'nights',
    THIS_WEEKEND: 'This Weekend',
    NEXT_WEEKEND: 'Next Weekend',
    THIS_WEEK: 'This week',
    NEXT_WEEK: 'Next week',
    THIS_MONTH: 'This month',
    NEXT_MONTH: 'Next month',
    CUSTOM: 'Custom'

  },
  CART: {
    CREDIT_LINE_FOR_UTILIZATION: 'Credit line for utilization',
    MINIMUM_AMOUNT_PER_ORDER: 'Minimum amount per order is {num}',
    MINIMUM_AMOUNT_PER_ORDER_PER_COMPANY: 'Minimum amount per order is {num} ({company})',
    ORDER_SUMMARY: 'Order summary',
    ORDER_VALUE: 'Order value',
    SAVINGS: 'Savings',
    ITEMS_IN_SHOPPING_CART: 'Items in the shopping cart',
    CLEARANCE_OF_INVENTORY: 'Clearance of inventory',
    REMOVE_ALL_ITEMS: 'Remove all items',
    REPURCHASE: 'Repurchase',
    OPEN_INVITATIONS: 'Open invitations',
    PREVIOUS_ORDER: 'Previous Orders',
    SHOPPING_CART: 'Shopping cart',
    INCLUDING_VAT: 'Including VAT',
    PACKAGE_QUANTITY: 'Package Quantity',
    FULL_PRICE: 'Full price',
    DAY_ORDER_ARRIVES:'The day the order arrives',
    SAVE_COMPLETE_ORDER_LATER: 'Save and complete an order later',
    ORDER_HISTORY: 'Order History',
    ORDER_DATE: 'Order Date',
    DELIVERY_DATE: 'Delivery date',
    QUANTITY_OF_ITEMS_ORDERED: 'Quantity of items ordered',
    ORDER_AMOUNT: 'Order amount',
    QUANTITY_OF_ITEMS_PROVIDED: 'Quantity of items provided',
    FINAL_AMOUNT: 'Final amount',
    BOOK_AGAIN: 'Book again',
    DETAILS: 'Details',
    MOVE_TO_CART: 'Move to cart',
    REMOVE: 'Remove',
    ORDER_NUMBER: 'Order number',
    DONE_SUCCESSFULLY: 'Done successfully',
    AMOUNT_OF_ITEMS: 'Amount of items',
    MOVE_CART_WARNING_TXT: 'Current set of cart items will be lost. Save your current cart as a temp order?',
    ASIDE_SHOPPING_CART: 'Shopping Cart',
    SOME_PRODUCTS_NOT_ORDERED: 'Please note some products have not been ordered!',
    SOME_PRODUCTS_OUT_OF_STOCK: 'Please note some products are out of stock!',
    YOU_ARE_NOT_A_CUSTOMER_OF_COMPANY: 'The order cannot be placed - you are not a customer of {company}. Please contact the office for more details - * 5409',
    SEARCH_A_PRODUCT: 'Search a product',
    ITEMS_IN_YOUR_SHOPPING_CART: '{NUM} items in your shopping cart',
    REMOVE_ALL: 'Remove all',
    EXPAND_ALL: 'Expand all',
    SAVE_FOR_LATER: 'Save for later',
    DEAL_DETAILS: 'Deal details',
    OUT_OF_STOCK: 'out of stock',
    SELECT_CREDIT_CARD: 'Select Credit-Card',
    PAYMENT_DETAIL: 'Payment Detail',
    CARDHOLDER_NAME: 'Cardholder Name',
    CARDHOLDER_ID: 'Cardholder ID',
    CARD_NUMBER: 'Card Number',
    EXPIRATION: 'Expiration',
    CVV: 'CVV',
    SAVE_CREDIT_CARD_INFORMATION: 'Save Credit-Card information',
    CHECKOUT: 'Checkout',
    NEW_CREDIT_CARD: 'New Credit-Card',
    PAYMENT_TO_TXT: 'To complete the order, you must complete the open payment for this order',
    PRESS_TO_PAYMENT: 'Press here transfer to payment page',


  },
  SHANIV_ORDER_STATUS: {
    created_on_our_side: 'Created on our side',
    sent_to_priority: 'Sent to priority',
    created_on_priority_side: 'Created on priority side',
    packed_in_transit: 'Packed / in transit',
    delivered: 'Delivered',
    error: 'Error',
    partly: 'partly'
  },
  REPORTS: {
    OBLIGATION_REPORT: 'Obligation report',
    AGENT_TAX: 'Agent tax',
    MARKETER_DISTRIBUTOR: 'Marketer / Distributor',
    COLLECTION_DAY: 'Collection day',
    MISSING_DOCUMENTS: 'Missing documents',

  },
  FLIGHTS: {
    PLEASE_USE_ALL_FILTERS: 'Please use all filters',
    TYPE: 'Type',
    TYPE_ROUND: 'Round',
    TYPE_ONEWAY: 'One way',
    CABIN_CLASS: 'Cabin class',
    CABIN_CLASS_ANY: 'Any',
    CABIN_CLASS_ECONOMY: 'Economy',
    CABIN_CLASS_ECONOMY_PREMIUM: 'Economy Premium',
    CABIN_CLASS_BUSINESS: 'Business',
    CABIN_CLASS_FIRST: 'First class',
    MAX_STOPOVERS: 'Max stopovers',
    MAX_STOPOVERS_0: 'Direct only',
    MAX_STOPOVERS_1: '1',
    MAX_STOPOVERS_2: '2',
    PASSENGERS: 'Passengers',
    ADULTS: 'Adults',
    OVER_11: 'Over 11',
    CHILDREN: 'Children',
    AGE_2_11: 'Age 2-11',
    INFANTS: 'Infants',
    UNDER_2: 'Under 2',
    BAGGAGE: 'Baggage',
    HOLD_BAGS: 'Hold bags',
    HAND_BAGS: 'Hand bags',
    FROM: 'From',
    TO: 'To',
    FOR_EXAMPLE: 'For example, {dest_name}',
    LONDON: 'London',
    NEW_YORK: 'New York',
    BERLIN: 'Berlin',
    ROME: 'Rome',
    FLIGHTS: 'Flights',
    VIEW_ALL_FLIGHTS: 'View all',
    FLIGHT_TO: 'Flight to',
    STARTING_FROM: 'Starting from',
    FULL_DETAILS: 'Full details',
    PRICE_SPECIFIC_FLIGHT_SHOWN: 'Price for specific flight',
    DAYS: 'Days',
    PLEASE_SEARCH: 'Do your first search please',
    NO_FLIGHTS_FOUND: 'No flights found',
    TRIP_SUMMARY: 'Trip summary',
    NIGHTS_IN: 'nights in',
    BOOK_FLIGHT: 'Book flight',
    CONNECTION_PROTECTED_BY_CARRIER: 'Connection protected by the carrier',
    BAGS_RECHECK_REQUIRED: 'Bags recheck required',
    LAYOVER: 'Layover',
    ARRIVED_DESTINATION: 'Arrived at destination',
    FREE: 'Free',
    PAID_OPTION: 'Paid option',
    NOT_AVAILABLE: 'Not available',
    PERSONAL_ITEM: 'Personal item',
    CABIN_BAGGAGE: 'Cabin baggage',
    CHECKED_BAGGAGE: 'Checked baggage',
    THEN_DEPART_FROM: 'then depart from',
    BAGGAGE_INFO: 'Baggage info',
    AIRLINES: 'Airlines',
    PRICE: 'Price',
    LIST_ALL: 'List all',
    DEPARTURE_DATE: 'Departure date',
    RETURN_DATE: 'Return date',
    DURATION: 'Duration',
    TRAVELERS: 'Travelers',
    TIP: 'TIP',
    FLIGHT_TIP_TXT: 'Travel safely by adding a checked bag instead. You can take less on board and avoid moving around the cabin.',
    ORDER_DETAILS: 'Order details',
    SELECT_ONE_OPTION: 'Select one option',
    NO_CHECKED_BAGGAGE: 'No checked baggage',
    ORDER: 'Order',
    PRICE_PER_PASSENGER: 'Price per passenger',
    TOTAL_ORDER_PRICE: 'Total order price',
    TOTAL: 'Total',
    PASSENGER: 'Passenger',
    PASSENGER_CATEGORY_ADULTS: 'Adult',
    PASSENGER_CATEGORY_CHILDREN: 'Child',
    PASSENGER_CATEGORY_INFANTS: 'Infant',
    FIRST_NAME: 'Name',
    LAST_NAME: 'Surname',
    NATIONALITY: 'Nationality',
    GENDER: 'Gender',
    GENDER_MALE: 'Male',
    GENDER_FEMALE: 'Female',
    DATE_OF_BIRTH: 'Date of birth',
    CARD_NUMBER: 'Card number',
    CARD_EXPIRATION: 'Card expiration',
    FLIGHT_IS_NOT_AVAILABLE: 'This flight is no longer available!',
    PRICE_CHANGED: 'Price changed!',
    PHONE: 'Phone',
    EMAIL: 'Email',
    ERROR: 'Error',
    PLEASE_CHECK_EMAIL_AND_PHONE: 'Please check email and phone fields',
    EDIT: 'Edit',
    SAVE: 'Save',
    CURRENT_STATUS: 'Current status',
    STATUS_CREATED: 'Created',
    STATUS_CREATED_DESC: 'Waiting for client confirmation',
    STATUS_CONFIRMED_BY_CLIENT: 'Confirmed by client',
    STATUS_CONFIRMED_BY_CLIENT_DESC: 'Client confirmed the order. Waiting for response from the provider',
    STATUS_ORDERED: 'Ordered',
    STATUS_ORDERED_DESC: 'Order created on provider side',
    STATUS_PAID: 'Paid',
    STATUS_PAID_DESC: 'Client paid for the order. Waiting for provider confirmation',
    STATUS_CONFIRMED: 'Confirmed',
    STATUS_CONFIRMED_DESC: 'Confirmed by provider',
    STATUS_COMPLETED: 'Completed',
    STATUS_COMPLETED_DESC: 'Order completed',
    STATUS_CANCELED: 'Canceled',
    STATUS_CANCELED_DESC: 'Order canceled',
    STATUS_FAILED: 'Failed',
    STATUS_FAILED_DESC: 'Order failed',
    BOOK: 'Book',
    BOOK_WITHOUT_CLIENT_CONFIRMATION: 'Book without client confirmation',
    CANCEL: 'Cancel',
    CONFIRM_PAYMENT: 'Confirm payment',
    ARE_YOU_SURE: 'Are you sure?',
    OPEN_ETICKETS: 'Open e-tickets',
    BACK_TO_SEARCH: 'Back to search',
  }
};