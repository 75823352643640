import {flightsService} from '../services';

const state = {
    search_in_process: false,
    searched: false,
    found_flights: [],
    location_options: {},
    filters_in_use: {},

    load_details_in_process: false,
    flight_details: null,

    create_order_in_process: false,
    load_order_details_in_process: false,
    load_order_status_in_process: false,
    order_details: null,
    order_status: null,

    update_passenger_info_in_process: false,
    cancel_flights_order_in_process: false,
    book_flights_order_in_process: false,
    confirm_flights_order_payment_in_process: false,

    statuses_list: {
        '1': 'created',
        '2': 'confirmed_by_client',
        '3': 'ordered',
        '4': 'paid',
        '5': 'confirmed',
        '6': 'completed',
        '7': 'canceled',
        '8': 'failed',
    }
};

const actions = {
    searchFlights({ commit }, {filters}) {
        commit('setSearchInProcessTrue');
        commit('setFiltersInUse', {filters});
        return flightsService.searchFlights(filters)
            .then(
                flights_results => {
                    if (flights_results.data && flights_results.data.length) {
                        commit('setFoundFlights', {flights: flights_results.data});
                    } else {
                        commit('setFoundFlights', {flights: null});
                    }
                    commit('setSearchInProcessFalse');
                    commit('setSearchedTrue');
                },
                error => {
                    commit('setFoundFlights', {flights: null});
                    commit('setSearchInProcessFalse');
                    commit('setSearchedTrue');
                }
            );
    },
    searchLocationOptions({ commit }, {filters}) {
        return flightsService.getLocations(filters)
            .then(
                results => {
                    if (results.data && results.data.length) {
                        commit('setLocationOptions', {options: results.data});
                    }
                    return results;
                },
                error => {
                    return error;
                }
            );
    },
    fetchFlightDetails({ commit }, {
        provider,
        booking_token,
        adults,
        children,
        infants,
        adult_hold_bag,
        adult_hand_bag,
        child_hold_bag,
        child_hand_bag,
    }) {
        commit('setLoadDetailsInProcessTrue');
        return flightsService.getFlightDetails(
            provider,
            booking_token,
            adults,
            children,
            infants,
            adult_hold_bag,
            adult_hand_bag,
            child_hold_bag,
            child_hand_bag,
        )
            .then(
                result => {
                    if (result && result.flight) {
                        commit('setFlightDetails', {data: result.flight});
                    }
                    commit('setLoadDetailsInProcessFalse');
                    return result;
                },
                error => {
                    commit('setLoadDetailsInProcessFalse');
                    return Promise.reject(error);
                }
            );
    },
    updateFlightDetails({ commit }, {price, bagsprice}) {
        commit('updateFlightDetails', {price: price, bagsprice: bagsprice});

        return true;
    },
    createFlightsOrder({ commit }, { provider, booking_token, passengers_data, phone, email, session_id }) {
        commit('setCreateOrderInProcessTrue');
        return flightsService.createFlightsOrder(
            provider,
            booking_token,
            passengers_data,
            phone,
            email,
            session_id
        )
            .then(
                result => {
                    if (result && result.order) {
                        commit('setFlightsOrderDetails', {data: result.order});
                    }
                    commit('setCreateOrderInProcessFalse');
                    return result;
                },
                error => {
                    commit('setCreateOrderInProcessFalse');
                    return Promise.reject(error);
                }
            );
    },
    fetchFlightsOrderDetails({ commit }, {
        id,
        token
    }) {
        commit('setLoadOrderDetailsInProcessTrue');
        return flightsService.getFlightsOrderDetails(
            id,
            token,
        )
            .then(
                result => {
                    if (result && result.order) {
                        commit('setFlightsOrderDetails', {data: result.order});
                    }
                    commit('setLoadOrderDetailsInProcessFalse');
                    return result;
                },
                error => {
                    commit('setLoadOrderDetailsInProcessFalse');
                    return Promise.reject(error);
                }
            );
    },
    fetchFlightsOrderStatus({ commit }, {
        id,
        token
    }) {
        commit('setLoadOrderStatusInProcessTrue');
        return flightsService.getFlightsOrderStatus(
            id,
            token,
        )
            .then(
                result => {
                    if (result && result.order && 'status' in result.order) {
                        commit('setFlightsOrderStatus', {status: result.order.status});
                    }
                    commit('setLoadOrderStatusInProcessFalse');
                    return result;
                },
                error => {
                    commit('setLoadOrderStatusInProcessFalse');
                    return Promise.reject(error);
                }
            );
    },
    updateFlightsOrderDetails({ commit }, {price, bagsprice}) {
        commit('updateFlightsOrderDetails', {price: price, bagsprice: bagsprice});

        return true;
    },
    updatePassengerInfo({ commit }, { id, order_id, token, passenger_info }) {
        commit('setUpdatePassengerInfoInProcessTrue');
        return flightsService.updatePassengerInfo(
            id,
            order_id,
            token,
            passenger_info
        )
            .then(
                result => {
                    if (result ) {}
                    commit('setUpdatePassengerInfoInProcessFalse');
                    return result;
                },
                error => {
                    commit('setUpdatePassengerInfoInProcessFalse');
                    return Promise.reject(error);
                }
            );
    },
    cancelFlightsOrder({ commit }, { id, token, type, message }) {
        commit('setCancelFlightsOrderInProcessTrue');
        return flightsService.cancelFlightsOrder(
            id,
            token,
            type,
            message
        )
            .then(
                result => {
                    if (result && result.success && result.order) {
                        commit('setFlightsOrderDetails', {data: result.order});
                    }
                    commit('setCancelFlightsOrderInProcessFalse');
                    return result;
                },
                error => {
                    commit('setCancelFlightsOrderInProcessFalse');
                    return Promise.reject(error);
                }
            );
    },
    initiateFlightsOrderPriceUpdate({ commit }, { id, token }) {
        return flightsService.initiateFlightsOrderPriceUpdate(
            id,
            token,
        )
            .then(
                result => {
                    if (result ) {}
                    return result;
                },
                error => {
                    return Promise.reject(error);
                }
            );
    },
    bookFlightsOrder({ commit }, { id, token }) {
        commit('setBookFlightsOrderInProcessTrue');
        return flightsService.bookFlightsOrder(
            id,
            token,
        )
            .then(
                result => {
                    if (result && result.success && result.order) {
                        commit('setFlightsOrderDetails', {data: result.order});
                    }
                    commit('setBookFlightsOrderInProcessFalse');
                    return result;
                },
                error => {
                    commit('setBookFlightsOrderInProcessFalse');
                    return Promise.reject(error);
                }
            );
    },
    confirmFlightsOrderPayment({ commit }, { id, token }) {
        commit('setConfirmFlightsOrderPaymentInProcessTrue');
        return flightsService.confirmFlightsOrderPayment(
            id,
            token,
        )
            .then(
                result => {
                    if (result && result.success && result.order) {
                        commit('setFlightsOrderDetails', {data: result.order});
                    }
                    commit('setConfirmFlightsOrderPaymentInProcessFalse');
                    return result;
                },
                error => {
                    commit('setConfirmFlightsOrderPaymentInProcessFalse');
                    return Promise.reject(error);
                }
            );
    },
    clearFlightDetailsState({ commit }) {
        commit('setFlightDetails', {data: null});
    },
};

const mutations = {
    setSearchInProcessTrue(state) {
        state.search_in_process = true;
    },
    setSearchInProcessFalse(state) {
        state.search_in_process = false;
    },
    setLoadDetailsInProcessTrue(state) {
        state.load_details_in_process = true;
    },
    setLoadDetailsInProcessFalse(state) {
        state.load_details_in_process = false;
    },
    setCreateOrderInProcessTrue(state) {
        state.create_order_in_process = true;
    },
    setCreateOrderInProcessFalse(state) {
        state.create_order_in_process = false;
    },
    setLoadOrderDetailsInProcessTrue(state) {
        state.load_order_details_in_process = true;
    },
    setLoadOrderDetailsInProcessFalse(state) {
        state.load_order_details_in_process = false;
    },
    setLoadOrderStatusInProcessTrue(state) {
        state.load_order_status_in_process = true;
    },
    setLoadOrderStatusInProcessFalse(state) {
        state.load_order_status_in_process = false;
    },
    setUpdatePassengerInfoInProcessTrue(state) {
        state.update_passenger_info_in_process = true;
    },
    setUpdatePassengerInfoInProcessFalse(state) {
        state.update_passenger_info_in_process = false;
    },
    setCancelFlightsOrderInProcessTrue(state) {
        state.cancel_flights_order_in_process = true;
    },
    setCancelFlightsOrderInProcessFalse(state) {
        state.cancel_flights_order_in_process = false;
    },
    setBookFlightsOrderInProcessTrue(state) {
        state.book_flights_order_in_process = true;
    },
    setBookFlightsOrderInProcessFalse(state) {
        state.book_flights_order_in_process = false;
    },
    setConfirmFlightsOrderPaymentInProcessTrue(state) {
        state.confirm_flights_order_payment_in_process = true;
    },
    setConfirmFlightsOrderPaymentInProcessFalse(state) {
        state.confirm_flights_order_payment_in_process = false;
    },
    setSearchedTrue(state) {
        state.searched = true;
    },
    setSearchedFalse(state) {
        state.searched = false;
    },
    setFoundFlights(state, {flights}) {
        if (flights && Array.isArray(flights) && flights.length) {
            for (let i = 0; i < flights.length; i++) {
                flights[i].to_routes = flights[i].routes.filter(r => !r.return_flight).sort((a, b) => {return moment(a.departure_date_utc).isBefore(b.departure_date_utc) ? -1 : 1});
                flights[i].return_routes = flights[i].routes.filter(r => r.return_flight).sort((a, b) => {return moment(a.departure_date_utc).isBefore(b.departure_date_utc) ? -1 : 1});
            }
            state.found_flights = JSON.parse(JSON.stringify(flights));
        } else {
            state.found_flights = [];
        }
    },
    setFiltersInUse(state, {filters}) {
        state.filters_in_use = JSON.parse(JSON.stringify(filters));
    },
    setLocationOptions(state, {options}) {
        if (Array.isArray(options)) {
            for (let i = 0; i < options.length; i++) {
                state.location_options['c:' + options[i].code] = {
                    code: options[i].code,
                    name: options[i].name,
                    country_name: options[i].country.name,
                    id: options[i].id
                };

                if ('airports' in options[i] && Array.isArray(options[i].airports)) {
                    for (let j = 0; j < options[i].airports.length; j++) {
                        state.location_options['a:' + options[i].airports[j].code] = {
                            code: options[i].airports[j].code,
                            name: options[i].airports[j].name,
                            country_name: options[i].country.name,
                            id: options[i].airports[j].id
                        };
                    }
                }
            }
        }
    },
    setFlightDetails(state, {data}) {
        let flight_details = data;

        if (flight_details) {
            flight_details.to_routes = flight_details.routes.filter(r => !r.return_flight).sort((a, b) => {
                return moment(a.departure_date_utc).isBefore(b.departure_date_utc) ? -1 : 1;
            });
            flight_details.return_routes = flight_details.routes.filter(r => r.return_flight).sort((a, b) => {
                return moment(a.departure_date_utc).isBefore(b.departure_date_utc) ? -1 : 1;
            });
        }

        state.flight_details = JSON.parse(JSON.stringify(flight_details));
    },
    setFlightsOrderDetails(state, {data}) {
        let order_details = data;

        if (order_details && order_details.flight && order_details.flight.routes) {
            order_details.flight.to_routes = order_details.flight.routes.filter(r => !r.return_flight).sort((a, b) => {
                return moment(a.departure_date_utc).isBefore(b.departure_date_utc) ? -1 : 1;
            });
            order_details.flight.return_routes = order_details.flight.routes.filter(r => r.return_flight).sort((a, b) => {
                return moment(a.departure_date_utc).isBefore(b.departure_date_utc) ? -1 : 1;
            });
        }

        state.order_details = JSON.parse(JSON.stringify(order_details));
        state.order_status = order_details.status;
    },
    setFlightsOrderStatus(state, {status}) {
        state.order_status = status;
        // state.order_details.status = status;
    },
    updateFlightDetails(state, {price, bagsprice}) {
        let flight_details = state.flight_details;

        if (flight_details && Object.keys(flight_details).length) {
            if (price && Object.keys(price).length) {
                flight_details.price = price;
            }
            if (bagsprice && Object.keys(bagsprice).length) {
                flight_details.bagsprice = bagsprice;
            }
        }

        state.flight_details = JSON.parse(JSON.stringify(flight_details));
    },
    updateFlightsOrderDetails(state, {price, bagsprice}) {
        let flights_order_details = state.order_details;

        if (
            flights_order_details
            && Object.keys(flights_order_details).length
            && flights_order_details.flight
            && Object.keys(flights_order_details.flight).length
        ) {
            if (price && Object.keys(price).length) {
                flights_order_details.flight.price = price;
            }
            if (bagsprice && Object.keys(bagsprice).length) {
                flights_order_details.flight.bagsprice = bagsprice;
            }
        }

        state.order_details = JSON.parse(JSON.stringify(flights_order_details));
    },
};

const getters = {
    flights: (state) => state.found_flights,
    filters_in_use: (state) => state.filters_in_use,
    filter_in_use: (state) => {
        return function(filter) {
            return filter in state.filters_in_use
                ? state.filters_in_use[filter] : null;
        }
    },
    flights_per_destination: (state, getters) => {
        let flights_per_destination = {};
        let destinations = getters.filter_in_use('to').split(',');

        if (destinations && destinations.length) {
            for (let i = 0; i < destinations.length; i++) {
                let destination_parts = destinations[i].split(':');
                let destination_type = destination_parts[0];
                let destination_code = destination_parts[1];
                let field_name = destination_type === 'c' ? 'city_code' : 'airport_code';
                flights_per_destination[destinations[i]] = state.found_flights.filter(ff => ff.to[field_name] === destination_code);
            }
        }

        // if (state.found_flights && state.found_flights.length) {
        //     flights_per_destination = state.found_flights.reduce(function(accum, current) {
        //         (accum[current.to.city_code] = accum[current.to.city_code] || []).push(current);
        //         return accum;
        //     }, {});
        // }

        return flights_per_destination;
    },
    single_cheapest_flights_per_destination: (state, getters) => {
        let single_cheapest_flights_per_destination = JSON.parse(JSON.stringify(getters.flights_per_destination));

        for (let ind in single_cheapest_flights_per_destination) {
            single_cheapest_flights_per_destination[ind] = single_cheapest_flights_per_destination[ind] && single_cheapest_flights_per_destination[ind].length ?
                single_cheapest_flights_per_destination[ind].reduce(
                    (min, p) => p.price.price < min.price.price ? p : min, single_cheapest_flights_per_destination[ind][0]
                ) : single_cheapest_flights_per_destination[ind];
        }

        return single_cheapest_flights_per_destination;
    },
    destinations_flights: (state) => {
        return function(destinations) {
            if (!Array.isArray(destinations)) {
                destinations = destinations.split(',');
            }
            let destinations_flights = [];
            if (state.found_flights && state.found_flights.length) {
                destinations_flights = state.found_flights.filter(ff => {
                    let flight_to_use = false;
                    for (let i = 0; i < destinations.length; i++) {
                        let destination = destinations[i];
                        let destination_parts = destination.split(':');
                        let destination_type = destination_parts[0];
                        let destination_code = destination_parts[1];

                        if (destination_type === 'c' && ff.to.city_code === destination_code
                            || destination_type === 'a' && ff.to.airport_code === destination_code) {
                            flight_to_use = true;
                            break;
                        }
                    }

                    return flight_to_use;
                });
            }

            return destinations_flights;
        }
    },
    location_option: (state) => {
        return function (code) {
            return state.location_options[code] || null;
        }
    },
    flight_details: (state) => {
        return function (
            provider,
            booking_token,
            adults,
            children,
            infants,
            adult_hold_bag,
            adult_hand_bag,
            child_hold_bag,
            child_hand_bag
        ) {
            let flightDetails = null;
            if (state.flight_details) {
                flightDetails = state.flight_details;
            } else if (state.found_flights) {
                flightDetails = state.found_flights.find(ff => {
                    return ff.provider === provider
                        && ff.booking_token === booking_token
                        && ff.adults == adults
                        && ff.children == children
                        && ff.infants == infants
                });
            }

            return flightDetails;
        }
    },
    passengers_route_param: (state) => {
        return state.filters_in_use.adults
            + '-' + state.filters_in_use.children
            + '-' + state.filters_in_use.infants;
    },
    baggage_route_param: (state) => {
        let adult_hand_bag = state.filters_in_use.hand_bags <= state.filters_in_use.adults
            ? state.filters_in_use.hand_bags : state.filters_in_use.adults;
        let child_hand_bag = state.filters_in_use.hand_bags > state.filters_in_use.adults
            ? state.filters_in_use.hand_bags - state.filters_in_use.adults : 0;

        let adult_hold_bag = 0;
        let child_hold_bag = 0;
        if (state.filters_in_use.hold_bags <= state.filters_in_use.adults + state.filters_in_use.children) {
            if (state.filters_in_use.hold_bags > state.filters_in_use.adults) {
                adult_hold_bag = state.filters_in_use.adults;
                child_hold_bag = state.filters_in_use.hold_bags - state.filters_in_use.adults;
            } else {
                adult_hold_bag = state.filters_in_use.hold_bags;
                child_hold_bag = 0;
            }
        } else if (state.filters_in_use.hold_bags <= state.filters_in_use.adults * 2 + state.filters_in_use.children) {
            child_hold_bag = state.filters_in_use.children;
            adult_hold_bag = state.filters_in_use.hold_bags - state.filters_in_use.children;
        } else {
            adult_hold_bag = state.filters_in_use.adults * 2;
            child_hold_bag = state.filters_in_use.hold_bags - state.filters_in_use.adults * 2;
        }

        return adult_hold_bag
            + '-' + adult_hand_bag
            + '-' + child_hold_bag
            + '-' + child_hand_bag;
    },
    flights_order_details: (state) => {
        // todo: maybe some logic here
        return state.order_details;
    },
    flights_order_status: (state) => {
        // todo: maybe some logic here
        return state.order_status;
    },
    get_status_num: (state) => {return function(status_name) {
        let status_num = null;
        for (let num in state.statuses_list) {
            if (state.statuses_list[num] === status_name) {
                status_num = +num;
                break;
            }
        }
        return status_num;
    }},
    get_status_name: (state) => {return function(status_num) {
        return state.statuses_list[status_num];
    }},
};

export const flights = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};