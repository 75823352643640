import { productService } from '../services';

const state = {
    products: [],
    product_tags: [],
    product_homepage_tag_id: null,
    product_module_loading: false,
    product_type: []
}
const actions = {
    getProduct({ commit }, {product_id}) {
        commit('setLoadingTrue');
        productService.getProduct(product_id)
            .then(
                product_data => {
                    commit('setLoadingFalse');
                    if (product_data && product_data.hasOwnProperty('id') && product_data.id) {
                        commit('setProduct', {product_id: product_id, product_data: product_data})
                    }
                },
                error => {
                    commit('setLoadingFalse');
                }
            );
    },
    getProducts({ commit }, {params}) {
        commit('setLoadingTrue');
        productService.getSupplierProducts(null, params)
            .then(
                items => {
                    commit('setLoadingFalse');
                    if (items) {
                        for (let product_type in items) {
                            if (items[product_type]) {
                                commit('setProducts', {items: items[product_type]})
                            }
                        }
                    } else {
                        commit('setProducts', {items: []})
                    }
                },
                error => {
                    commit('setLoadingFalse');
                    commit('setProducts', {items: []});
                } 
                
            );
    },
    getProductTags({ commit }) {
        commit('setLoadingTrue');
        productService.getProductTags()
            .then(
                tags => {
                    commit('setLoadingFalse');
                    commit('setProductTags', tags);
                },
                error => {
                    commit('setLoadingFalse');
                    commit('setProductTags', []);
                } 
            );
    },
    getProductType({ commit }) {
        commit('setLoadingTrue');
        return productService.getProductType()
            .then(
                receipt_data => {
                    commit('setLoadingFalse');
                    commit('setProductType', receipt_data);
                },
                error => {
                    commit('setLoadingFalse');
                    commit('setProductTags', []);
                } 
            );
    },
    saveProduct({dispatch, commit }, { params }) {
        commit('setLoadingTrue');
        return productService.saveProduct(params)
            .then(
                receipt_data => {
                    dispatch('alert/success', '', {root: true});
                    commit('setLoadingFalse');
                },
                error => {
                    dispatch('alert/error', error, {root: true});
                    commit('setLoadingFalse');
                } 
            );
    }
};

const mutations = {
    setLoadingTrue(state) {
        state.product_module_loading = true;
    },
    setLoadingFalse(state) {
        state.product_module_loading = false;
    },
    setProduct(state, {product_id, product_data}) {
        let products = state.products || []
        products[product_id] = {...(products[product_id] || {}), ...product_data}
        state.products = null;
        state.products = products;
    },
    setProducts(state, {items}) {
        let products = state.products || []

        if (Array.isArray(items)) {
            for (let product of items) {
                if (!product || !product.hasOwnProperty('id')) {
                    continue
                }
                products[product.id] = product
            }
        }

        state.products = null
        state.products = products
    },
    setProductTags(state, tags) {
        state.product_tags = tags;
        if (Array.isArray(tags)) {
            for (let tag_index in tags) {
                let tagLowerCase = tags[tag_index].name.toLowerCase()
                if (tagLowerCase == 'homepage' || tagLowerCase == 'home page' || tagLowerCase == 'דף הבית') {
                    state.product_homepage_tag_id = tags[tag_index].id;
                }
            }
        }
    },
    setProductType(state, receipt_data) {
        state.product_type = receipt_data;
    }
};

export const product = {
    namespaced: true,
    state,
    actions,
    mutations
};