import { shaniv_managementService } from '../services';
import i18n from '../common/plugins/vue-i18n';
import { product } from './product.module';

const state = {
  package_sale: null,
  isPackage: false,
  products: [],
  product_detail: {},
  subcategory_data: [],
  package_data: {
    active: true,
    end_date: '',
    end_time: '',
    image: '',
    name: '',
    price: '',
    products: [],
    start_date: '',
    start_time: '',
  },
  package_list: [],
  product_package_list: [],
  loading: false,
  maxItemNumber: 0,
  shanivTags: [],
  customers: [],
  getCustomersLoading: false,
  selectedCustomer: null,
  createResellerAndUserLoading: false,
  createResellerAndUserError: null,
  businessForm: {
    //required
    name: null, //suggest but user must make sure
    business_license_number: null, //suggest but user must input
    agent_id: null,
    suppliers_profile_id: null, //should match the currently logged in user or set statically
    profitPercentagesProfile_id: null, //should match the currently logged in user or set statically
    commissionsProfile_id: null, //should match the currently logged in user or set statically
    distributor_id: null, //should be the currently logged in user or set statically
    //optional - will be sent only if inferred successfully
    phone_number: null,
    second_phone_number: null,
    city: null,
    street: null,
    opening_hours: null,
    contact_mail: null,
    contact_account_mail: null,
    tags: null, //example: "[24]"
  },
  userForm: {
    //required
    username: null, //user must input
    password: null, //user must input
    role_id: null,
    // business_id: null, //should be the currently logged in user or set statically
    //optional - will be sent only if inferred successfully
    first_name: null,
    last_name: null,
    phone_number: null,
    account_mail: null,
    city: null,
    street: null,
    israeli_id: null,
    country: null,
  },
  productData: null,
  isManager: false,
  companyList: [],
  maxPackageNumber: 0,
  similarCustomers: []
};

const actions = {
  updateProductsQuantityFromExcel({ commit, dispatch }, { params }){
    commit('setLoadingTrue');
    return shaniv_managementService.updateProductsQuantityFromExcel(params).then(
      (result) => {
        commit('setLoadingFalse');
        if (result) {
          dispatch('alert/success', '', { root: true });
        }
        return result;
      },
      (error) => {
        dispatch('alert/error', '', { root: true });
        commit('setLoadingFalse');
        return error;
      },
    );
  },
  getShanivTags({ commit }) {
    commit('setGetShanivTagsStatus', 'loading');
    return shaniv_managementService.getShanivTags().then(
      (tagsRes) => {
        if (Array.isArray(tagsRes) && tagsRes.length > 0) {
          const mappedTags = tagsRes.map((tag) => ({
            ...tag,
            isActiveCurrProduct: false,
          }));
          commit('setShanivTags', mappedTags);
        }
        commit('setGetShanivTagsStatus', null);
      },
      (error) => {
        commit('setGetShanivTagsStatus', 'error');
      },
    );
  },

  getProducts({ commit }, params) {
    commit('setLoadingTrue');
    return shaniv_managementService.getProducts(params).then(
      (productsRes) => {
        commit('setLoadingFalse');
        commit('setProducts', productsRes);
      },
      (error) => {
        commit('setLoadingFalse');
      },
    );
  },

  updateProductDataInTheList({ commit }, { id }) {
    commit('setLoadingTrue');
    return shaniv_managementService.getProduct(id).then(
      (product_data) => {
        commit('setLoadingFalse');
        if (product_data) {
          commit('updateProductDataInTheList', { product_data: product_data });
        }
      },
      (error) => {
        commit('setLoadingFalse');
      },
    );
  },

  getProductDetail({ commit }, { extpart }) {
    commit('setLoadingTrue');
    return shaniv_managementService.getProductDetail(extpart).then(
      (receipt_data) => {
        commit('setLoadingFalse');
        if (receipt_data) {
          commit('setProductDetail', { receipt_data: receipt_data });
        }
      },
      (error) => {
        commit('setLoadingFalse');
      },
    );
  },

  getSubcategoryData({ commit }) {
    commit('setLoadingTrue');
    return shaniv_managementService.getSubcategoryData().then(
      (receipt_data) => {
        commit('setLoadingFalse');
        if (receipt_data) {
          commit('setSubcategoryData', { receipt_data: receipt_data });
        }
      },
      (error) => {
        commit('setLoadingFalse');
      },
    );
  },

  updateData({ dispatch, commit }, { id, params }) {
    commit('setLoadingTrue');
    return shaniv_managementService.updateData(id, params).then(
      (receipt_data) => {
        commit('setLoadingFalse');
        if (receipt_data) {
          dispatch('alert/success', '', { root: true });
          actions.updateProductDataInTheList({commit}, {id: id});
        }
      },
      (error) => {
        commit('setLoadingFalse');
      },
    );
  },

  getPackageData({ commit }, { id }) {
    commit('setLoadingTrue');
    return shaniv_managementService.getPackageData(id).then(
      (receipt_data) => {
        commit('setLoadingFalse');
        if (receipt_data) {
          commit('setPackageData', { receipt_data: receipt_data });
        }
      },
      (error) => {
        commit('setLoadingFalse');
      },
    );
  },
  getPackageList({ commit }, params) {
    commit('setLoadingTrue');
    return shaniv_managementService.getPackageList(params).then(
      (receipt_data) => {
        commit('setLoadingFalse');
        if (receipt_data.total > 0) {
          let maxItemNumber = 0;
          receipt_data.data.forEach((el) => {
            el.operation_data = el.start_date + ' ' + el.end_date;
            let itemNumber = el.products.length;
            if (itemNumber > maxItemNumber) {
              maxItemNumber = itemNumber;
            }
          });
          commit('setMaxItemNumber', { receipt_data: maxItemNumber });
          commit('setMaxPackageNumber', { receipt_data: receipt_data.total });
          if (params.isForTable) {
            commit('setPackageList', {
              receipt_data: receipt_data.data,
              isForAdd: false
            });
          } else {
            if (params.page > 0) {
              commit('setPackageList', {
                receipt_data: receipt_data.data,
                isForAdd: true
              });
            } else {
              commit('setPackageList', {
                receipt_data: receipt_data.data,
                isForAdd: false
              });
            }
          }
        } else {
          commit('setPackageList', {
            receipt_data: [],
            isForAdd: false
          });
        }
      },
      (error) => {
        commit('setLoadingFalse');
        commit('setPackageList', {
          receipt_data: [],
          isForAdd: false
        });
      },
    );
  },
  updatePackageData({ dispatch, commit }, { id }) {
    commit('setLoadingTrue');
    return shaniv_managementService
      .updatePackageData(id, state.package_data)
      .then(
        (receipt_data) => {
          commit('setLoadingFalse');
          if (receipt_data == 'success') {
            dispatch('alert/success', '', { root: true });
          }
        },
        (error) => {
          commit('setLoadingFalse');
        },
      );
  },
  changePackageState({ commit }, { id, active }) {
    commit('setLoadingTrue');
    return shaniv_managementService.changePackageState(id, active).then(
      (receipt_data) => {
        commit('setLoadingFalse');
        if (receipt_data) {
          console.log(receipt_data);
        }
      },
      (error) => {
        commit('setLoadingFalse');
      },
    );
  },

  getCustomers({ commit }, params) {
    commit('setGetCustomersLoading', true);
    return shaniv_managementService.getCustomers(params).then(
      (customersRes) => {
        commit('setCustomers', customersRes);
        commit('setGetCustomersLoading', false);
      },
      (error) => {
        commit('setGetCustomersLoading', false);
      },
    );
  },

  setSelectedCustomer({ commit }, customer) {
    commit('setSelectedCustomer', customer);

    const inferredShaniv = inferShaniv(customer);
    commit('updateResellerForm', {
      formName: 'businessForm',
      values: inferredShaniv.business,
    });
    commit('updateResellerForm', {
      formName: 'userForm',
      values: inferredShaniv.user,
    });
  },
  clearSelectedUser({ commit }) {
    commit('clearSelectedUser');
    commit('setCreateResellerAndUserError', null);
    commit('clearResellerForms');
  },

  async createResellerAndUser({ commit, state, dispatch }) {
    commit('setCreateResellerAndUserLoading', true);
    commit('setCreateResellerAndUserError', null);

    let businessId = null,
      userId = null;

    //
    //verify username
    try {
      const userExists = await shaniv_managementService.verifyUsername(
        state.userForm.username,
      );
      if (userExists) {
        commit('setCreateResellerAndUserLoading', false);
        commit('setCreateResellerAndUserError', 'username already exists');
        return;
      }
    } catch (error) {
      commit('setCreateResellerAndUserLoading', false);
      commit('setCreateResellerAndUserError', error);
      return;
    }

    //
    //create business (reseller)
    try {
      businessId = await shaniv_managementService.createReseller(
        state.businessForm,
      );
      businessId = typeof businessId === 'object' && businessId !== null && businessId.id
          ? businessId.id : businessId;
    } catch (error) {
      commit('setCreateResellerAndUserLoading', false);
      commit('setCreateResellerAndUserError', error);
      return;
    }

    //
    //create first user of this reseller
    try {
      userId = await shaniv_managementService.createUser({
        ...state.userForm,
        business_id: businessId,
        is_shaniv: true
      });
    } catch (error) {
      commit('setCreateResellerAndUserLoading', false);
      commit('setCreateResellerAndUserError', error);
      return;
    }

    //
    //update shaniv customer information with the created business & user ids
    try {
      return await shaniv_managementService.updateReseller(
        state.selectedCustomer.id,
        businessId,
        userId,
      ).then(
        () => {
          commit('setCreateResellerAndUserLoading', false);
          dispatch('clearSelectedUser');
        }
      );
    } catch (error) {
      commit('setCreateResellerAndUserLoading', false);
      commit('setCreateResellerAndUserError', error);
    }
  },
  async updateResellerAndUser({ commit, state, dispatch }, businessId) {
    commit('setCreateResellerAndUserLoading', true);
    let userId = null;
    if (state.userForm.username && state.userForm.password) {
      try {
        userId = await shaniv_managementService.createUser({
          ...state.userForm,
          business_id: businessId,
        });
      } catch (error) {
        commit('setCreateResellerAndUserLoading', false);
        commit('setCreateResellerAndUserError', error);
        return;
      }
    }
    //
    //update shaniv customer information with the created business & user ids
    try {
      return await shaniv_managementService.updateReseller(
        state.selectedCustomer.id,
        businessId,
        userId,
      ).then(
        () => {
          commit('setCreateResellerAndUserLoading', false);
          dispatch('clearSelectedUser');
        }
      );
    } catch (error) {
      commit('setCreateResellerAndUserLoading', false);
      commit('setCreateResellerAndUserError', error);
    }
  },
  /////
  getPackageIDs({ commit },{ extpart }) {
    commit('setGetShanivTagsStatus', 'loading');
    return shaniv_managementService.getPackageIDs(extpart).then(
      (result) => {
        if( result ){
          commit('setIsPackage', true)
        }
        for (let pack of result) {
          return shaniv_managementService.getPackageDetails(pack.shaniv_package_id).then(
            (details) => {
              commit('getSalePackage', details)
            }
          )
        }
      },
      (error) => {
        commit('setGetShanivTagsStatus', 'error');
      },
    );
  },
  getProductData({ commit }, {extpart, plname}) {
    return shaniv_managementService.getProductData(extpart, plname).then(
      (result) => {
        //console.log(result)
        commit('setProductData', {result: result})
        console.log(state.product_detail)
      },
      (error) => {
        commit('setGetShanivTagsStatus', 'error');
      },
    ); 
  },
  getPackageListById({ commit }, {id}) {
    return shaniv_managementService.getPackageListById(id).then(
      (result) => {
        commit('setProductPackageList', {receipt_data: result})
      },
      (error) => {
        commit('setGetShanivTagsStatus', 'error');
      },
    ); 
  },
  getCompanyList({ commit }) {
    commit('setLoadingTrue');
    return shaniv_managementService.getCompanyList()
      .then(
        receipt_data => {
          commit('setLoadingFalse');
          if (receipt_data) {
            commit('setCompanyList', { receipt_data: receipt_data })
          }
        },
        error => {
          commit('setLoadingFalse');
        }
      );
  },
  getSimilarCustomer({ commit }, id) {
    commit('setLoadingTrue');
    return shaniv_managementService.getSimilarCustomer(id)
      .then(
        receipt_data => {
          commit('setLoadingFalse');
          if (receipt_data) {
            commit('setSimilarCustomerList', { receipt_data: receipt_data })
          }
        },
        error => {
          commit('setLoadingFalse');
        }
      );
  },
  createReseller({ commit }, data) {
    commit('setLoadingTrue');
    return shaniv_managementService.createReseller(data)
      .then(
        receipt_data => {
          commit('setLoadingFalse');
          if (receipt_data) {
            // commit('setSimilarCustomerList', { receipt_data: receipt_data })
            return receipt_data;
          }
        },
        error => {
          commit('setLoadingFalse');
        }
      );
  },
  createUser({ dispatch, commit }, data) {
    commit('setLoadingTrue');
    return shaniv_managementService.createUser(data)
      .then(
        receipt_data => {
          commit('setLoadingFalse');
          if (receipt_data) {
            dispatch('alert/success', '', { root: true });
            console.log("receipt_data", receipt_data)
          }
        },
        error => {
          commit('setLoadingFalse');
        }
      );
  },
  createDistributes({ commit }, data) {
    commit('setLoadingTrue');
    return shaniv_managementService.createDistributes(data)
      .then(
        receipt_data => {
          commit('setLoadingFalse');
          if (receipt_data) {
            return receipt_data;
          }
        },
        error => {
          commit('setLoadingFalse');
        }
      );
  },
  verifyFields({ commit }, data) {
    commit('setLoadingTrue');
    return shaniv_managementService.verifyFields(data)
      .then(
        receipt_data => {
          commit('setLoadingFalse');
          if (receipt_data) {
            // commit('setSimilarCustomerList', { receipt_data: receipt_data })
            return receipt_data;
          }
        },
        error => {
          commit('setLoadingFalse');
        }
      );
  },
};

const mutations = {
  setLoadingTrue(state) {
    state.loading = true;
  },
  setLoadingFalse(state) {
    state.loading = false;
  },
  setProducts(state, productsRes) {
    const mappedProducts = productsRes.data.map((p) => ({
      ...p,
      not_active_since: p.not_active_since || 'active',
    }));
    productsRes.data = mappedProducts;
    state.products = productsRes; //productsRes.products;
    // state.products = productsRes.data;
    
  },
  updateProductDataInTheList(state, { product_data }) {
    let productId = product_data.id;
    let productIndex = null;
    if (!'data' in state.products) {
      state.products['data'] = [];
    }
    let currentProductData = state.products.data.some((item, index) => { productIndex = index; return item.id == productId; });

    if (productIndex >= 0) {
      state.products.data[productIndex] = JSON.parse(JSON.stringify(product_data));
    } else {
      state.products.data.push(product_data);
      if ('total' in state.products) {
        state.products.total++;
      } else {
        state.products.total = 1;
      }
    }
    state.products.data = JSON.parse(JSON.stringify(state.products.data));
  },
  setProductDetail(state, { receipt_data }) {
    state.product_detail = receipt_data;
  },
  setSubcategoryData(state, { receipt_data }) {
    state.subcategory_data = receipt_data;
  },
  setCompanyList(state, { receipt_data }) {
    state.companyList = receipt_data;
  },
  setUpdateTagsStatus(state, status) {
    state.updateTagsStatus = status;
  },
  setGetShanivTagsStatus(state, status) {
    state.getShanivTagsStatus = status;
  },
  setShanivTags(state, tags) {
    state.shanivTags = tags;
  },
  setPackageData(state, { receipt_data }) {
    state.package_data = receipt_data;
  },
  setPackageList(state, { receipt_data, isForAdd }) {
    if (isForAdd) {
      state.package_list = state.package_list.concat(receipt_data);
    } else {
      state.package_list = receipt_data;
    }
  },
  setProductPackageList(state, { receipt_data }) {
    state.product_package_list = receipt_data;
  },
  setMaxItemNumber(state, { receipt_data }) {
    state.maxItemNumber = receipt_data;
  },
  setMaxPackageNumber(state, { receipt_data }) {
    state.maxPackageNumber = receipt_data;
  },
  setSimilarCustomerList(state, { receipt_data }) {
    state.similarCustomers = receipt_data;
  },
  setGetCustomersLoading(state, isLoading) {
    state.getCustomersLoading = isLoading;
  },
  setCustomers(state, customers) {
    state.customers = customers;
  },
  //
  setSelectedCustomer(state, customer) {
    state.selectedCustomer = customer;
  },
  clearSelectedUser(state) {
    state.selectedCustomer = null;
  },
  setCreateResellerAndUserLoading(state, isLoading) {
    state.createResellerAndUserLoading = isLoading;
  },
  setCreateResellerAndUserError(state, error) {
    state.createResellerAndUserError = error;
  },
  updateResellerFormField(state, { formName, formField, newValue }) {
    if (!state.hasOwnProperty(formName)) {
      return;
    }
    if (!state[formName].hasOwnProperty(formField)) {
      return;
    }

    state[formName][formField] = newValue;
  },
  updateResellerForm(state, { formName, values }) {
    if (!state.hasOwnProperty(formName)) {
      return;
    }

    state[formName] = {
      ...state[formName],
      ...values,
    };
  },
  clearResellerForms(state) {
    state.businessForm = {
      //required
      name: null, //suggest but user must make sure
      business_license_number: null, //suggest but user must input
      agent_id: null,
      suppliers_profile_id: null, //should match the currently logged in user or set statically
      profitPercentagesProfile_id: null, //should match the currently logged in user or set statically
      commissionsProfile_id: null, //should match the currently logged in user or set statically
      distributor_id: null, //should be the currently logged in user or set statically
      //optional - will be sent only if inferred successfully
      phone_number: null,
      second_phone_number: null,
      city: null,
      street: null,
      opening_hours: null,
      contact_mail: null,
      contact_account_mail: null,
      tags: null, //example: "[24]"
    };
    state.userForm = {
      //required
      username: null, //user must input
      password: null, //user must input
      role_id: null,
      // business_id: null, //should be the currently logged in user or set statically
      //optional - will be sent only if inferred successfully
      first_name: null,
      last_name: null,
      phone_number: null,
      account_mail: null,
      city: null,
      street: null,
      israeli_id: null,
      country: null,
    };
  },
  getSalePackage(state, details)
  {
    state.package_sale = details;
  },
  setIsPackage(state,show)
  {
    state.isPackage = show
  },
  setProductData(state, { result })
  {
    state.product_detail = result.products[0]
    state.isManager = true
  }
};

const getters = {
  package_list: (state) => state.package_list,
};

export const shaniv_management = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

function inferShaniv(customer) {
  //
  //infer business data
  const business = {
    // agent_id: 36, //should be static
    // profitPercentagesProfile_id: 78,
    // commissionsProfile_id: 152,
    // suppliers_profile_id: 154,
    // tourism_profile_id: 13,
    distributor_id: 1,
    is_shaniv: true
  };

  if (customer.CUSTDES) {
    business.name = customer.CUSTDES;
  }
  if (customer.WTAXNUM) {
    business.business_license_number = customer.WTAXNUM;
  }
  if (customer.PHONE) {
    let formatted = customer.PHONE?.replace?.(/\D/g, '');
    if (formatted?.length === 10) {
      business.phone_number = formatted;
    }
  }
  if (customer.STATE) {
    business.city = customer.STATE;
  }
  if (customer.ADDRESS) {
    business.street = customer.ADDRESS;
  }

  //
  //infer user data
  const user = {
    role_id: 3, //type is "משווק" (reseller)
    // business_id: 73, //shaniv distributor business id
  };

  if (customer.STATE) {
    user.city = customer.STATE;
  }
  if (customer.ADDRESS) {
    user.street = customer.ADDRESS;
  }
  if (customer.PHONE) {
    let formatted = customer.PHONE?.replace?.(/\D/g, '');
    if (formatted?.length === 10) {
      user.phone_number = formatted;
    }
  }

  //
  //
  return {
    business,
    user,
  };
}
