import {dealService} from '../services';
import i18n from '../common/plugins/vue-i18n';
import router from "../router";

const state = {
    loading: false,
    cheapestDealsAllLoading: false,
    isShowTourismDealCarousel: false,
    dealData_receipts: [],
    dealDetailData_receipts: [],
    cheapestDealsData: [],
    cheapestDealsAllData: [],
    specialDealsData: [],
    bookingData_receipts: [],
    bookingResult: [],
    hotelData_receipts: [],
    specificDeals_receipts: [],
    filterData: {},
    sideFilterData: {}
}

const actions = {
    getDeal({ commit }, {filters}) {
        commit('setLoadingTrue');
        return dealService.getDeal(filters)
            .then(
                receipt_data => {
                    commit('setLoadingFalse');
                    if (receipt_data) {
                        commit('setDealData', {receipt_data: receipt_data, page_num: filters.page})
                    }
                },
                error => {
                    commit('setDealData', {receipt_data: "", page_num: 0})
                    commit('setLoadingFalse');
                }
            );
    },
    getDealDetail({ dispatch, commit }, { id, rowId, destinationId, dealType }) {
        commit('setLoadingTrue');
        return dealService.getDealDetail(id, rowId)
            .then(
                receipt_data => {
                    commit('setLoadingFalse');
                    if (receipt_data) {
                        if (receipt_data.originRow && receipt_data.selectedRow
                            && receipt_data.originRow.rowId != receipt_data.selectedRow.rowId) {
                            dispatch('alert/info', i18n.t('TOURISM.DEAL_CHANGED'), {root: true});
                            router.replace({name: receipt_data.selectedRow.type == 'ORGANIZED_TOUR' ? 'TourismOrganized' : 'TourismProduct', params: {id: receipt_data.selectedRow.dealId, rowId: receipt_data.selectedRow.rowId, destinationId: destinationId, dealType: dealType}});
                        }

                        commit('setDealDetailData', {receipt_data: receipt_data});
                    }
                },
                error => {
                    commit('setLoadingFalse');

                    dispatch('alert/info', i18n.t('TOURISM.PLEASE_CHECK_OTHER_DEALS'), {root: true});
                    router.replace({name: 'Tourism', query: {...(destinationId && {id: destinationId}), ...(dealType && {type: dealType})}});
                }
            );
    },
    getHotelData({ commit }, { id }) {
        commit('setLoadingTrue');
        return dealService.getHotelData(id)
            .then(
                receipt_data => {
                    commit('setLoadingFalse');
                    if (receipt_data) {
                        commit('setHotelData', {receipt_data: receipt_data})
                    }
                },
                error => {
                    commit('setLoadingFalse');
                }
            );
    },
    getCheapestDeals({ commit }) {
        commit('setLoadingTrue');
        return dealService.getCheapestDeals()
            .then(
                deals_data => {
                    commit('setLoadingFalse');
                    if (deals_data) {
                        commit('setCheapestDealsData', {deals_data: deals_data})
                    }
                },
                // error => {}
            );
    },
    getCheapestDealsAll({ commit }, {filters} = {}) {
        commit('setCheapestDealsAllLoadingTrue');
        return dealService.getCheapestDealsAll(filters)
            .then(
                deals_data => {
                    commit('setCheapestDealsAllLoadingFalse');
                    if (deals_data) {
                        commit('setCheapestDealsAllData', {deals_data: deals_data})
                    }
                },
                // error => {}
            );
    },
    getSpecialDeals({ commit }, {filters} = {}) {
        commit('setLoadingTrue');
        filters = filters || {}
        filters.is_special = true
        filters.budget = 10000
        return dealService.getDeal(filters)
            .then(
                deals_data => {
                    commit('setLoadingFalse');
                    if (deals_data) {
                        commit('setSpecialDealsData', {deals_data: deals_data})
                    }
                },
                // error => {}
            );
    },
    getDealsByIdType({ commit }, { to, type, page }) {
        commit('setLoadingTrue');
        return dealService.getDealsByIdType(to, type, page)
            .then(
                receipt_data => {
                    commit('setLoadingFalse');
                    if (receipt_data) {
                        commit('setSpecificDeals', {receipt_data: receipt_data})
                    }
                },
                error => {
                    commit('setLoadingFalse');
                }
            );
    },
    async getBooking({ commit }, { params }) {
        commit('setLoadingTrue');
        // dealService.getBooking(params)
        // .then(
        //     receipt_data => {
        //         commit('setLoadingFalse');
        //         if (receipt_data) {
        //             commit('setBookingData', {receipt_data: receipt_data});
        //             return receipt_data;
        //         }
        //     },
        //     error => {
        //         commit('setBookingData', {receipt_data: ""});
        //         commit('setLoadingFalse');
        //         return false;
        //     }
        // );
        let receipt_data = await dealService.getBooking(params);
        commit('setLoadingFalse');
        if (receipt_data != false){
            commit('setBookingData', {receipt_data})
            return receipt_data
        }else{
            return false
        }
    },
    sendBooking({ dispatch, commit }, { params }) {
        commit('setLoadingTrue');
        return dealService.sendBooking(params)
        .then(
            receipt_data => {
                commit('setLoadingFalse');
                if (receipt_data) {
                    commit('setBookingResult', {receipt_data: receipt_data});
                }
            },
            error => {
                commit('setBookingResult', {receipt_data: ""})
                commit('setLoadingFalse');
            }
        );
    },
    saveFilterData({ commit }, { params }) {
        commit('setFilterData', { params: params });
    },
    backupDealData({ commit }, { data }) {
        commit('setBackupDealData', { data: data });
    }
};

const mutations = {
    setLoadingTrue(state) {
        state.loading = true;
    },
    setLoadingFalse(state) {
        state.loading = false;
    },
    setCheapestDealsAllLoadingTrue(state) {
        state.cheapestDealsAllLoading = true;
    },
    setCheapestDealsAllLoadingFalse(state) {
        state.cheapestDealsAllLoading = false;
    },
    setBackupDealData(state, {data}) {
        state.dealData_receipts = data;
    },
    setDealData(state, {receipt_data, page_num}) {
        if (page_num == 0) {
            state.dealData_receipts = receipt_data;
        } else {
            state.dealData_receipts.items.push(...receipt_data.items);
            state.dealData_receipts.page = page_num;
        }
    },
    setCheapestDealsData(state, {deals_data}) {
        let cheapestDealsData = state.cheapestDealsData || []
        cheapestDealsData = deals_data || []
        state.cheapestDealsData = null;
        state.cheapestDealsData = cheapestDealsData;
    },
    setCheapestDealsAllData(state, {deals_data}) {
        let cheapestDealsAllData = state.cheapestDealsAllData || []
        cheapestDealsAllData = deals_data || []
        state.cheapestDealsAllData = null;
        state.cheapestDealsAllData = cheapestDealsAllData;
    },
    setSpecialDealsData(state, {deals_data}) {
        let specialDealsData = state.specialDealsData || []
        specialDealsData = deals_data || []
        state.specialDealsData = null;
        state.specialDealsData = specialDealsData;
    },
    setDealDetailData(state, {receipt_data}) {
        // let dealDetailData_receipts = state.dealDetailData_receipts || []
        // let dealDetailData_receipts = receipt_data;
        // let total_price = dealDetailData_receipts.result.deal.total_price;
        // if (total_price) {
        //     dealDetailData_receipts.result.deal.result_price = total_price;
        // } else {
        //     const basicPaxComplect = dealDetailData_receipts.result.deal.basicPaxComplect;
        //     dealDetailData_receipts.result.deal.result_price = dealDetailData_receipts.result.deal.data.priceDescription[basicPaxComplect][0].price;
        // }
        // const startDate = dealDetailData_receipts.result.deal.departureDate;
        // let endDate = new Date(startDate);
        // endDate.setDate(endDate.getDate() + dealDetailData_receipts.result.deal.dealLength*1);
        // dealDetailData_receipts.result.deal.dateRange = {
        //     endDate: endDate,
        //     startDate: startDate
        // }
        
        // const adult_num = (dealDetailData_receipts.result.deal.basicPaxComplect.match(new RegExp("A", "g")) || []).length;
        // const child_num = (dealDetailData_receipts.result.deal.basicPaxComplect.match(new RegExp("C", "g")) || []).length;
        // var numOfPeople = "";

        // if(adult_num != 0 && child_num != 0) {
        //     if(localStorage.language != 'en' ) {
        //         numOfPeople = i18n.t('TOURISM.ADULT') + " " + adult_num + ", " + i18n.t('TOURISM.CHILD') + " " + child_num;
        //     } else {
        //         numOfPeople = i18n.t('TOURISM.ADULT') + " " + adult_num + ", " + i18n.t('TOURISM.CHILD') + " " + child_num;
        //     }
        // }
        // else if (adult_num != 0) {
        //     if(localStorage.language != 'en' ) {
        //         numOfPeople = adult_num  + " " +  i18n.t('TOURISM.ADULT');
        //     } else {
        //         numOfPeople = i18n.t('TOURISM.ADULT') + " " + adult_num;
        //     }
        // }
        // else if (child_num != 0) {
        //     if(localStorage.language != 'en' ) {
        //         numOfPeople = adult_num  + " " +  i18n.t('TOURISM.CHILD');
        //     } else {
        //         numOfPeople = i18n.t('TOURISM.CHILD') + " " + adult_num;
        //     }
        // }
        
        // dealDetailData_receipts.result.deal.numOfPeople = numOfPeople;

        // var size = 0, key;
        // let roomArray = [];
        // const obj = dealDetailData_receipts.result.deal.data.priceDescription;
        // for (key in obj) {
        //     if (obj.hasOwnProperty(key)) { 
        //         size++;
        //         const adult_num = (key.match(new RegExp("A", "g")) || []).length;
        //         const child_num = (key.match(new RegExp("C", "g")) || []).length;
        //         const infant_num = (key.match(new RegExp("I", "g")) || []).length;
        //         let room = {
        //             index: size,
        //             adult_num: adult_num,
        //             child_num: child_num,
        //             infant_num: infant_num
        //         }
        //         roomArray.push(room);
        //     }
                
        // }
        // dealDetailData_receipts.result.deal.roomData = roomArray;
        // let roomArray = [];
        // const obj = dealDetailData_receipts.result.deal.data.priceDescription;
        // for (let key in obj) {
        //     if (obj.hasOwnProperty(key) && key != "I") { 
        //         roomArray.push(key);
        //     }
                
        // }
        // dealDetailData_receipts.result.deal.roomType = roomArray;
        // const obj2 = dealDetailData_receipts.result.flights[0]
        // for (let key in obj2) {
        //     let temp_date = new Date(obj2[key][0].departureDate);
        //     const tem_day = temp_date.getDate();
        //     const tem_mon = temp_date.getMonth();
        //     obj2[key][0].new_departureDate = tem_day + "/" + tem_mon;
        // }


        // state.dealDetailData_receipts = null;
        state.dealDetailData_receipts = receipt_data;
    },
    setBookingData(state, {receipt_data}) {
        // let bookingData_receipts = state.bookingData_receipts || []
        // bookingData_receipts = receipt_data || []
        // state.bookingData_receipts = null;
        // state.bookingData_receipts = bookingData_receipts;
        state.bookingData_receipts = receipt_data;
    },
    setBookingResult(state, {receipt_data}) {
        state.bookingResult = receipt_data;
    },
    setHotelData(state, {receipt_data}) {
        state.hotelData_receipts = receipt_data;
    },
    setSpecificDeals(state, {receipt_data}) {
        if (state.specificDeals_receipts.length !=0 && state.specificDeals_receipts.items[0].destination_id == receipt_data.items[0].destination_id) {
            state.specificDeals_receipts.items.push(...receipt_data.items);
            state.specificDeals_receipts.page = receipt_data.page;
        } else {
            state.specificDeals_receipts = receipt_data;
        }
    },
    setFilterData(state, {params}) {
        state.filterData = params;
    },
    setSideFilterData(state, params) {
        state.sideFilterData = params;
    },
    setIsShowTourismDealCarousel(state, params) {
        state.isShowTourismDealCarousel = params;
    }
};

export const deal = {
    namespaced: true,
    state,
    actions,
    mutations
};