import Vue from 'vue';
import Vuex from 'vuex';

import { account } from './account.module';
import { add_to_home_screen } from './add_to_home_screen.module';
import { supplier } from './supplier.module';
import { product } from './product.module';
import { productShaniv } from './productShaniv.module';
import { payment } from './payment.module';
import { supplier_payment } from './supplier_payment.module';
import { gift_card_payment } from './gift_card_payment.module';
import { game_card_payment } from './game_card_payment.module';
import { destination } from './destination.module';
import { tourSupplier } from './tour_supplier.module';
import { alert } from './alert.module';
import { alertTwo } from './alertTwo.module';
import { deal } from './deal.module';
import { language } from './language.module';
import { banner } from './banner.module';
import { tour_order } from './tour_order.module';
import { setting } from './setting.module';
import { reseller_settings } from './reseller_settings.module';
import { seller_tourism_percentage } from './seller_tourism_percentage.module';
import { report } from './report.module';
import { permission } from './permission.module';
import { reseller_details } from './reseller_details.module';
import { cartSystem } from './cart.module';
import { shaniv_management } from './shaniv_management.module';
import { report_details } from './report_details.module';
import { reset_module } from './reset.module';
import { chargeRavkav_module } from './chargeRavkav.module';
import { shaniv_customer } from './shaniv_customer.module';
import { general_settings } from './general_settings.module';
import { tag_management } from './tag_management.module';
import { category_products } from './category_products.module';
import { system_settings } from './system_settings.module';
import { flights } from './flights.module';
import loading from './loading.module';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    account,
    add_to_home_screen,
    supplier,
    product,
    payment,
    supplier_payment,
    gift_card_payment,
    game_card_payment,
    destination,
    tourSupplier,
    alert,
    alertTwo,
    deal,
    language,
    banner,
    tour_order,
    setting,
    reseller_settings,
    seller_tourism_percentage,
    report,
    permission,
    loading,
    productShaniv,
    reseller_details,
    cartSystem,
    shaniv_management,
    report_details,
    reset_module,
    chargeRavkav_module,
    shaniv_customer,
    general_settings,
    tag_management,
    category_products,
    system_settings,
    flights,
  },
});
