import appConfig from '../appConfig'
import axios from 'axios'
import router from "../router";

export const flightsService = {
    searchFlights,
    getLocations,
    getFlightDetails,
    checkFlight,
    createFlightsOrder,
    getFlightsOrderDetails,
    getFlightsOrderStatus,
    updatePassengerInfo,
    cancelFlightsOrder,
    initiateFlightsOrderPriceUpdate,
    bookFlightsOrder,
    confirmFlightsOrderPayment
};

function searchFlights(filters) {
    let params = {
        from: filters.from.replace(/c:/g, 'city:').replace(/a:/g, 'airport:'),
        to: filters.to.replace(/c:/g, 'city:').replace(/a:/g, 'airport:'),

        adults: filters.adults,
        children: filters.children,
        infants: filters.infants,

        flight_type: filters.type,
        cabin_class: filters.cabin_class,
        max_stopovers: filters.max_stopovers,

        one_per_city: filters.one_per_city,
        one_per_date: filters.one_per_date,

        adult_hand_bag: filters.hand_bags <= filters.adults
            ? filters.hand_bags : filters.adults,
        child_hand_bag: filters.hand_bags > filters.adults
            ? filters.hand_bags - filters.adults : 0,
    };

    let adult_hold_bag = 0;
    let child_hold_bag = 0;
    if (filters.hold_bags <= filters.adults + filters.children) {
        if (filters.hold_bags > filters.adults) {
            adult_hold_bag = filters.adults;
            child_hold_bag = filters.hold_bags - filters.adults;
        } else {
            adult_hold_bag = filters.hold_bags;
            child_hold_bag = 0;
        }
    } else if (filters.hold_bags <= filters.adults * 2 + filters.children) {
        child_hold_bag = filters.children;
        adult_hold_bag = filters.hold_bags - filters.children;
    } else {
        adult_hold_bag = filters.adults * 2;
        child_hold_bag = filters.hold_bags - filters.adults * 2;
    }

    params.adult_hold_bag = adult_hold_bag;
    params.child_hold_bag = child_hold_bag;

    // todo: add logic for flex departure and return dates
    //     start_date_flex: this.dateRange.startDateFlex,
    //     end_date_flex: this.dateRange.endDateFlex,
    let start_date = formatDate(filters.start_date);
    params.departure_date_from = start_date;
    params.departure_date_to = start_date;
    if (filters.is_range_night) {
        params.nights_from = filters.nights_from;
        params.nights_to = filters.nights_to;
    } else {
        let end_date = formatDate(filters.end_date);
        params.return_date_from = end_date;
        params.return_date_to = end_date;
    }

    return _apiRequest('GET', '/flights/search', params);
}

function getLocations(filters) {
    let params = filters;

    return _apiRequest('GET', '/flights/location', params);
}

function getFlightDetails(
    provider,
    booking_token,
    adults,
    children,
    infants,
    adult_hold_bag,
    adult_hand_bag,
    child_hold_bag,
    child_hand_bag
) {
    let params = {
        provider: provider,
        booking_token: booking_token,
        adults: adults,
        children: children,
        infants: infants,
        adult_hold_bag: adult_hold_bag,
        adult_hand_bag: adult_hand_bag,
        child_hold_bag: child_hold_bag,
        child_hand_bag: child_hand_bag,
    };

    return _apiRequest('GET', '/flights', params);
}

function checkFlight(
    provider,
    booking_token,
    adults,
    children,
    infants,
    adult_hold_bag,
    adult_hand_bag,
    child_hold_bag,
    child_hand_bag,
    session_id
) {
    let params = {
        provider: provider,
        booking_token: booking_token,
        adults: adults,
        children: children,
        infants: infants,
        adult_hold_bag: adult_hold_bag,
        adult_hand_bag: adult_hand_bag,
        child_hold_bag: child_hold_bag,
        child_hand_bag: child_hand_bag,
        session_id: session_id,
    };

    // console.log(params)
    return _apiRequest('GET', '/flights/check', params);
}

function createFlightsOrder(
    provider,
    booking_token,
    passengers_data,
    phone,
    email,
    session_id
) {
    let data = {
        provider: provider,
        booking_token: booking_token,
        passengers_data: passengers_data,
        phone: phone,
        email: email,
        session_id: session_id,
    };

    // console.log(data)
    return _apiRequest('POST', '/flights/order', null, data);
}

function getFlightsOrderDetails(id, token = null) {
    let params = {};
    if (token) {
        params.token = token;
    }
    // console.log(id)
    return _apiRequest('GET', '/flights/order/' + id, params);
}

function getFlightsOrderStatus(id, token = null) {
    let params = {};
    if (token) {
        params.token = token;
    }
    // console.log(id)
    // console.log(params)
    return _apiRequest('GET', '/flights/order/' + id + '/status', params);
}

function updatePassengerInfo(
    id,
    order_id,
    token,
    passenger_info,
) {
    let params = {};
    if (token) {
        params.token = token;
    }
    let data = {passenger_info: passenger_info};

    // console.log(params)
    // console.log(data)
    return _apiRequest('PUT', '/flights/order/' + order_id + '/passenger/' + id, params, data);
}

function cancelFlightsOrder(
    id,
    token,
    type,
    message = ''
) {
    let params = {};
    if (token) {
        params.token = token;
    }
    let data = {type: type, message: message};

    // console.log(data)
    return _apiRequest('POST', '/flights/order/' + id + '/cancel', params, data);
}

function initiateFlightsOrderPriceUpdate(
    id,
    token
) {
    let params = {};
    if (token) {
        params.token = token;
    }

    // console.log(data)
    return _apiRequest('PUT', '/flights/order/' + id + '/initiate_price_update', params, null);
}

function bookFlightsOrder(
    id,
    token
) {
    let params = {};
    if (token) {
        params.token = token;
    }

    // console.log(params)
    return _apiRequest('POST', '/flights/order/' + id + '/book', params, null);
}

function confirmFlightsOrderPayment(
    id
) {
    return _apiRequest('POST', '/flights/order/' + id + '/confirm_payment', null, null);
}

function _apiRequest(method, api_path, params, data) {
    const config = {
        method: method,
        url: appConfig.apiUrl + '/' + slashLTrim(api_path),
        headers: {
            'Content-Type': 'application/json',
        },
        ...(params && {params: params}),
        ...(data && {data: data}),
        withCredentials: 1
    };

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}

function slashLTrim(str) {
    return str.replace(/^\/+/g, '');
}


function formatDate(date) {
    if (!(date instanceof Date && !isNaN(date))) {
        date = new Date(date);
    }
    let
        month = "" + (date.getMonth() + 1),
        day = "" + date.getDate(),
        year = date.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day, month, year].join("/");
}