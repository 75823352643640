// Arabic
export const locale = {
  COMMON: {
    NIS: 'شيكل',
    SELECT: 'تحديد',
    DATES: 'تواريخ',
    DATE: 'تاريخ',
    NO_DATA: 'لايوجد بيانات',
    OFFERS: 'عروض',
    SEARCH: 'بحث',
    SAVE: 'حفظ',
    EDIT: 'تعديل',
    CANCEL: 'إلغاء',
    DELETE: 'حذف',
    PHONE: 'هاتف',
    NEW: 'جديد',
    ACTIVE: 'نشيط',
    INACTIVE: "غير نشط",
    YES: 'نعم',
    NO: 'لا',
    ADD: 'أضف',
    PRICE: 'السعر',
    FIND: 'تجد',
    KG: 'كلغ',
    TIME: 'زمن',
    PLACE: 'مكان',
    INVOICE: 'فاتورة',
    CODE: 'الشفرة',
    CONTINUE: 'استمر',
    OK: 'حسنا',
    BACK: 'إرجاع',
    ACTION: 'عمل',
    E_MAIL: 'بريد الكتروني',
    CITY: 'مدينة',
    ADDRESS: 'عنوان',
    POSTAL_CODE: 'الكود البريدى',
    ACTIVITY_TIME: 'وقت النشاط',
    FIRST_NAME: 'اسم شخصي',
    LAST_NAME: 'اسم عائلة',
    ID_NUMBER_PASSPORT: 'رقم الهوية / جواز السفر',
    HOME_ADDRESS: 'عنوان المنزل',
    PASSWORD: 'كلمه السر',
    SECURE: 'آمن',
    SECURE_DEVICE: 'جهاز آمن',
    PAYMENT_METHOD: 'طريقة الدفع او السداد',
    ID: 'هوية شخصية',
    HOUR: 'الساعة',
    MORE: 'أكثر',
    LESS: 'أقل',
    COST: 'كلفة',
    BALANCE: 'أكثر',
    NAME: 'اسم',
    BIRTHDAY: 'عيد الميلاد',
    RETURN: 'إرجاع',
    CLOSE: 'يغلق',
    UPLOAD_IMAGE: 'تحميل الصور',
    HIDE: 'يخفي',
    DAY: 'يوم',
    MONDAY: 'الاثنين',
    TUESDAY: 'يوم الثلاثاء',
    WEDNESDAY: 'الأربعاء',
    THURSDAY: 'يوم الخميس',
    FRIDAY: 'يوم الجمعة',
    SATURDAY: 'يوم السبت',
    SUNDAY: 'يوم الأحد',
    COMMENTS: 'تعليقات',
    DURATION: 'مدة',
    VIEW: 'رأي',
    COVID_TXT: 'يرجى التحقق من خط سير الرحلة ، بما في ذلك فترات التوقف ، بحثًا عن <b> قيود السفر </ b> قبل الحجز. لا يعتبر خط سير الرحلة المعروض تأكيدًا على أهليتك للسفر.',
    CONFIG: 'التكوين',
    CERTIFICATE: 'شهادة',
  },
  DATE: {
    SUN: 'شمس',
    MON: 'الاثنين',
    TUE: 'الثلاثاء',
    WED: 'الأربعاء',
    THU: 'الخميس',
    FRI: 'الجمعة',
    SAT: 'السبت',
  },
  CURRENCY: {
    USD: '$',
    EUR: '€',
  },
  LOGIN: {
    ENTRANCE: 'مدخل',
    LOGIN_TEXT: 'مرحبًا ، يُرجى إدخال اسم مستخدم وكلمة مرور لتسجيل الدخول',
    USERNAME: 'اسم المستخدم',
    PASSWORD: 'كلمه السر',
    REMEMBER_ME: 'تذكرنى',
    FORGOT_PASSWORD: 'هل نسيت كلمة المرور',
    LOGIN: 'تسجيل الدخول',
    CONTACT_US: "اتصل بنا",

  },
  AUTHFOOTER: {
    SUPPORT: 'الدعم',
    HOLIDAY_HOURS: 'أيام الجمعة والمساء في ساعات الإجازة',
    ANSWER_HOURS: 'الإجابة من A إلى T بين الساعات',
    SCREEN_SHARING: 'للحصول على الدعم من خلال مشاركة الشاشة',
    START_SUPPORT: 'ابدأ الدعم',
    PHONE_SUPPORT: 'للدعم مع ممثل على الهاتف',
    CHAT_INTRO_DESC: "للدردشة مع ممثل ، انقر على أيقونة الدعم في الزاوية السفلية",
  },
  ASIDE: {
    MAIN: 'الأساسية',
    TOURISM: 'السياحة',
    COMMUNICATION: 'الاتصالات',
    PAYMENTS: 'المدفوعات',
    GIFT_CARD: 'كرت هدية',
    REPORTS: 'التقارير',
    SECOND_LEVEL: 'المستوى الثاني',
    SECOND_LEVEL_OPEN: 'فتح المستوى الثاني',
    THIRD_LEVEL: 'المستوى الثالث',
    THIRDLY: 'ثالثا',
    CANCEL_SALE: 'إلغاء البيع',
    SYSTEM_SETTINGS: 'اعدادات النظام',
    MANAGEMENT: 'إدارة',
    CATEGORIES_PRODUCTS: 'الفئات والمنتجات',
    VENDORS: 'الباعة',
    PRODUCTS: 'منتجات',
    TOURISM_PROVIDERS: 'مزودي السياحة',
    TOURISM_PRODUCTS: 'المنتجات السياحية',
    TOURIST_DESTINATIONS: 'وجهات السياح',
    HOTELS: 'الفنادق',
    SPORT: 'رياضة',
    EXHIBITIONS: 'المعارض',
    IMPRESSIONS: 'انطباعات',
    MARKETERS: 'المسوقين',
    PRIVATE_CUSTOMERS: 'عملاء من القطاع الخاص',
    SALES_REPORTS: 'تقارير المبيعات',
    BOOKKEEPING: 'إمساك الدفاتر',
    ANALYSIS_STATISTICS: 'التحليل والإحصاء',
    MANAGE_TRANSLATION: 'إدارة الترجمة',
    GENERAL: 'جنرال لواء',
    TOURISM_PROFIT_PERCENTAGES: 'نسب الربح السياحي',
    PROFIT_PERCENTAGES: 'نسب الربح',
    PROFIT_COMMISSIONS:'عمولات الربح',
    COPY_PROFIT_PERCENTAGE_TO_PRODUCT: 'انسخ نسبة الربح إلى المنتج',
    AUTHORIZES_PROVIDER_TO_PROVIDER: 'يصرح مزود لمزود',
    TAG_MANAGMENT: "إدارة العلامات",
    COPY_RESELLER_PROFILE: "نسخ الملف التعريفي للموزع",
    RESELLER_TOUR: 'جولة إعادة البيع',
    TOURISM_REPORT: 'تقرير السياحة',
    SALES_AND_PAYMENTS: 'المبيعات والمدفوعات',
    CONTRACT_DATA: 'بيانات العقد',
    SHANIV_PRODUCTS: 'منتجات شنيف',
    SHANIV_PACKAGES: 'إدارة الحزم',
    SHANIV_CUSTOMERS: 'عملاء شنيف',
    SALES: 'مبيعات',
  },
  HEADER: {
    CUSTOMER_NUMBER: 'رقم العميل',
    UPDATES_ANNOUNCEMENTS: 'التحديثات والإعلانات',
    PERSONAL_AREA: 'منطقة شخصية',
    LOG_OFF: 'إطفاء',
    SHORTCUT_SHANIV: 'الاختصار شنيف',
    SHORTCUT_SHORTCUT: 'اختصار الاختصار',
    CREATE_SHORTCUT: 'قم بإنشاء إختصار',
  },
  DASHBOARD: {
    DIAL_CARDS: 'بطاقات الاتصال',
    STARTING_NIS: 'بدءًا من شيكل {num}',
    VACATION_PACKAGES: 'حقائب العطلة',
    STARTING_VACATION: 'بدءًا من 199 دولارًا',
    GIFT_CARDS: 'بطاقات الهدايا',
    PRODUCT_NAME: 'اسم المنتج',
    PARIS: 'باريس',
    BERLIN: 'برلين',
    ROME: 'روما',
    LONDON: 'لندن',
    WEEKEND_HOTEL: 'فندق ويك إند {num} ستار',
    PRODUCT_DESCRIPTION: 'وصف المنتج',
    FAVORITES: 'المفضلة',
    CALLING_CARD: 'بطاقة اتصال',
    GAMES: 'العاب',
    VACATION_PACKAGES_TO_SELECTED_DESTINATIONS: 'حزم الإجازة إلى وجهات مختارة',
    ORGANIZED_TRIPS_TO_SELECTED_DESTINATIONS: 'رحلات منظمة إلى وجهات مختارة',
  },
  TOURISM: {
    FROM: 'من عند',
    TO: 'إلى',
    PASSENGERS: 'ركاب',
    ADULTS: 'الكبار',
    ADULT: 'بالغ',
    CHILD: 'طفل',
    CHILDREN: 'الأطفال',
    BOY: 'صبي',
    BUDGET: 'ميزانية',
    DEGREE_HOSPITALITY: 'درجة الضيافة',
    PROMOTIONS: 'الترقيات',
    CASINO: 'كازينو',
    EXHIBITIONS: 'المعارض',
    IMPRESSIONS: 'انطباعات',
    SUNBATHING: 'حمامات الشمس',
    SUN_FUN: 'متعة الشمس',
    LAST_MOMENT: 'آخر لحظة',
    SYLVESTER: 'سيلفستر',
    TO_ALL_FAMILY: 'لجميع أفراد الأسرة',
    ATTRACTIVENESS: 'جاذبية',
    RECOMMENDED: 'موصى به',
    POPULAR: 'جمع',
    STARTING_FROM: 'بدءا من',
    TOUR: 'جولة',
    TYPE_OF_DEAL: 'نوع الصفقة',
    PRICE_SHOWN_FOR_PACKAGE_DEAL_ON_DATES: 'السعر المعروض هو لصفقة الحزمة في التواريخ',
    PRICE_SHOWN_FOR_ORGANIZED_TOUR_ON_DATES: 'السعر المعروض هو لجولة منظمة في التواريخ',
    VACATION_PACKAGES_TO_DESTINATION_ON_VARIETY_DATES: 'حزم الإجازات إلى {DES} في تواريخ مختلفة',
    ORGANIZED_TOUR_TO_DESTINATION_ON_VARIETY_DATES: 'جولة منظمة إلى {DES} في عدة تواريخ',
    FIND_DATES: 'البحث عن التواريخ',
    CHOOSE_VACATION_PACKAGE: 'اختر حزمة عطلة',
    DES_VACATION_FOR_NIGHTS: '{DES} باقة إجازة لمدة {NUM} ليلة',
    SHOW_MORE: 'أظهر المزيد',
    ANYWHERE: 'في أى مكان',
    NOFSHON: 'NOFSHON',
    ORGANIZED_TOUR: 'جولة منظمة',
    ORGANIZED_TOURS: 'الجولات المنظمة',
    ALL: 'الجميع',
    ALL_DESTINATIONS: 'كل الوجهات',
    VIEW_ALL_DEAL_DESTINATION: 'عرض كل وجهة الصفقة',
    PACKAGE_DEALS: 'صفقات',
    SORT: 'فرز',
    FILTER: 'منقي',
    DEPARTURE_DATE: 'تاريخ المغادرة',
    LOW_TO_HIGH: 'من أسفل إلى أعلى',
    HIGH_TO_LOW: 'من الأعلى إلى الأقل',
    NON_STOP: 'بدون توقف',
    STOP_NUM: '{NUM} توقف',
    STOPS: 'توقف',
    NUMBER_OF_NIGHTS: 'عدد الليالي',
    ACCOMMODATION_STAR_RATING: 'الإقامة تصنيف النجوم',
    NON: 'عدم',
    BOARD_BASIS: 'أساس متنها',
    ROOM_ONLY: 'غرفة فقط',
    BED_BREAKFAST: 'سرير و فطور',
    HALF_BOARD: 'نصف إقامة',
    FULL_BOARD: 'ممتلئ',
    PRICE_RANGE: 'نطاق السعر',
    TIMES: 'مرات',
    TAKE_OFF: 'اخلع',
    LANDING: 'الهبوط',
    TAKE_OFF_FROM_PLACE: 'الإقلاع من {PLACE}',
    LANDING_FROM_PLACE: 'الهبوط من {PLACE}',
    CLEAR_FILTERS: 'مسح عوامل التصفية',
    DEAL_CHANGED: 'لاحظ أن المعاملة المحددة قد تغيرت',
    PLEASE_CHECK_OTHER_DEALS: 'آسف فاتتك صفقة الحزمة - يرجى التحقق من العروض الترويجية الأخرى',
    SPORT_EVENTS: 'الأحداث الرياضية',
    WORKING_WITH: 'أعمل مع',
    TOP_CATEGORIES_QUICK_SELECT: 'تحديد الفئات الأعلى سريعًا',
    SEARCH_YOUR_DESIRE: 'ابحث عن رغبتك',
    ORGANIZED_TOURS_TO_SELECTED_DESTINATIONS: 'جولات منظمة إلى وجهات مختارة',
    TRADE_SHOWS: 'المعارض التجارية',
    FREE_SEARCH: 'بحث مجاني',
    RECOMMENDED_TRADE_SHOWS: 'المعارض التجارية الموصى بها',
    UPCOMING_TRADE_SHOWS: 'المعارض التجارية القادمة',
    INFO: 'معلومات',
    MORE_DATES: 'المزيد من التواريخ',
    TRAVELERS: 'مسافرين',
    ADVERTISEMENT: 'الإعلانات',
    EXHIBITION_FOR_ADVERTISING_PROMOTIONAL_ITEMS: 'معرض للدعاية والاعلان',
    ADVERTISING_TRADE_SHOWS: 'المعارض التجارية الإعلانية',
    CHOOSE_VACATION_DEALS: 'اختر صفقة الإجازة',
    VACATION_PACKAGES_TO: 'حزم الإجازات إلى {DES}',
    MARKETING_TRADE_SHOWS: 'المعارض التجارية للتسويق',
    TRADE_SHOW_CONTACT: 'المعرض التجاري الاتصال',
    AUDIENCE: 'جمهور',
    CYCLE: 'دورة',
    LOCAL_TIME: 'الوقت المحلي',
    FAIR_LOCATION: 'موقع عادل',
    OPEN_IN_MAP: 'فتح في الخريطة',
    FAIR_ORGANIZER: 'منظم عادل',
    FAIRS_OF_THESE_SECTORS: 'معارض هذه القطاعات',
    HOTEL_DETAILS: 'تفاصيل الفندق',
    RECOMMENDED_VACATION_PACKAGE: 'حزمة عطلة موصى بها',
    BOOK: 'الكتاب',
    DISCLAIMER: 'تنصل',
    CHOOSE_DIFFERENCT_RECOMMENDED_VACATION_PACKAGE: 'اختر حزمة عطلة موصى بها مختلفة',
    VIEW_ALL_FLIGHTS: 'عرض كل الرحلات',
    FLIGHTS_TO: 'رحلات جوية إلى',
    FULL_DETAILS: 'تفاصيل كاملة',
    EXPLORE_MORE_DATES: 'اكتشف المزيد من التمور',
    FLIGHTS_SELECTED_DESTINATIONS: 'رحلات إلى وجهات مختارة',
    VIEW_ALL_VACATIONS_DEALS: "عرض جميع عروض الإجازات",
    VACATION_PACKAGES_TO_SELECTED_DESTINATIONS: "حزم عطلة إلى وجهات مختارة",
    VIEW_ALL_ORGANIZED_DEALS: 'عرض جميع الصفقات المنظمة',
    PRICE_SPECIFIC_FLIGHT_SHOWN: 'السعر للرحلة المحددة المعروضة',
    GO_TO_DEAL: 'اذهب إلى الصفقة',
    BOOK_FLIGHT: 'احجز رحلة طيران',
    BOOK_A_DEAL: 'احجز صفقة',
    ORDER_DETAILS: 'تفاصيل الطلب',
    TRIP_SUMMARY: 'ملخص الرحلة',
    PERSONAL_ITEM: 'عنصر شخصي',
    CABIN_BAGGAGE: 'أمتعة المقصورة',
    CHECKED_BAGGAGE: 'الحقائب المفحوصة',
    BAGGAGE_INFO: 'معلومات الأمتعة',
    SELECT_ONE_OPTION: 'حدد خيارًا واحدًا',
    NO_CHECKED_BAGGAGE: 'لا توجد أمتعة مسجلة',
    TIP: 'نصيحة',
    FLIGHT_TIP_TXT: 'سافر بأمان عن طريق إضافة حقيبة مسجلة بدلاً من ذلك. يمكنك أن تأخذ أقل على متن الطائرة وتجنب التحرك في المقصورة.',
    PASSENGERS: 'ركاب',
    TOTAL_ORDER_PRICE: 'إجمالي سعر الطلب',
    PASSENGERS_PASSPORT_DETAILS: 'تفاصيل جواز سفر الركاب',
    ORDER_SUMMARY_TXT: 'الطلب قيد المعالجة - سيتم إرسال تأكيد الطلب في غضون 48 ساعة',
    CHEAPEST_FLIGHTS: 'أرخص الرحلات الجوية',
    LAST_MINUTE_FLIGHTS: 'رحلات اللحظة الأخيرة',
    DATE_RANGE: 'نطاق الموعد',
    DURATION: 'مدة',


  },
  COMMUNICATION: {
    BEST_SELLERS: 'الأفضل مبيعًا',
    MESSAGES: 'رسائل',
    PRICE_USE_ABROAD_ISRAEL: 'سعر للاستخدام في الخارج وفي إسرائيل',
    OTHER_1: 'أخرى 1',
    OTHER_2: 'أخرى 2',
    SURFING: 'ركوب الأمواج',
    GIGA_ANOTHER_2: 'جيجا أخرى 2',
    VALID_ON_DAYS: 'صالح في أيام',
    PRODUCT_WITH_NO_VAT: 'معفى من الجمارك',
  },
  TOURISM_SUPPLIER: {
    TITLE: 'إدارة موردي السياحة',
    ARKIO: 'أركيو',
    HP: 'حصان',
    PROFIT_PERCENTAGES: 'نسب الربح',
    CONTACTS: 'جهات الاتصال',
    BANK_DETAILS: 'التفاصيل المصرفية',
    PROVIDER_NUMBER: 'رقم المزود',
    SUPPLIER_CODE: 'كود المورد في أماديوس',
    NAME_PROVIDER: 'مزود الاسم',
    HP_DEALER: 'حصان/ موزع',
    CONTACT: 'اتصل',
    MOBILE_CONTACT: 'الاتصال المحمول',
    DISPLAY: 'عرض',
    FLIGHTS: 'الرحلات الجوية',
    VACATIONERS: 'المصطافون',
    ORGANIZED: 'منظم',
  },
  DESTINATION: {
    TITLE: 'إدارة الوجهات السياحية',
    ALL_AIRPORTS: 'جميع المطارات',
    CONFIRM: 'تؤكد',
    COUNTRY: 'بلد',
    DESTINATION_NAME: 'اسم الوجهة',
    AIRPORTS: 'المطارات',
    TAGS: 'العلامات',
    ORDER: 'طلب',
    VISA: 'تأشيرة دخول',
    VIDEO_LINKS: 'روابط الفيديو',
    VIDEO: 'فيديو',
  },
  TOURISM_PRODUCT: {
    SHARE_OVER: 'مشاركة على',
    FLIGHT_DETAILS: 'تفاصيل الرحلة',
    SIXTH: 'السادس',
    SABBATH: 'السبت',
    WHAT_INCLUDE: 'ما هو مدرج في الرحلة',
    OVERTIME: 'متأخر، بعد فوات الوقت',
    INVITATION: 'رسالة دعوة',
    BOOKING_MODAL_TXT: 'لإكمال الطلب ، يرجى ملء التفاصيل التالية',
    WAY_PAYMENT: 'طريقة الدفع',
    CASH: 'السيولة النقدية',
    CREDIT: 'ائتمان',
    DISCOUNT_MODAL_TITLE: 'تغيير سعر الحزمة للعميل',
    DISCOUNT_MODAL_TXT: 'يمكنك تسعير أو إعطاء خصم للعميل',
    ORIGINAL_PACKAGE_COST: 'تكلفة العبوة الأصلية',
    DISCOUNT_PERCENTAGE: 'نسبة الخصم',
    INCREASE: 'أضف',
    DISCOUNT: 'تخفيض',
    DISCOUNT_EXP: 'يمكنك إضافة عدد غير محدود وإعطاء خصم يصل إلى مبلغ',
    PRICE_UPTO_DATE: 'السعر حتى الآن',
    GIVE_DISCOUNT: 'منح خصم',
    GIVE_INCREASE: 'إعطاء زيادة',
    FRONTAL_SERVICE: 'خدمة أمامية',
    FRONTAL_CUT: 'قطع الخدمة الأمامية الخاصة بك',
    BASE_COMMISSION: 'عمولة القاعدة الخاصة بك',
    TOTAL_COMMISSION: 'مجموع عمولتك',
    YOUR_COMMISSION: 'عمولتك',
    ROOMS: 'غرف',
    BABIES: 'أطفال',
    ROOM: 'غرفة',
    THINGS_DOAREA_TXT: 'أشياء للقيام بها في المنطقة',
    OUT_OF_COMMISSION: 'للخروج من هذا العمولة',
    HOTEL_CHANGE: 'تغيير الفندق',
    ADD_ROOM: 'أضف غرفة',
    FACILITIES: 'المنشآت',
    ROOM_TYPE: 'نوع الغرفة',
    HOSTING_BASE: 'قاعدة الاستضافة',
    ACCOMMODATION_LEVEL: 'مستوى الاستضافة',
    SEATS_LEFT: '{num} مقاعد متبقية',
    NO_STAR: 'لا نجمة',
    YOUR_SERVICE_FEE: 'رسوم الخدمة الخاصة بك',
    YOUR_BASE_FEE: 'الرسوم الأساسية الخاصة بك',
    YOUR_TOTAL_COMMISSION: 'إجمالي عمولتك',
    SEAT_LIMIT_TXT: 'يمكنك إضافة غرف لما يصل إلى {num} أشخاص',
    REMOVE_ROOM: 'إزالة الغرفة',
    MAX_DECREASE_VALUE: 'قيمة الحد الأقصى',
    GENERAL_RULES: "قواعد عامة",
    TRIP_ROUTE: "طريق الرحلة",
    DETAILED_TOUR_PLAN: "خطة جولة مفصلة",
    MANUALLY_DISCOUNT: "يدويا الخصم",
    CHANGE_PRICE: "تغيير السعر",
    CHNAGE_COMMISSION_TXT: "يمكنك إضافة أو تخفيض عمولتك",
    ADD_TO_PRICE: "أضف إلى السعر",
    CURRENT_TOTAL_PRICE: "السعر الإجمالي الحالي",
    CONTINUE_WITH_ORIGINAL_PRICE: "تواصل مع السعر الأصلي",
    NO_LIMITS_FOR_ADDING: "لا حدود للإضافة",
    DISCOUNT_IS_UP_TO: "الخصم يصل إلى",
    DISCOUNT_PLACEHOLDER_TXT: "أدخل مبلغ الخصم حتى",
    ADD_PRICE_PLACEHOLDER_TXT: "أدخل المبلغ الذي تريد إضافته إلى السعر",
    DEAL_SUMMARY: "ملخص الصفقة",
    PLACE_ORDER: "مكان الامر"
  },
  GIFTCARDSUP: {
    ADDITIONAL_PRODUCTS: 'منتجات إضافية',
  },
  GIFTCARD_PRODUCT: {
    PHONE_NUMBER: 'رقم الهاتف',
    PHONE_VERIFY: 'التحقق من رقم الهاتف',
    MORE_DETAILS: 'المزيد من التفاصيل',
    SIMILAR_PRODUCTS: 'منتجات مماثلة',
    GIFTCARD: 'كرت هدية',
    VERIFY_MODAL_TITLE: 'في انتظار التحقق من الهاتف المحمول العام',
    VERIFY_MODAL_TEXT:
      'لإكمال الطلب ، يرجى تمرير رمز التحقق لمرة واحدة إلى العميل ، حتى يتمكنوا من إدخاله على الرابط المخصص الذي تم تلقيه عبر رسائلهم القصيرة على الهاتف المحمول',
    ONE_VCODE: 'رمز التحقق لمرة واحدة',
    APPROVAL: 'موافقة',
    CANCEL_RESERVATION: 'إلغاء الحجز',
  },
  GIFTCARD_PAYMENT_RESULT: {
    INVITATION_ACCEPTED: 'تم قبول الدعوة',
    IN_TEL_AVIV: 'في تل أبيب',
    AGENT: 'وكيل',
    HAIM_LEVIATHAN: 'حاييم لفياتان',
    PRODUCT: 'المنتج',
    QUANTITY: 'كمية',
    TOTAL: 'مجموع',
    VAT: 'ضريبة القيمة المضافة',
    TOTAL_PAYMENT: 'إجمالي الدفع بما في ذلك ضريبة القيمة المضافة',
  },
  GIFTCARD_PAYMENT_RESPUB: {
    TITLE: 'تم استلام الرمز بنجاح',
    ORDER_NUMBER: 'رقم الأمر',
    BUSINESS_NAME: 'الاسم التجاري',
    SUC_TEXT: 'سيتم تحديث رمز الشراء هنا في الدقائق القليلة القادمة',
    PURCHASE_CODE: 'كود شراء',
    PURCHASE_EXTERNAL_ID: 'رمز البطاقة',
    SERIAL_NUMBER: "رقم متسلسل",
  },
  GIFTCARD_PAYMENT_VERIPUB: {
    ONETIME_CODE: 'رمز لمرة واحدة',
    DESC: 'أدخل الرمز لمرة واحدة في المتجر',
  },
  PAGE404: {
    ERROR_TEXT: 'عفوًا! الصفحة غير موجودة!',
  },
  MANAGE_TRANSLATION: {
    TITLE: 'إدارة الترجمة',
    ENGLISH: 'الإنجليزية',
    HEBREW: 'اللغة العبرية',
    ARABIC: 'عربى',
    GROUP: 'مجموعة',
    KEY: 'مفتاح',
  },
  VALIDATION: {
    REQUIRED_FIELD: 'هذا مجال مطلوب.',
    INVALID_VALUE: 'قيمة غير صالحة',
    MIN_VALUE: 'الحد الأدنى للقيمة هو {value}',
    MAX_VALUE: 'القيمة القصوى هي {value}',
    INTEGER: 'يجب أن تكون القيمة عددًا صحيحًا',
    EXACT_LENGTH: 'يجب أن يكون طول القيمة {value}',
    CURRENT_BALANCE: 'الرصيد الحالي هو {value}',
  },
  ALERT: {
    OPERATION_SUCCESS: 'نجاح العملية',
    SUCCESS: 'نجاح',
    INFORMATION: 'معلومه',
    ERROR: 'خطأ',
    MESSAGEL: 'رسالة',
    NO_FILTER_DATA: 'لا توجد بيانات تصفية',
    SUCCESS_MSG: 'تمت المعاملة بنجاح',
    CLOSURE: 'قريب',
    REQUIRE_PASSPORT_TXT: 'يرجى ملء جميع تفاصيل جواز السفر أولاً',
    ERROR_PASSPORT_UPLOAD_TXT: 'حدث خطأ في استخراج بيانات جواز السفر من الصورة',
    TRY_AGAIN: 'حاول مرة أخري',
    DELETE_CONFIRM_TXT: "هل أنت متأكد أنك تريد حذف {NAME}؟"
  },
  TOUR_ORDER: {
    TRANSACTION_DETAILS: 'تفاصيل الصفقه',
    TRANSACTION_DETAILS_TXT: 'تفاصيل الحجز تواريخ السفر الإضافات الوجهة',
    SWITCH_TO_PAYMENT: 'التبديل إلى الدفع',
    METHODS_OF_PAYMENT: 'طرق الدفع',
    TOUR_PAYMENT_TXT: 'يرجى إدخال معلومات الائتمان الخاصة بك للدفع',
    TICKET_NUMBER: 'رقم التذكرة',
    VALIDITY: 'صلاحية',
    DIGITS_ON_CARD: '3 أرقام على ظهر البطاقة',
    CARDHOLDER_NAME: 'اسم حامل البطاقة',
    TOUR_PAYMENT_BOTTOMTXT: 'يمكنك مراجعة الطلب قبل تأكيد الدفع',
    PASSPORTS_PLEASE: 'جوازات السفر من فضلك',
    TOUR_PASSPORT_TXT: 'التقاط صور لجوازات السفر للمسافرين',
    PASSPORT_PHOTO: 'صورة جواز السفر',
    TOUR_PASSPORT_TXTCANT: 'لا تستطيع التقاط الصور؟',
    ENTER_MANUALLY: 'أدخل التفاصيل يدويًا',
    TOUR_PASSPORT_MTITLE: 'تم تصوير هذا الراكب بالفعل',
    TOUR_PASSPORT_MTXT: 'يرجى أخذ جواز سفر آخر لم يؤخذ',
    PASSENGER: 'سافر',
    ANOTHER_PASSPORT_PHOTO: 'صورة جواز سفر أخرى',
    PASSPORT_INFOR_VERIFICATION: 'التحقق من معلومات جواز السفر',
    TOUR_PERSON_TXT: 'يرجى التحقق من معلومات الركاب المأخوذة',
    FITST_NAME_EN: 'الاسم الأول بالإنجليزية',
    LAST_NAME_EN: 'اسم العائلة بالإنجليزية',
    NUMBER_OF_PASSPORT: 'رقم جواز السفر',
    ID_NUMBER: 'رقم الهويه',
    DATE_OF_BIRTH: 'تاريخ الولاده',
    PASSPORT_BASE_COUNTRY: 'الدولة التي صدر فيها جواز السفر',
    GENDER: 'جنس',
    MAN: 'رجل',
    WIFE: 'زوجة',
    SHOOT_AGAIN: 'أطلق النار مرة أخرى',
    WHICH_PASSENGER_CUSTOMER: 'أي من الركاب هو العميل',
    TOUR_CONFIRM_TXT: 'تفاصيل الحجز تواريخ السفر الإضافات الوجهة',
    ORDER_CONFIRMATION: 'تأكيد الطلب',
    TOUR_CONFIRM_TXTBOTTOM: 'بالضغط على تأكيد الطلب ، فإنك تؤكد',
    SITE_POLICY: 'سياسة الموقع',
    TOUR_SUCCESS_TXT: 'يمكن تتبع الطلب',
    TOUR_SUCCESS_LINK: 'في المجال الشخصي',
    PASSPORT_UPLOAD_STATE: 'تم تحميل {{num1}} من {{num2}} جوازات السفر',
    PRICE_PER_PASSENGER: 'سعر الراكب',
    TOTAL_PRICE: 'السعر الكلي',
    PRICE_BEFORE_DISCOUNT: 'السعر قبل الخصم',
    PRICE_BEFORE_INCREASE: 'السعر قبل الزيادة',
    PRICE_TO_PAID: 'الثمن الواجب دفعه',
    CANCEL_TRANSACTION: 'الغاء صفقة',
    CURRENCEY_EXCHANGE_RATE: 'سعر صرف العملة',
    TOTAL_PRICE_TXT: 'السعر الإجمالي مبين بالشيكل ومقرب',
    ROUND_TRIP_FLIGHT: 'رحلة ذهابا وإيابا',
    ACCOMMODATION: 'الإقامة',
    PASSENGERS_VERHICLE_ROOMS: 'غرف الركاب والمركبات',
    TRANSPORTATION: 'سفريات',
    TICKETS: 'تذاكر',
    TV_SET: 'جهاز التلفاز',
    FREE_PARKING: 'موقف سيارات مجاني',
    BATHROOM: 'حمام',
    FULL_DETAIL_HOTEL: 'انقر للحصول على التفاصيل الكاملة حول الفندق',
    TRANSPORTATION_TITLE: 'النقل: الاستلام والعودة من المطار',
    GO: 'اذهب',
    DISTANCE: 'مسافة',
    ESTIMATED_TRANSPORTATION_TIME: 'وقت النقل المقدر',
    TEL_AVIV: 'تل أبيب',
    SELECT_CUSTOMER_TXT: 'من فضلك ، حدد العميل',
    PASSPORT_EXPIRATION_DATE: 'تاريخ انتهاء الجواز',
    PASSENGER_PASSPORT_DETAILS: 'تفاصيل جواز سفر الركاب',
  },
  GENERAL_SETTING: {
    TOURISM_SETTINGS: 'إعدادات السياحة',
    OFF_REPLACEMENT_TXT: 'عرض حزم الاستبدال عندما يكون السعر غاليًا',
    PERCENT: 'في المئة',
    PERCENT_ABSORPTION_TXT:
      'النسبة المئوية لزيادة سعر الامتصاص للمشتريات النقدية',
    DAYS_MARKETER_TXT: 'عدد الأيام التي يحق فيها للمسوق الحصول على عمولة',
    MINIMUM_PROFIT_TXT: 'النسبة المئوية للربح الدنيا للالواح',
    ADD_PENNY_TXT: 'إضافة البنسات إلى رسوم صرف العملات',
    DAYS: 'ايام',
    PENNIES: 'البنسات',
    ORGANIZED_GENERAL_RULES: "القواعد العامة المنظمة",
    ORGANIZED_CANCELLATION_DETAILS: "تفاصيل الإلغاء المنظم",
    PASSPORT: "جواز سفر",
    ACURRA:"أكورا",
    MRZ_SCANNER:"mrz الماسح الضوئي",
  },
  TOURISM_PROFIT_PERCENTAGES: {
    DISTRIBUTOR: 'التجزئه',
    MARKETER: 'تسويق',
    CLIENT_NO: 'رقم العميل',
    MOBILE_PHONE: 'هاتف نقال',
    HP_AUTHORIZED_DEALER: 'إتش بي / موزع معتمد',
    PLOTTERS: 'المتآمرون',
    PLATORS_ALL_RESELLERS: 'بلسم جميع الموزعين',
    SEARCH_CUSTOMER_NUMBER: 'ابحث عن رقم العميل',
    MOBILE_PHONE_SEARCHING: 'الهاتف المحمول للبحث',
    PERCENTAGE_CASH_PROFIT: 'نسبة الربح النقدي',
    DIRECT_SALES_COMMISSION: 'عمولة البيع المباشر',
    REFERRAL_SALES_COMMISSION: 'عمولة مبيعات الإحالة',
    CREDIT_PROFIT_PERCENTAGE: 'نسبة ربح الائتمان',
    SALES_COMMISSION_DIRECT_LINK: 'ارتباط مباشر بعمولة المبيعات',
    INDIRECT_LINK_SALES_COMMISSION: 'عمولة مبيعات وصلة غير مباشرة',
    FRONTAL_REPRESENTATIVE_FEE: 'رسوم التمثيل الجبهي',
    FRONTAL_TXT: 'المبلغ التلقائي حسب عملة المعاملة',
  },
  RESELLER_TOUR_SETTINGS: {
    RESELLER_TOUR_SETTINGS: 'إعدادات جولة المورد',
    RESELLER_ID: 'معرف البائع',
    DIRECT_SALE_FEE: 'رسوم البيع المباشر',
    AFFILIATE_SALE_FEE: 'رسوم بيع الأفلييت',
    CREDIT_SALE_FEE: 'رسوم بيع الائتمان',
    LINK_SALE_FEE: 'ربط رسوم البيع',
    SECONDLINK_SALE_FEE: 'رسوم بيع الرابط الثاني',
    FRONT_SALE_FEE: 'رسوم البيع الأمامي',
    DIRECTFRONT_SALE_FEE: 'رسوم البيع المباشر',
    EDIT_RESELLER_SETTINGS: 'تحرير إعدادات المورد',
    ADD_RESELLER_SETTINGS: 'أضف إعدادات الموزع',
  },
  REGISTER_RESELLER: {
    MARKETER_REGISTRATION_BUSINESS: 'تسجيل المسوق: تفاصيل العمل',
    WELCOME_ENTER_TXT: 'مرحبًا ، يُرجى إدخال التفاصيل التالية',
    EMAIL_ACCOUNTING: 'محاسبة البريد الإلكتروني',
    VENDOR_PROFILE: 'ملف تعريف البائع',
    PROFILE_PERCENTAGE_PROFILE: 'ملف النسبة المئوية للربح',
    FEES_PROFILE: 'ملف الرسوم',
    TOURISM_PERCENTAGE_PROFILE: 'لمحة عن نسبة السياحة',
    BUSINESS_NAME: 'الاسم التجاري',
    AUTHORIZED_DEALER_HP: 'موزع معتمد / H.P.',
    TYPE_OF_BUSINESS: 'نوع من الاعمال',
    BUSINESS_PHONE: 'هاتف العمل',
    MOBILE_PHONE_CONTACT: 'اتصال الهاتف المحمول',
    ARTICLE: '{num} مادة',
    FRIDAY_HOLIDAY_EVES: 'يوم الجمعة وعشية العيد',
    SATURDAYS_HOLIDAYS: 'أيام السبت والأعياد',
    SUNDAYS_THURSDAYS: 'أيام الأحد - الخميس',
    TITLE2: 'تسجيل المسوق: تفاصيل المالك وتسجيل الدخول',
    ENTER_FOLLOWING_DETAILS: 'يرجى إدخال التفاصيل التالية',
    ADDING_ADDITIONAL_OWNERS: 'إضافة مالكين إضافيين',
    TITLE3: 'تسجيل المسوق: تفاصيل الأمن والدفع',
    LINE_OF_CREDIT: 'خط ائتمان',
    BANK_ACCOUNT_INFORMATION: 'معلومات الحساب المصرفي',
    BANK: 'بنك',
    BANK_NUMBER: 'عدد البنوك',
    ACCOUNT_NUMBER: 'رقم حساب',
    BRANCH: 'فرع',
    COLLECTION_DAYS: 'أيام الجمع',
    A: 'و',
    B: 'ب',
    C: 'ج',
    D: 'د',
    E: 'ה',
    F: 'و',
    REMARKS: 'ملاحظات',
    TITLE4: 'تسجيل المسوق: إدارة المستندات',
    AUTHORIZED_DEALER_CERTIFICATE: 'شهادة تاجر معتمد',
    COMMUNICATION_CONTRACT: 'عقد اتصال',
    PERSONAL_GUARANTEE: 'ضمان شخصي',
    HAWK_APPROVAL: 'موافقة هوك',
    ADD_A_DOCUMENT: 'أضف وثيقة',
    FINISHED: 'انتهى',
    DOCUMENT_TYPE: 'نوع الوثيقة',
    UPLOAD_DOCUMENT: 'تحميل مستند',
    CHIC_PHOTO: 'صورة أنيقة',
    AGENT_RESPONSIBLE: 'الوكيل المسؤول',
    DEFINE_SELLER_AS_A_DISTRIBUTOR: 'عرّف البائع بأنه موزع',
    AS_DISTRIBUTE_WARNING_TXT: '* انتباه! من المستحيل إلغاء الحفظ.',
  },
  REPORT_SALES: {
    SALES: 'مبيعات',
    SALES_REPORT: 'تقرير المبيعات',
    TIME_FILTERING: 'تصفية الوقت',
    FROM_DATE: 'من التاريخ',
    UNTIL: 'حتى',
    START_HOUR: 'من ساعة',
    END_HOUR: 'لمدة ساعة',
    WITHOUT_TIME_LIMITS: 'بدون حدود زمنية',
    RANGE_OF_DATES: 'نطاق التواريخ',
    GENERAL_FILTERING: 'التصفية العامة',
    DOUBT: 'شك',
    FORM_OF_PAYMENT: 'طريقة الدفع',
    MOBILE: 'نقال',
    CONSUMER_PRICE: 'سعر المستهلك',
    COST_PRICE: 'سعر الكلفة',
    STATUS: 'وضع',
    REQUEST_FOR_CANCELLATION: 'طلب الإلغاء',
    CELLCOM: 'سيلكوم',
    AMAZON: 'أمازون',
    NETFLIX: 'نيتفليكس',
    MATT: 'غير لامع',
    COMPLETED: 'منجز',
    BY_INVITATION: 'عن طريق الدعوة',
    OUT_OF: 'بعيدا عن المكان',
    UTILIZED: 'تستخدم',
    APPLY: 'تطبيق',
    IN_TREATMENT: 'في العلاج',
    CANCELED: 'ألغي',
    PARTIALLY_CANCELED: 'مُلغى جزئيًا',
    FOR_FULL_DETAILS: 'للحصول على التفاصيل الكاملة',
    NUMBER_OF_PASSENGERS: 'عدد الركاب',
    HOSTING: 'الاقامه',
    STARS: 'نجوم',
    HOTEL: 'الفندق',
    DESTINED: 'مقدر',
    CUSTOMER_NAME: 'اسم الزبون',
    TRANSACTION_TYPE: 'نوع المعاملة',
    NUMBER_OF_TRANSACTION: 'رقم المعاملة',
    EXPORT_TO_EXCEL: 'تصدير إلى Excel',
    FOR_PRINTING: 'للطباعة',
    SEARCH: 'بحث',
    EVERYTHING: 'الكل',
    START_DATE: 'تاريخ البدء',
    END_DATE: 'تاريخ الانتهاء',
    SUPPLIER_ID: 'شك',
    EXTERNAL_ORDER_ID: 'معرف الطلب الخارجي',
    GIFT_CARD_CODE: 'كود بطاقة الهدايا',
    CONTRACT_NUMBER: 'رقم التعاقد',
    BILL_NUMBER: 'رقم الفاتوره',
    CANCELLED: 'ألغيت',
    OPENDED: 'افتتح',
    SUCCESS: 'نجاح',
    PENDING: 'قيد الانتظار',
    FAILED: 'فشل',
    INVOICES_RECEIPTS: 'الفواتير والإيصالات',
    VIEWING: 'رأي',
    DOWNLOAD: 'تحميل',
    RECEIPT_TAX_INVOICE: 'ضريبة الاستلام / الفاتورة',
    RAV_KAV_TRANSACTION: 'رقم المعاملة متعدد الخطوط',
    RAV_KAV_CARD_NUMBER: 'رقم التذكرة',
    CONTRACT_TYPE: 'نوع العقد',
    CONTRACT_NAME: 'اسم العقد',
    CANCEL_STATUS: 'حالة إلغاء المعاملة',
    AWAITING: 'ريثما',
    REJECT: 'مرفوض',
    NO_CANCEL_REQUEST: 'لم يتم تقديم طلب الإلغاء',
    GAME_CARD_CODE: 'كود بطاقة اللعبة',
    SERIAL_NUMBER: "رقم متسلسل",
    PIN_CODE: "الرمز السري",
    NOT_CANCELLED: 'لم يتم إلغاؤها',
    REJECTED: 'مرفوض',
    CANCEL_ACTION: 'إلغاء الإجراء',
    STATUS_DATE: 'تاريخ الحالة',
    STATUS_START_DATE: 'تاريخ بدء الحالة',
    STATUS_END_DATE: 'تاريخ انتهاء الحالة'
  },
  CARMEL_TUNNELS: {
    PAYMENT_CARMEL_TUNNELS: 'دفع أنفاق الكرمل',
    ID_NUMBER: 'رقم الضمان الاجتماعي / CNPJ',
    INVOICE_LAST_DIGITS: 'آخر 6 أرقام من رقم الفاتورة',
    INVOICE_SUM: 'قيمة الفاتورة',
    PAYMENT_SUM: 'مبلغ الدفع',
    MOBILE_PHONE_NUMBER: 'رقم الهاتف الجوال',
    RESULT_TXT: 'في نهاية الدفع ، يجب تزويد العميل بإيصال فقط!',
    WHOLE: 'كل',
    VERIFICATION_BEFORE_LOADING: 'التحقق من معلومات العميل قبل التحميل',
    DETAILS_PLACE_TRANSACTION: 'تفاصيل مكان المعاملة',
    TRANSACTION_APPROVAL: 'رقم الموافقة على المعاملة',
    INVOICE_DETAILS: 'تفاصيل الفاتورة',
    NUMBER_CLEARINGHOUSE: 'رقم غرفة المقاصة',
    SERVICE_FEE: 'رسوم الخدمة',
    AFTER_VERIFY_TXT: 'بعد التحقق من التفاصيل لا يمكن إلغاء المعاملة!',
    CONFIRMATION_PURCHASE: 'تأكيد التفاصيل والشراء',
  },
  SUPPLIER_PAYMENT: {
    CANCEL: 'يلغي',
    CANCEL_PAYMENT: 'إلغاء الصفقة',
    LOAD_MULTILINE_CARD: 'قم بتحميل بطاقة متعددة الخطوط',
    RAVKAV_READER_DISCONNECTED: 'Card reader not found - please connect it and try again',
    RAVKAV_NO_CARD: 'Card not found in card reader - please insert the card and try again',
    RAVKAV_FAILURE_CARD_EXTRACTED: 'The card was extracted from the card reader - please insert the card and try again',
    MULTILINE_CARD_TXT1:
      'أدخل بطاقة متعددة الخطوط في قارئ البطاقات مع توجيه الشريحة لأعلى',
    MULTILINE_CARD_TXT2:
      'إذا لم يتم تثبيت برنامج متعدد الخطوط على الكمبيوتر ، فيجب تنزيله وتثبيته قبل الاستخدام.',
    CANCELATION: 'الغاء',
    CLICK_VIEW_CHARGE_CARDS: 'انقر لعرض بطاقات شحن البطاقة',
    POPUP_MUST_CONFIRMED: 'يجب تأكيد النافذة المنبثقة',
    WINDOWS: 'شبابيك',
    UBUNTU: 'أوبونتو',
    MACINTOSH: 'ماكنتوش',
    MONTHLY_SENSES: 'الحواس الشهرية',
    FREE_DAILY: 'مجانا يوميا',
    FREE_WEEKLY: 'مجاني أسبوعي',
    FREE_FLEXIBLE: 'مرونة مجانية',
    ACCUMULATED_VALUE: 'القيمة المتراكمة',
    JERUSALEM: 'القدس',
    MORE_FILTERS: 'المزيد من المرشحات',
    RAVKAV_CONFIRM_TXT: 'تم تحميل البطاقة متعددة الخطوط بنجاح',
    MULTILINE_CARD_NUMBER: 'رقم البطاقة متعدد الخطوط',
    CARD_TYPE: 'نوع البطاقة',
    CARD_LOADING: 'تحميل البطاقة',
    FINISHED_AND_CLOSED: 'منتهية ومغلقة',
    BUS_CARD: 'بطاقة الحافلة',
    UPGRADE_TO: 'ترقية الى',
    MULTILINE_TRAVEL_CONTRACTS: 'عقود سفر متعددة الخطوط',
    EXPIRED: 'منتهية الصلاحية',
    CLAIM: 'عشرة',
    PAYMENT_INSTANT_INVOICE: 'دفع فاتورة فورية',
    ID_NUMBER: 'رقم الهوية',
    INVOICES: 'فواتير',
    AMOUNT_OF_INVOICES: 'مبلغ الفواتير',
    CHECK: 'راجع',
    INTERNET_SUBSCRIPTION_NUMBER: 'رقم الاشتراك على الإنترنت',
    ID_OWNER_HP: 'رقم هوية صاحب الخط / هـ.',
    BEZEQ_TXT1: 'وفقًا لسجلات بيزك ، لم يتم دفع الفواتير الواردة أدناه',
    BEZEQ_TXT2: 'يمكنك إلغاء تحديد الفواتير التي ترغب في دفعها في وقت آخر',
    DEADLINE_FOR_PAYMENT: 'الموعد النهائي للدفع',
    INVOICING: 'الفواتير',
    TOTAL_SELECTED_INVOICES: 'إجمالي الفواتير المختارة',
    TOTAL_PAYMENT: 'المبلغ الإجمالي',
    BEZEQ_TXT3: '* هناك رسوم 7 شيكل لكل فاتورة بيزك',
    BEZEQ_FOOTER_TXT: 'بمجرد أن يتم الدفع لا يمكن إلغاء المعاملة!',
    PAYMENT_POLICE_REPORTS: 'دفع محاضر الشرطة',
    PAYER_CUSTOMER_NUMBER: 'رقم الدافع / العميل',
    REPORT_NUMBER: 'رقم التقرير',
    POLICE_REPORT_NUMBER: 'رقم محضر الشرطة',
    POLICE_REPORT: 'تقرير الشرطة',
    ID: 'هوية شخصية',
    COMPANY: 'شركة',
    PASSPORT: 'جواز سفر',
    DRIVING_LICENSE: 'رخصة سياقة',
    POLICE_DATABASE_TXT:
      'تم العثور على 3 تقارير مفتوحة للدفع في قاعدة بيانات الشرطة',
    PORTFOLIO_VOUCHER_TAX: 'ضريبة المحفظة المركزية / ضريبة القسيمة في الترتيب',
    DEBT_FILE_FACTOR: 'عامل محفظة الديون',
    DATE_OF_PAYMENT: 'تاريخ السداد',
    FOUNDATION: 'مؤسسة',
    ARREARS_AND_EXPENSES: 'المتأخرات والنفقات',
    NUMBER_SELECTED_REPORT: 'عدد التقارير المختارة',
    TOTAL_SUM_ACCOUNTS: 'المجموع الكلي للحسابات',
    MOBILE_PHONE_RECEPTION: 'رقم الهاتف المحمول للاستقبال',
    POLICE_FOOTER_TXT1: 'انتبه ! العميل على علم',
    POLICE_FOOTER_TXT2:
      'أنه إذا كان هناك دين من نوع التعويض لضحية جريمة في دعوى جنائية ، فسيتم أولاً إضافة أي مبلغ يتم دفعه لصالح تغطية هذا الدين',
    PURCHASE_POWER_CODE_TXT: 'قم بشراء كود شحن الطاقة',
    PAY_ATTENTION_TXT:
      'انتبهوا!! وفقًا لإرشادات اللجنة الكهروتقنية الدولية ، لا ينبغي تحصيل رسوم من العميل',
    COMMISSION_DES_TXT:
      '** رسوم هذه الدفعة في النظام هي [commission_amount] شيكل',
    CUSTOMERS_NAME: 'اسم العميل',
    PURCHASE_AMOUNT: 'مبلغ الشراء',
    TOTAL_NET_EXCLUDING_VAT:
      'إجمالي صافي المبلغ (باستثناء ضريبة القيمة المضافة)',
    KWH: 'كيلوواط ساعة',
    PAYMENT_DEBTS_TXT: 'مبلغ سداد الديون في جدول السداد',
    TOTAL_CHARGES_ADDITIONAL_CREDITS: 'إجمالي الرسوم - اعتمادات إضافية',
    BUSINESS_ADDRESS: 'عنوان العمل',
    LOADING_CODE: 'كود التحميل',
    MUNICIPAL_TXT1: 'الدفع للسلطات - ضريبة الأملاك بئر السبع',
    MUNICIPAL_TXT2: 'الدفع للسلطات - تم تنفيذ ضريبة الأملاك في بئر السبع بنجاح',
    PAYER_CUSTOMER_ID: 'جهة الدفع / العميل / رقم الهوية',
    CLEARANCE_VOUCHER_APPENDIX_NUMBER: 'رقم المخالصة / القسيمة / الملحق',
    PAYMENT_TO_AUTHORITIES: 'الدفع للسلطات',
    SELECT_PERMISSION: 'حدد الإذن',
    TYPE_PAYMENT_AUTHORITY: 'نوع الدفع في الهيئة',
    PAYMENT_6CROSSES_NORTH: 'الدفع 6 يعبر الشمال',
    PAYMENT6_SUCCESS_TXT: 'تم الدفع 6 North Cross بنجاح',
    REQUESTED_PRICE: 'السعر المطلوب',
    COMMISSION: 'عمولة',
    TOTAL_PRICE: 'السعر الكلي',
    LICENSE: 'رخصه',
    SELECT_ALL: 'اختر الكل',
    ROAD6_COMMISSION_DESCRIPTION:
      'هناك عمولة قدرها [commission_amount_text] لكل فاتورة',
    ROAD6_TOTAL_PRICE_DESCRIPTION:
      '[selected_options_count_text] محدد لإجمالي [selected_options_price] - [total_price_text]',
    PAYMENT_FAILED: "فشل الدفع ، يرجى الاتصال بالدعم أو المحاولة مرة أخرى في وقت لاحق",
    SOMETHING_WENT_WRONG: "حدث خطأ ما ، يرجى الاتصال بالدعم أو المحاولة مرة أخرى لاحقًا",
    INCORRECT_CODE: "شفرة خاطئة",
    PAYMENT: 'دفع',
    PAY: 'دفع',
    PAID: 'المدفوع',
    METER_NUMBER: 'عدد الأمتار',
    APPROVAL_NUMBER: 'رقم التأكيد'
  },
  TOURISM_REPORT: {
    TRANSACTION_DETAILS: 'تفاصيل الصفقه',
    PURCHASE_DATE: 'تاريخ الشراء',
    PAYMENT: 'دفع',
    A_CONFIRMATION: 'تأكيد',
    NO_OF_PASSENGERS: 'عدد الركاب',
    TOTAL: 'مجموع',
    SELLING_RESELLER: 'بائع التجزئة',
    PASSPORT_DETAIL_TXT:
      'يرجى التحقق من تفاصيل المسافر التي تم الحصول عليها ، وتصحيحها / تأكيدها إذا لزم الأمر',
  },
  PAYMENT_REPORT: {
    AMOUNT_RECEIVED: 'تم استلام المبلغ',
    TYPE_OF_PAYMENT: 'طريقة الدفع',
    FOR: 'إلى عن على',
    RECEPTION_NUMBER: 'رقم الاستقبال',
    PAYMENT_REPORT: 'تقرير الدفع',
  },
  SALES_PAMENT_REPORT: {
    SALES_PAMENT_REPORT: 'تقرير المبيعات والمدفوعات',
    TAX_ACCEPTANCE_TRANSACTION: 'ضريبة. معاملة القبول',
    CONSUMER_PRICE: 'سعر المستهلك',
    CURRENT_BALANCE: 'الرصيد الحالي',
  },
  FACILITY: {
    AIRCONDITION: 'تكييف هواء',
    HAIRDRYER: 'مجففة الشعر',
    PHARMACY: 'مقابل',
    BABYSITTING: 'مجالسة الاطفال',
    HAIRDRESSER: 'حلاق',
    PHONEDIRDIAL: 'هاتف Dirdial',
    BAR: 'شريط',
    HANDICAP: 'عائق',
    RESTAURANTS: 'مطاعم',
    BEACH: 'شاطئ بحر',
    HEALTHCLUB: 'نادي صحي',
    ROOMSERVICE: 'خدمة الغرف',
    BEAUTYPARLOUR: 'صالون تجميل',
    ICEMACHINES: 'ماكينات الثلج',
    SAFEDEPBOX: 'صندوق الأمانات',
    BUSINESSCTR: 'نسبة النقر إلى الظهور للأعمال',
    JACUZZI: 'جاكوزي',
    SAUNA: 'ساونا',
    CARRENTAL: 'تاجير سيارة',
    KITCHEN: 'مطبخ',
    SIGHTSEEING: 'معالم المدينة',
    CASINO: 'كازينو',
    LAUNDRYSVC: 'مغسلة SVC',
    SNOWSKIING: 'التزلج على الجليد',
    COFFEESHOP: 'مقهى',
    LOUNGE: 'صالة',
    SOLARIUM: 'حمام شمسي',
    CONVENTIONCTR: 'اتفاقية CTR',
    MASSAGE: 'رسالة',
    SPA: 'منتجع صحي',
    DISCO: 'ديسكو',
    MEETINGROOMS: 'غرف الإجتماعات',
    SWIMMINGPOOL: 'حمام السباحة',
    SWIMMINGPOOLINDOOR: 'مسبح داخلي',
    ELEVATOR: 'مصعد',
    MINIBAR: 'ميني بار',
    TENNIS: 'تنس',
    EXCHANGEFAC: 'تبادل FAC',
    MOVIECHANNELS: 'قنوات الأفلام',
    TELEVISION: 'التلفاز',
    EXECUTIVEFLR: 'تنفيذي FLR',
    NONSMOKINGRMS: 'لغير المدخنين RMS',
    VALETPARKING: 'خدمة صف السيارات',
    FAXFACINROOM: 'غرفة الفاكس',
    PARKING: 'موقف سيارات',
    WATERSPORTS: 'رياضات مائيه',
    GIFTSHOP: 'متجر الهدايا',
    PCHOOKUPINRM: 'ربط الكمبيوتر في RM',
    WOMENSGSTRMS: 'نسائي GSTR MS',
    GOLF: 'الجولف',
    PETSACCEPTED: 'الحيوانات الأليفة مقبولة',
  },
  TOUR_PLACES: {
    RECOMMENDED_TOURIST_SITES: 'المواقع السياحية الموصى بها',
    SHOPPING: 'التسوق',
    FOOD_AND_RESTAURANTS: 'الطعام والمطاعم',
    MUSEUMS: 'المتاحف',
    READ_MORE: 'اقرأ أكثر',
    LOCAL_NAME: 'الاسم المحلي',
    LOCATION: 'موقع',
    MORE_INFO_CONTACT: 'مزيد من المعلومات والاتصال',
    WIKIPEDIA: 'ويكيبيديا',
    OFFICIAL_SITE: 'موقع رسمي',
    ENTRANCE_FEE: 'رسم الدخول',
    COORDINATES: 'إحداثيات',
    REDUCED: 'انخفاض',
    CHILDREN_LABEL: 'الأطفال (أقل من 18 عامًا)',
    FREE: 'مجانا',
  },
  ROOM: {
    REGULAR: 'عادي',
    CLASSIC_ROOM: 'غرفة كلاسيكية',
    DELUXE: 'ديلوكس',
    JUNIOR_SUITE: 'جناح جونيور',
    REGULAR_ROOM_STANDARD: 'غرفة عادية قياسية',
    SEA_VIEW_STANDARD: 'معيار عرض البحر',
    STANDARD_ROOM: 'غرفة عادية',
    APARTMENT_ONE_BEDROOM: 'شقة بغرفة نوم واحدة',
    APARTMENT_TWO_BEDROOMS: 'شقة غرفتين نوم',
    STUDIO: 'ستوديو',
    STANDARD: 'اساسي',
    STANDART: "STANDART",
    FAMILY_ROOM: 'غرفة العائلة',
    DELUXE_ROOM: 'غرفة ديلوكس',
    SUPERIOR_ROOM: 'الغرفة العلوية',
  },
  FARE_BASIS: {
    AI: "AI",
    BB: "BB",
    "F+": "F+",
    FB: "FB",
    HB: "HB",
    "HB+": "HB+",
    KH: "KH",
    KS: "KS",
    RO: "RO",
    UA: "UA",
    UAI: "UAI",
    UI: "UI",
  },
  COMMUNICATION_PRODUCT: {
    CONFIRMATION_PHONE_NUMBER_MODAL_TITLE: 'تأكيد الهاتف المحمول',
    CONFIRMATION_PHONE_NUMBER_MODAL_TEXT:
      'يرجى التأكد من صحة الرقم [phone_number]',
  },
  RESELLER_DETAILS: {
    TEMPORARY_FRAMEWORK: 'إطار عمل مؤقت',
    BALANCE_FOR_UTILIZATION: 'رصيد للاستخدام',
    BALANCE_UPDATE: 'تحديث الرصيد',
    SUM: 'مجموع',
    PAYMENT_METHODS: 'طرق الدفع',
    UPDATE_BALANCE: 'تحديث الرصيد',
  },
  ADS_MANAGEMENT: {
    BANNER_MANAGEMENT: 'إدارة البانر',
    BANNERS: 'لافتات',
    ADVERTISER: 'معلن',
    BANNER_STATUS: 'حالة البانر',
    TYPE: 'نوع',
    THERE: 'اسم',
    LANGUAGE: 'لغة',
    LENGTH: 'الطول',
    A_BEGINNING: 'البداية',
    A_END: 'أ. النهاية',
    HOURS: 'ساعات',
    PRESENTATION: 'عرض',
    EDITING: 'تقييم',
    BOUNCE: 'وثب، ارتداد',
    ADVERTISERS: 'المعلنين',
    NEW_BANNER_EDIT: 'شعار جديد / تحرير',
    NAME_PUBLICATION: 'اسم المنشور',
    NUMBER_OF_BOUNCES: 'عدد مرات الارتداد',
    PAGE: 'الصفحة',
    LINK_TO_PAGE: 'ارتباط بالصفحة',
    CHOOSE_POINT_RESELLER: 'اختر موزعي نقاط البيع',
    ADVERTISING_START_TIME: 'وقت بدء الإعلان',
    POST_TIME_PUBLICATION: 'وقت النشر',
    ADVERTISING_HOURS: 'ساعات الإعلان',
    PRICING: 'تسعير',
    PRICING_LIST: 'قائمة التسعير',
    ADVERTISING: 'إعلان',
    PAYMENT_METHOD: 'طريقة الدفع او السداد',
    PREVIEW: 'معاينة',
    THE_SIZE_PUBLICATION_IS: 'حجم المنشور',
    SHOW: 'تبين',
    PAID_UP: 'المدفوع',
    OPEN: 'مفتوح',
    IMAGE: 'صورة',
    VIDEO: 'فيديو',
    BANNERS_ADVERTISERS: 'لافتات - معلنون',
    PHONES: 'الهواتف',
    RESPONSIBLE_ACCOUNTING: 'مسؤول عن المحاسبة',
    ACCOUNTING_TELEPHONE: 'هاتف المحاسبة',
    OPEN_DEBT: 'فتح الديون',
    A_REGISTRATION: 'أ. التسجيل',
    BAIL: 'الكفالة',
    CLOSE_CHARGE: 'إغلاق تهمة',
    DETAIL_EDITING: 'التفاصيل والتحرير',
    NEW_PUBLISHER: 'ناشر جديد',
    BACK_TO_BANNER: 'العودة إلى اللافتات',
    NEW_PUBLISHER_EDITOR: 'ناشر / محرر جديد',
    ADVERTISER_NAME: 'اسم المعلن',
    SITE: 'موقع',
    DATE_OF_ESTABLISHMENT: 'تاريخ التأسيس',
    FROM_GOOGLE: 'من جوجل',
    ACCOUNTANTS: 'محاسبون',
    TELEPHONE_BOOKS: 'كتب الهاتف',
    CREDIT_DETAILS: 'تفاصيل الائتمان',
    ID: 'بطاقة تعريف',
    LIST_ADVERTISEMENTS_PRICES: 'قائمة الإعلانات والأسعار',
    DATE_PLEASE: 'تاريخ من فضلك',
    PAYMENT_STATUS: 'حالة السداد',


  },
  MANAGE_PRODUCT: {
    SELECT_CATEGORY: 'اختر تصنيف',
    SELECT_PROVIDER: 'حدد مزود',
    CHOOSE_PRODUCT: 'اختر منتجًا',
    PRODUCT_NAME_HEBREW: 'اسم المنتج: عبري',
    PRODUCT_NAME_ENGLISH: 'اسم المنتج: انجليزي',
    PRODUCT_NAME_ARABIC: 'اسم المنتج: عربي',
    PRODUCT_DESCRIPTION_HEBREW: "وصف المنتج: عبري",
    PRODUCT_DESCRIPTION_ENGLISH: "وصف المنتج: انجليزي",
    PRODUCT_DESCRIPTION_ARABIC: "وصف المنتج: عربي",
    TRANSLATION_ADDITIONAL_LANGUAGES: 'ترجمة لغات إضافية',
    PRODUCT_DETAILS: 'تفاصيل المنتج',
    MINUTES_OF_CONVERSATION: 'محضر محادثة',
    SMS_MESSAGES: 'رسائل SMS',
    MOBILE_BROWSING: 'تصفح المحمول',
    GENERAL_BUDGET: 'الميزانية العامة',
    AUTHORIZED_FOR_REVOCATION: 'مصرح بالإلغاء',
    DEFAULT_IN_SETTING_RESELLER: 'الافتراضي في إعداد موزع',
    PRODUCT_WITHOUT_VAT: 'منتج بدون ضريبة القيمة المضافة',
    PRODUCT_SETTINGS: 'إعدادات المنتج',
    ID_AT_VENDOR: 'المعرف لدى البائع',
    ICOUNT_PRODUCT_NUMBER: 'رقم منتج iCount بتنسيق',
    PROFIT_MODEL: 'نموذج الربح',
    DISTRIBUTION_FEE: 'رسوم التوزيع',
    PURCHASE_PRICE: 'سعر الشراء',
    CURRENCY: 'عملة',
    MINUTES_AVAILABLE: 'الدقائق المتاحة',
    INTERNET: 'انترنت',
    UNLIMITED: 'غير محدود',
    DEFINE_VIEW_ADDITIONAL_DETAIL: 'تحديد وعرض تفاصيل إضافية',
    ADDITIONAL_DETAILS: 'تفاصيل إضافية',
    DEFAULT_LANGUAGE: 'اللغة الافتراضية'
  },
  MANAGE_SHANIV: {
    DUTY_FREE: 'معفى من الجمارك',
    SHANIV_ID: 'معرف شانيف',
    DESCRIPTION: 'وصف المنتج',
    EXTPART_PRODUCT_CODE: 'EXTPART كود المنتج',
    PARTNAME: 'PARTNAME',
    BARCODE_BARCODE: 'BARCODE كود المنتج',
    PRICE_PRICE: 'PRICE السعر',
    PRICE_INCLUDING_VAT: 'السعر بما في ذلك ضريبة القيمة المضافة',
    PRODUCT_DESCRIPTION_HE: 'وصف المنتج: العبرية',
    PRODUCT_DESCRIPTION_EN: 'وصف المنتج: اللغة الإنجليزية',
    PRODUCT_DESCRIPTION_AR: 'وصف المنتج: عربي',
    CATEGORY: 'فئة',
    SUBCATEGORY: 'تصنيف فرعي',
    CATEGORY_NUMBER: 'رقم الفئة',
    MATERIAL: 'مادة',
    BRAND: 'علامة تجارية',
    PARKING: 'التعبئة',
    SELECT_IMAGE: 'اختر صورة',
    NAPKINS: 'المناديل',
    TOWELS: 'مناشف',
    HANDKERCHIEFS: 'مناديل',
    TOILET: 'الحمام',
    SHANIV: 'شنيف',
    SASATK: 'ساساتك',
    NUMBER_UNITS_PACKAGE: 'عدد الوحدات في العبوة',
    NUMBER_UNITS_PER_SURFACE: 'عدد الوحدات لكل سطح',
    SKU: 'SKU',
    BARCODE: 'الرمز الشريطي',
    ADD_TO_BASKET: 'أضف إلى السلة',
    ADD_BOTH_ITEMS_TO_CART: 'أضف كلا العنصرين إلى عربة التسوق',
    PRICE_PER_UNIT: 'السعر لكل وحدة',
    DISCOUNT_PURCHASE_SURFACE: 'خصم بنسبة {num}٪ على شراء السطح',
    RECOMMENDED_RETAIL_PRICE: 'سعر التجزئة الموصى به',
    PACKAGE_OFFER: 'عرض الحزمة',
    SAVE: 'حفظ',
    ADDED_TO_PACKAGE: 'تمت الإضافة إلى الحزمة',
    ONLY: 'فقط',
    PRODUCTS_PURCHASED_TOGETHER_OFTEN:
      'يتم شراء المنتجات معًا في كثير من الأحيان',
    ADD_SELECTED_ITEMS_TO_CART: 'أضف العناصر المحددة إلى عربة التسوق',
    CUSTOMERS_ALSO_SAW: 'كما رأى العملاء',
    SURFACE: 'سطح',
    PRODUCT_PROMOTIONS: 'ترقيات المنتج',
    OPERATION_NUMBER: 'رقم العملية',
    ITEM: 'بند',
    ITEM1: 'البند 1',
    ITEM_QUANTITY1: 'كمية العنصر 1',
    ITEM2: 'البند 2',
    ITEM_QUANTITY2: 'كمية العنصر 2',
    ITEM3: 'البند 3',
    ITEM_QUANTITY3: 'كمية العنصر 3',
    NORMAL_PRICE: 'السعر العادي',
    SALES_PRICE: 'سعر البيع',
    OPERATION_DATE: 'تاريخ العملية',
    OPERATION_IMAGE: 'صورة العملية',
    OPERATION_NAME: 'اسم العملية',
    TYPE_OF_OPERATION: 'نوع العملية',
    ADD_PRODUCTS_FROM_LIST: 'أضف منتجات من القائمة',
    VOID: 'باطل',
    VALID_UNTIL: 'صالح حتى',
    START_TIME: 'وقت البدء',
    END_TIME: 'وقت النهاية',
    PRICE_FROM_LOW_TO_HIGH: 'السعر من الأقل إلى الأعلى',
    PRICE_LISTS: 'قائمة الأسعار',
    USER_MANUAL: 'دليل الاستخدام',
    AMOUNT_TO_ORDER: 'المبلغ المطلوب',
    ADD_TO_CART_PROMOTION: 'أضف إلى سلة التسوق الترويج',
    AGENT: 'وكيل',
    AGENT_NAME: 'اسم العميل',
    AGENT_PHONE: 'رقم هاتف الوكيل',
    SET_PACKAGE_QUANTITY_MANUALLY: 'قم بتعيين كمية العبوة',
    MANUAL_PACKAGE_QUANTITY: 'كمية العبوة',
    SET_PALLET_QUANTITY_MANUALLY: 'اضبط كمية الأسطح',
    MANUAL_PALLET_QUANTITY: 'كمية المرتبة',
    IMPORT_SASTECH_QUANTITY_EXCEL: 'كمية استيراد Sastech',
    IMPORT_FILE: 'استيراد ملف',
    IMPORT: 'تأتي',
  },
  BUSINESS_DETAILS: {
    BUSINESS_DETAILS: 'تفاصيل العمل',
    PLAFAID_RESELLER: 'موزع Plafaid',
    SHANIV_MARKETER: 'مسوق شانيف',
    SHENIV_CUSTOMER_TAX: 'ضريبة العملاء Sheniv',
    FREEZE: 'تجميد',
    UNFREEZE: 'ذوبان',
    SHOE: 'حذاء',
    LOCK: 'قفل',
    UNLOCK: 'الغاء القفل',
    SEARCH_SHANIV_CUSTOMERS: 'ابحث عن عملاء Shaniv',
    CUSTOMER_SEARCH: 'بحث العملاء',
    MULTILINE_POSITION: 'موقف متعدد الخطوط',
    SHANIV_PLNAME: 'PLNAME ({company})',
    SHANIV_CUSTNAME: 'CUSTNAME ({company})',
    YOU_WANT_DELETE_BUSINESS: 'هل تريد حذف هذا العمل ({business})؟',
  },
  CUSTOMER_LISTS: {
    CUSTOMER_LISTS: 'قوائم العملاء',
    TAX_CLIENT: 'ضريبة. زبون',
    SECTOR: 'قطاع',
    AREA_CODE: 'رمز المنطقة',
    REGION_NAME: 'اسم المنطقة',
    AUTHORIZED_DEALER_HP: 'تاجر معتمد / H.P.',
    AGENT_CODE: 'رمز العميل',
    AGENT_NAME: 'اسم العميل',
    OBLIGO: 'Obligo',
    PLATOK_CUSTOMER: 'عميل Platok',
    EXPLAND_FULL_TABLE: 'قم بالتوسيع إلى الجدول الكامل',
    UPLOADING_NEW_LIST: 'تحميل قائمة جديدة',
    BECOME_CUSTOMER: 'كن عميلاً',
    LIST_NAME: 'اسم القائمة',
    EXISTING_CUSTOMER_FLAPAID: 'عميل حالي في FlaPaid',
    FLAPPY_CUSTOMER_TAX: 'ضريبة العملاء القاذفة',
    CHECK_ALL: 'تحقق من الكل',
    CREATE_USER: 'إنشاء مستخدم',
    PAYDES: 'PAYDES'
  },
  SASTECH: {
    ADDED_TO_CART: 'تمت الإضافة إلى عربة التسوق',
    TO_BOX_OFFICE: 'إلى شباك التذاكر',
    OUT_OF_ITEMS: '{num1} من {num2} عنصر',
    TOTAL_ADDED_TXT: 'تمت إضافة الإجمالي إلى عربة التسوق',
    CONTENT: 'قدره',
    TOTAL_IN_CART: 'الإجمالي في سلة التسوق',
    HOT_SALES: 'مبيعات ساخنة',
    COMPLEMENT: 'تكملة',
    INSTITUTIONAL: 'مؤسسية',
    PAPER_PRODUCTS: 'المنتجات الورقية والورقية',
    DISH_WASHING: 'غسيل الاطباق',
    BLEACH: 'تبييض',
    DISINFECTION_CLEANING: 'التطهير والتنظيف',
    FLOOR_CLEANING: 'تنظيف الأرضيات',
    OPERATES_PACKAGES: 'تعمل الحزم',
    CHOOSE_COMPANY: 'اختر شركة',
    BEFORE_DISCOUNT: "قبل الخصم",
    ALL_PRODUCTS: "جميع المنتجات",
    PLEASE_SELECT_QUANTITY: 'الرجاء تحديد الكمية',
    SHOPPING_CART: 'عربة التسوق',
    DELIVERY_DATE: 'تاريخ التسليم',
    PAYMENT_METHOD: 'طريقة الدفع او السداد',
    PROMOTIONS: 'الترقيات والفوائد',
    MAKE_RESERVATION: 'احجز',
    PACKAGES: 'الحزم',
    ADD_PRODUCTS: 'أضف المنتجات',
    ADD_PACKAGES: 'أضف الحزم',
    PACKAGE_QUANTITY: 'حزمة الكمية',
    BARCODE: 'الباركود',
    UNIT_PRICE: 'سعر الوحده',
    PACKAGE_PRICE: 'سعر العبوة',
    ITEMS: 'العناصر',
    FRAMEWORK_EXPLOITATION_SASSATECH: 'إطار للاستغلال في ساساتك',
    MINIMUM_ORDER: 'الحد الأدنى للطلب',
    PRODUCTS: 'منتجات',
    CLEAR_FILTERS: 'مسح عوامل التصفية',
    SORT_DEFAULT: 'الترتيب الافتراضي',
    SORT_PRICE: 'سعر:',
    SORT_DIRECTION_ASC: 'من أسفل إلى أعلى',
    SORT_DIRECTION_DESC: 'من الأعلى إلى الأقل',
    PAPER_PAPER_PRODUCTS: 'منتجات الورق والورق',
    SPRAYS: 'البخاخات',
    LAUNDRY: 'غسيل ملابس',
    CULTIVATION: 'تحسين',
    ONE_TIME: 'مره واحده',
    TOILET_BATHROOMS: 'المراحيض والحمامات',
    APPLY_FILTER: 'تطبيق عامل التصفية',
    CLEAR: 'واضح',


  },
  MANAGEMENT: {
    TIMING_VAT_CHANGE: 'توقيت تغيير ضريبة القيمة المضافة',
    NEW_VAT: 'ضريبة القيمة المضافة الجديدة',
    MAXIMUM_PROFIT_COMMISSION_PERCENTAGE: 'الحد الأقصى لنسبة الربح / العمولة',
    COMMISSION_LEVEL: 'مستوى العمولة',
    POINTS: 'نقاط',
    MAXIMUM_AMOUNT_ALLOWED: 'الحد الأقصى للمبلغ المسموح به',
    CREDIT_PAYMENT_LIMIT: 'حد دفع الائتمان',
    PREPAID_PROFIT_PERCENTAGE: 'نسب الأرباح المدفوعة مقدمًا',
    CUSTOMER_TYPE: 'نوع العميل',
    PROFIT_PRO: '٪ ربح',
    APPLY_PROFIT_TO_RESELLERS: 'تطبيق ربح ٪ على جميع الموزعين',
    REMOVE_PROFIT_ON_DISTRIBUTORS:
      'إزالة النسبة المئوية للربح من جميع الموزعين',
    APPLY_GIFT_TO_MARKETERS: 'تطبيق نقاط الهدايا على جميع المسوقين',
    REMOVE_GIFT_ON_DISTRIBUTORS: 'إزالة نقاط الهدايا من جميع الموزعين',
    ACCOUNT_FEES_PROFIT_COMMISSIONS: 'عمولات أرباح رسوم الحساب',
    COMMISSIONER_DISTRIBUTOR_COMMISSION: 'مفوض / عمولة الموزع',
    CONSUMER_COMMISSION: 'عمولة المستهلك',
    APPLY_PROFIT_COMMISSION_TO_MARKETERS: 'تطبيق عمولة ربح على جميع المسوقين',
    REMOVE_PROFIT_MARGIN_ON_DISTRIBUTORS: 'إزالة هامش الربح على جميع الموزعين',
    APPLY_CONSUMER_FEE_TO_RESELLERS: 'تطبيق رسوم المستهلك على جميع الموزعين',
    REMOVE_CONSUMER_FEE_ON_DISTRIBUTORS: 'إزالة رسوم المستهلك من جميع الموزعين',
    CONSUMER_COMMISSION_LEVEL: 'مستوى عمولة المستهلك',
    COPY_PROFIT_PERCENTAGE_TO_PRODUCT: 'نسخ نسبة الربح للمنتج',
    ORIGINAL_PRODUCT: 'المنتج الأصلي',
    TARGET_PRODUCT: 'المنتج المستهدف',
    COPY_VENDOR_PERMISSIONS: 'نسخ أذونات البائع',
    SOURCE_PROVIDER: 'توفير المصدر',
    TARGET_PROVIDER: 'مزود الهدف',
    COPY_RESELLER_PROFILE: 'نسخ الملف التعريفي للموزع',
    SOURCE_MARKETER: 'المصدر المسوق',
    TARGET_MARKETER: 'المسوق المستهدف',
    TAG_MANAGEMENT: 'إدارة العلامات',
    TAG_TYPE: 'نوع العلامة',
    TAG_NAME: 'اسم العلامة',
    FILTER_NAME: 'اسم المرشح',
    APPEARS_ON_PRODUCT: 'يظهر على المنتج',
    DELETION: 'حذف',
    CREATE_NEW_TYPE: 'قم بإنشاء نوع جديد',
    CREATE_NEW_TAG: 'قم بإنشاء علامة جديدة',
    CREATE_EDIT_CATEGORY: 'إنشاء أو تغيير فئة',
    CATEGORY_NAME: 'اسم التصنيف',
    BACKGROUND_COLOR_PICKER: 'فئة لون الخلفية',
    CATEGORY_TEXT_COLOR: 'لون نص الفئة',
    CREATE_CHANGE_TAG: 'إنشاء أو تغيير علامة',
    EXTRA_CONVERTIBLE_PENNIES: 'بنسات إضافية قابلة للتحويل',
    SUPPLIER_NAME: 'اسم المورد',
    SUPPLIER_TYPE: 'نوع المورد',
    ANOTHER_PHONE: 'هاتف آخر',
    SUPPLIER_LOGO: 'شعار المورد',
    FONT_COLOR: 'لون الخط',
    LOGO_BACKGROUND: 'خلفية الشعار',
    PRODUCT_CARD_BACKGROUND: 'خلفية بطاقة المنتج',
    SELECTED_PRODUCT_CARD_BG: 'خلفية بطاقة المنتج المحدد',
    OPERATING_PRODUCT_FONT: 'خط منتج التشغيل',
    BACKGROUND_PRODUCT_CARD_SALE: 'بيع بطاقة المنتج الخلفية',
    MAX_MARKET_PROFIT_COMMISSION: 'الحد الأقصى لربح / عمولة المسوق٪',
    MIN_ADDITIONAL_COMMISSION_CONSUMER: 'الحد الأدنى للعمولة الإضافية للمستهلك',
    MAX_AMOUNT_ALLOWED_PAYMENT: 'الحد الأقصى للمبلغ المسموح به للدفع',
    LIMIT_AUTO_PAYMENT_CREDIT: 'الحد من الدفع التلقائي على الائتمان',
    CONSUMER_COMMISSION_STAIRS: 'سلالم عمولة المستهلك',
    CREDIT_CARD_PROVIDE: 'بطاقة ائتمان لتقديمها',
    HOW_TO_CANCEL: 'كيف تلغي',
    EMAIL_TO_CANCEL: 'بريد إلكتروني للإلغاء',
    DARK: 'مظلم',
    BRIGHT: 'مشرق',
    SELECT_PROFILE: 'حدد ملف تعريف',
    NEW_PROFILE: 'الملف الشخصي الجديد',
    EDIT_PROFILE: 'تعديل الملف الشخصي',
    APPROVED_FOR_USE: 'معتمد للاستخدام من أجل',
    DIRECTOR: 'مدير',  
    TV: 'تلفاز',
    USING_DISTRIBUTION_FEE: 'باستخدام رسوم التوزيع',
    AS_DEFAULT_PROFILE: 'كملف تعريف افتراضي',
    TYPE_OF_PRODUCT: 'نوع المنتج',
    APPLY_PROFIT_ON_ALL_PRODUCTS: 'تطبيق ربح ٪ على جميع المنتجات',
    APPLY_GIFT_POINTS_TO_ALL_PRODUCTS: 'تطبيق نقاط الهدايا على جميع المنتجات',
    CARD_TYPE: 'نوع البطاقة',
    PERCENTAGE_PROFIT: 'نسبة الربح',
    A_DISTRIBUTION: 'أ. التوزيع',
    ENABLED: 'ممكن',
    PROFIT_COMMISSION_TO_MARKETER: 'عمولة الربح للمسوق',
    EXTRA_CHARGE_TO_CONSUMER: 'رسوم إضافية للمستهلك',
    APPLY_PROFIT_COMMISSION_TO_PRODUCTS: 'تطبيق عمولة ربح على جميع المنتجات',
    APPLY_ADDITIONAL_PRICE_TO_CONSUMER: 'تطبيق سعر إضافي على المستهلك',
    ADDITIONAL_TO_CONSUMER: 'إضافة إلى المستهلك',
    COMMISSIONERS_COMMISSION: 'عمولة المفوض',
    TOURISM_PROFILE: 'لمحة عن السياحة',
    MIN_FRONTAL_REPRESENTATIVE_FEE: 'الحد الأدنى لرسوم الممثل الأمامي',
    SKUS: 'وحدات SKU',
    NAME_OF_SKU: 'اسم SKU',
    REQUIRED: 'واجب',
    SELECT_SKU: 'حدد SKU',
    OFF: 'غير فعال',
    OPTIONAL: 'خياري',
    ENTER_NEW_PART_NUMBER_THERE: 'أدخل رقم جزء جديد هناك',
    MANUAL_TICKETS: 'تذاكر يدوية',
    ACTIVE_CARDS: 'البطاقات النشطة',
    TICKETS_USED: 'التذاكر المستخدمة',
    TICKETS_ABOUT_EXPIRE: 'التذاكر التي على وشك الانتهاء',
    EXPIRED_UNUSED_CARDS: 'البطاقات منتهية الصلاحية وغير المستخدمة',
    DATE_OF_INCOME: 'تاريخ الدخل',
    INSERTED_BY: 'تم إدراجها بواسطة',
    DATE_TIME_OF_INCOME: 'تاريخ ووقت الدخل',
    EXPIRY_DATE: 'تاريخ انتهاء الصلاحية',
    USED: 'مستخدم',
    CARD_WAS_CREATED_SUCCESSFULLY: 'تم إنشاء البطاقة بنجاح',
    GIFTS: 'هدايا',
    GIFT_CATEGORY: 'فئة الهدية',
    GIFT_LIST: 'قائمة الهدايا',
    NAME_OF_GIFT: 'اسم الهدية',
    POINTS_TO_BE_UTILIZED: 'النقاط التي سيتم استخدامها',
    PRODUCT_IMAGE: 'صورة المنتج',
    DETAIL_ON_WAITING: 'تفاصيل حول الانتظار',
    ACTIVE_GIFT: 'هدية فعالة',
    INVOICE_MANAGEMENT: 'إدارة الفواتير',
    AUTHORIZED_PROVIDER_TO_PROVIDER: 'يصرح مزود لمزود',
    PROFIT_COMMISSIONS: 'عمولات الربح',
    NEW_MANUAL_TICKETS: 'تذاكر يدوية جديدة',
    GENERAL_SETTINGS: 'الاعدادات العامة',
    MILES: 'بريد',
    BASE: 'يتمركز',
    TEMPORARY_ADDITION: 'إضافة مؤقتة',
    DATE_TEMPORARY_ADDITION: 'تاريخ الإضافة المؤقتة',
    VACATION: 'اجازة',
    ORGANIZED: 'منظم',
    CRUZ: 'كروز',
    ROLE: 'وظيفة',
    SECURITY_DEPOSIT: 'الوديعه',
    THERE_IS: 'يوجد',
    TOURISM_PROVIDER: 'مزود السياحة',
    PACKAGE_REFRESH_TIME_DAYS_AHEAD: 'وقت تحديث الحزمة {num} يوم قادم',
    REFRESH_TIME_FORWARD_EACH_DAY: 'وقت التحديث {num} يوم للأمام كل يوم',
    AT: 'في',
    DAYS: 'ايام',
    EXCEPT_FOR_REFRESH_DAY: 'ماعدا في يوم التحديث {NUM} يوم',
    SETTING: 'ضبط',
    API_SETTING: 'API إعداد',
    APPROVED_SUPPLIERS: 'الموردين المعتمدين',
    PAYMENT_FEES: 'رسوم السداد',
    USERS: 'المستخدمون',
    NUMBER: 'رقم',
    USERNAME: 'اسم االمستخدم',
    CELLPHONE_NUMBER: 'رقم الهاتف المحمول',
    OWNER: 'صاحب',
    ADD_USER: 'إضافة مستخدم',
    NEW_USER: 'مستخدم جديد',
    PERMISSIONS: 'أذونات',
    SEVERAL_SECURE_DEVICES: 'عدة أجهزة آمنة',
    IP_NUMBER_ADDRESSES: 'عدد عناوين IP',
    IP_ADDRESS: 'عنوان IP',
    FRIENDLY_NAME: 'اسم مألوف',
    ACTIONS: 'أجراءات',
    PASSWORD_RESET: 'إعادة تعيين كلمة المرور',
    CHANGE_PASSWORD: 'غير كلمة السر',
    UNDO_FREEZE: 'التراجع عن التجميد',
    DESTINATION_DESCRIPTION: 'وصف الوجهة',
    HELP: 'مساعده',
    FONT_LOGO_SUPPLIER: 'مورد شعار الخط',
    PRODUCT_FONT: 'خط المنتج',
    SELECTED_PREFERRED_PRODUCT_FONT: 'خط المنتج المحدد / المفضل',
    DOUBT_BACKGROUND: 'شك الخلفية',
    PRODUCT_BACKGROUND: 'خلفية المنتج',
    SELECTED_PREFERRED_PRODUCT_BACKGROUND: 'خلفية المنتج المختار / المفضل',
    BRIGHT_LOGO_FONT: 'خط شعار مشرق',
    BRIGHT_LOGO_BACKGROUND: 'مشرق شعار الخلفية',
    STAR_COLOR_FAVORITES: 'لون النجوم المفضل',
    SIDE_LOGO_BACKGROUND: 'الجانب شعار الخلفية',
    TERMS_OF_CALLS: 'شروط المكالمات',
    MANUAL_PRICE: 'السعر اليدوي',
    ALTERNATIVE_PRODUCT_RECOMMENDED: 'منتج بديل (موصى به)',
    PRODUCT_TYPE: 'نوع المنتج',
    VIRTUAL: 'افتراضية',
    MANUAL: 'يدوي',
    BILLS_PAYMENT: 'دفع الفواتير',
    SUCURE_IP: 'IP آمن',
    CHANGE_USER_PASSWORD_TXT: 'التغيير إلى كلمة مرور مستخدم جديدة {UNAME} للأعمال {BNAME}',
    ENTER_NEW_PASSWORD: 'أدخل كلمة مرور جديدة',
    PASSWORD_AUTHENTICATION: 'مصادقة كلمة المرور',
    CHANGE_PASSWORD_DESC: 'في نهاية التغيير ، سيتم إرسال كلمة المرور الجديدة إلى المستخدم في الرسالة ، إلى الرقم {MNUM} وسيتم تحديث صاحب العمل في الرسالة حول التغيير في كلمة المرور ، إلى الرقم {MNUM}',
    RESET_PASSWORD_DESC: 'سيؤدي هذا الإجراء إلى إنشاء كلمة مرور جديدة لـ {UNAME} للأعمال {BNAME}. سيتم إرسال البطاقة الجديدة عن طريق الرسائل القصيرة إلى هذا الرقم {MNUM}. سيتم إخطار صاحب العمل بتغيير كلمة المرور على هذا الرقم {OMNUM}.',
    LOGIN_HISTORY: 'سجل تسجيل الدخول',
    PAYMENTS_CANCELLATION: 'إلغاء المدفوعات',
    SYNC_NOW: 'زامن الآن "لنقل البيانات',
    SYNC_STARTED: "بدأت المزامنة",
    SHANIV_CUSTOMER_NUMBER: 'رقم عميل شنيف',
    SASA_CUSTOMER_NUMBER: 'رقم عميل ساسا',
    NEW_BUSINESS: 'عمل جديد',
    PRODUCTS: "منتجات",
    PROFILE_NAME: "اسم الشخصية",
    PRODUCT_ACTION_WARNING_TXT: "سيؤدي هذا الإجراء إلى تغييرات في جميع المنتجات ، بما في ذلك تلك التي لا تظهر بعد الترشيح! هل أنت متأكد أنك تريد الاستمرار؟",
    PERMISSIONS_PROFILE: "ملف تعريف الأذونات",
    CAN_ASSIGNED: "يمكن تعيينها",
    IMPORT_PERMISSIONS: "أذونات الاستيراد",
    PERMITTED_TO: "مسموح ل",
    ROLE_NAME: "اسم الدور",
    GIFTS_NEW: "الهدايا - جديد",
    AS_SHANIV_DEFAULT_PROFILE: "بشكل افتراضي Shaniv",
    ADD_VAT: 'أضف ضريبة القيمة المضافة',
    PRODUCTS_SOURCE: 'مصدر المنتجات',
    POINTS_USER: 'مستخدم النقاط',
    LOCK: 'قفل',
    CANCELLATION_FREEZE: 'تجميد الإلغاء',
    UNLOCK: 'الغاء القفل',
    LINUX: 'لينكس',
    WINDOWS: 'شبابيك',


  },
  CANCELLATION: {
    CANCELLATION_SYSTEM: 'نظام الإلغاء',
    DEAL_NUMBER: 'رقم الصفقة',
    DATE_TIME_OF_LOADING: 'تاريخ ووقت التحميل',
    DATE_TIME_OF_APPLICATION: 'تاريخ ووقت تقديم الطلب',
    AMOUNT_CANCELED: 'المبلغ ملغى',
    ALL_RESELLERS: 'جميع الموزعين',
    CHARGING_DATE: 'تاريخ الشحن',
    CANCELLATION_DATE: 'تاريخ الإلغاء',
    SHOW_CANCELLATIONS_IN_SYSTEM_ONLY: 'إظهار الإلغاءات في النظام فقط',
    SHOW_OPEN_CANCELLATIONS_ONLY: 'إظهار الإلغاءات المفتوحة فقط',
    CANCEL_FREE_CHARGE_LOADING: 'إلغاء الشحن المجاني',
    LOADING_DETAILS: 'تفاصيل التحميل',
    CANCELLATION_IN_SYSTEM: 'الإلغاء في النظام',
    AMOUNT_TO_BE_CANCELED: 'المبلغ المطلوب إلغاؤه',
    CANCELLATION_SUCCESSFUL_TXT: 'تم الإلغاء بنجاح',
    PARTIALLY_SUCCESSFUL_TXT: 'كان الإلغاء ناجحًا جزئيًا',
    CANCELLATION_FAILED: 'فشل الإلغاء',
    CLOSED: 'مغلق',
    CANCELLATION_DETAILS: 'تفاصيل الإلغاء',
    HOWTO_REQUEST_CANCELLATION: 'كيفية طلب الإلغاء',
    DATE_CANCELLATION1: 'تاريخ ووقت الإلغاء',
    DATE_CANCELLATION2: 'تاريخ ووقت الإلغاء',
    CANCELLATION_OPERATION: 'عملية الإلغاء',
    SUPPLIER_TRANSACTION_NUMBER: 'رقم معاملة المورد',
    VENDOR_CONFIRMATION_NUMBER: 'رقم تأكيد البائع',
    CANCELLATION_TXT: 'انتبهوا!! سيتم هذا الإلغاء على Platok فقط وليس أمام المورد *',
    WHICH_CANCELLATION: 'أي إلغاء',
    PAYMENTS_CANCELLATION: 'إلغاء المدفوعات',
    PREPAID_CANCELLATION: 'إلغاء الدفع المسبق',
    CANCELLATION: 'إلغاء',
    TRANSACTION_NUMBER: 'رقم التحويلة',
    BUSINESS_NUMBER: 'عدد الأعمال',
    CANCELLATION_AMOUNT: 'مبلغ الإلغاء',
    PROVIDER_APPROVAL_NUNBER: 'رقم اعتماد المزود',
    TRANSACTION: 'عملية',
    BUSINESS_ID: 'معرف الأعمال',
    PROVIDER: 'مزود',
    CHARGING_TIME: 'وقت الشحن',
    CELLULAR_NUMBER: 'الرقم الخلوي',
    CANCEL_CONFIRMATION_NUMBER: 'إلغاء رقم التأكيد',
    PARTIAL_CANCEL: 'إلغاء جزئي',
    SALE_TRANSACTION_PRICE: 'سعر صفقة البيع',
    CANCELLATION_NOTE: 'ملاحظة الإلغاء',
    CANCELED_PRICE: 'السعر الملغي',
    CANCELATION_SUCCESSFUL: 'تم الإلغاء بنجاح',
    PARTIAL_CANCELATION_OF_NUM: 'إلغاء جزئي لـ {NUM}',
    CAN_NOT_BE_CANCELED: 'لا يمكن إلغاؤه',
    RAVKAV_CARD: 'بطاقة رافكاف',
    RAVKAV_TRANSACTTION: 'صفقة رافكاف',
    CANCEL_VIA_PROVIDER: 'إلغاء عبر المزود',
    CANCEL_LOCALY: 'إلغاء محليًا',


  },
  COMPONENT: {
    SELECT_NUMBER_NIGHT_RANGE: 'حدد عدد الليالي في النطاق',
    SELECT_DATES: 'حدد التواريخ',
    EXACTLY_SELECTED_DATES: 'التواريخ المحددة بالضبط',
    STAY: 'يقضي',
    NIGHTS: 'ليالي',
    THIS_WEEKEND: 'نهاية هذا الأسبوع',
    NEXT_WEEKEND: 'عطلة نهاية الاسبوع القادمة',
    THIS_WEEK: 'هذا الاسبوع',
    NEXT_WEEK: 'الاسبوع القادم',
    THIS_MONTH: 'هذا الشهر',
    NEXT_MONTH: 'الشهر القادم',
    CUSTOM: 'مخصص'
  },
  CART: {
    CREDIT_LINE_FOR_UTILIZATION: 'حد ائتماني للاستخدام',
    MINIMUM_AMOUNT_PER_ORDER: 'الحد الأدنى للمبلغ لكل طلب {num}',
    MINIMUM_AMOUNT_PER_ORDER_PER_COMPANY: 'الحد الأدنى للمبلغ لكل طلب {num} ({company})',
    ORDER_SUMMARY: 'ملخص الطلب',
    ORDER_VALUE: 'ثمن الطلب',
    SAVINGS: 'توفير',
    ITEMS_IN_SHOPPING_CART: 'العناصر الموجودة في عربة التسوق',
    CLEARANCE_OF_INVENTORY: 'تخليص المخزون',
    REMOVE_ALL_ITEMS: 'قم بإزالة كافة العناصر',
    REPURCHASE: 'إعادة الشراء',
    OPEN_INVITATIONS: 'دعوات مفتوحة',
    PREVIOUS_ORDER: 'الطلبات السابقة',
    SHOPPING_CART: 'عربة التسوق',
    INCLUDING_VAT: 'بما في ذلك ضريبة القيمة المضافة',
    PACKAGE_QUANTITY: 'الكمية في العبوة',
    FULL_PRICE: 'السعر الكامل',
    DAY_ORDER_ARRIVES:'يوم وصول الطلب',
    SAVE_COMPLETE_ORDER_LATER: 'احفظ وأكمل طلبًا لاحقًا',
    ORDER_HISTORY: 'تاريخ الطلب',
    ORDER_DATE: 'تاريخ الطلب',
    DELIVERY_DATE: 'تاريخ التسليم او الوصول',
    QUANTITY_OF_ITEMS_ORDERED: 'كمية العناصر المطلوبة',
    ORDER_AMOUNT: 'كمية الطلب',
    QUANTITY_OF_ITEMS_PROVIDED: 'كمية العناصر المقدمة',
    FINAL_AMOUNT: 'القيمة النهائية',
    BOOK_AGAIN: 'احجز مرة أخرى',
    DETAILS: 'تفاصيل',
    MOVE_TO_CART: 'انتقل الكرت',
    REMOVE: 'إزالة',
    ORDER_NUMBER: 'رقم الأمر',
    DONE_SUCCESSFULLY: 'فعلت بنجاح',
    AMOUNT_OF_ITEMS: 'كمية العناصر',
    MOVE_CART_WARNING_TXT: 'ستفقد المجموعة الحالية من عناصر سلة التسوق. حفظ عربتك الحالية كطلب مؤقت؟',
    ASIDE_SHOPPING_CART: 'عربة التسوق',
    SOME_PRODUCTS_NOT_ORDERED: 'يرجى ملاحظة أن بعض المنتجات لم يتم طلبها!',
    SOME_PRODUCTS_OUT_OF_STOCK: 'يرجى ملاحظة أن بعض المنتجات نفذت من المخزن!',
    YOU_ARE_NOT_A_CUSTOMER_OF_COMPANY: 'The order cannot be placed - you are not a customer of {company}. Please contact the office for more details - * 5409',
    SEARCH_A_PRODUCT: 'ابحث عن منتج',
    ITEMS_IN_YOUR_SHOPPING_CART: '{NUM} عنصر في سلة التسوق الخاصة بك',
    REMOVE_ALL: 'حذف الكل',
    EXPAND_ALL: 'توسيع الكل',
    SAVE_FOR_LATER: 'احفظ لوقت لاحق',
    DEAL_DETAILS: 'تفاصيل الصفقة',
    OUT_OF_STOCK: 'إنتهى من المخزن',
    SELECT_CREDIT_CARD: 'حدد بطاقة الائتمان',
    PAYMENT_DETAIL: 'تفاصيل الدفع',
    CARDHOLDER_NAME: 'إسم صاحب البطاقة',
    CARDHOLDER_ID: 'معرف حامل البطاقة',
    CARD_NUMBER: 'رقم البطاقة',
    EXPIRATION: 'انتهاء الصلاحية',
    CVV: 'CVV',
    SAVE_CREDIT_CARD_INFORMATION: 'حفظ معلومات بطاقة الائتمان',
    CHECKOUT: 'الدفع',
    NEW_CREDIT_CARD: 'بطاقة ائتمان جديدة',
    PAYMENT_TO_TXT: 'لإكمال الطلب ، يجب عليك إكمال الدفع المفتوح لهذا الطلب',
    PRESS_TO_PAYMENT: 'اضغط هنا التحويل إلى صفحة الدفع',
  },
  SHANIV_ORDER_STATUS: {
    created_on_our_side: 'تم إنشاؤها من جانبنا',
    sent_to_priority: 'أرسلت إلى الأولوية',
    created_on_priority_side: 'تم إنشاؤها على جانب الأولوية',
    packed_in_transit: 'بخيبة أمل / في مرحلة انتقالية',
    delivered: 'ذكرت',
    error: 'خطأ',
    partly: 'جزئيا'
  },
  REPORTS: {
    OBLIGATION_REPORT: 'تقرير الالتزام',
    AGENT_TAX: 'ضريبة الوكيل',
    MARKETER_DISTRIBUTOR: 'المسوق / الموزع',
    COLLECTION_DAY: 'يوم الجمع',
    MISSING_DOCUMENTS: 'مستندات مفقودة',
  },
  FLIGHTS: {
    PLEASE_USE_ALL_FILTERS: 'Please use all filters',
    TYPE: 'Type',
    TYPE_ROUND: 'Round',
    TYPE_ONEWAY: 'One way',
    CABIN_CLASS: 'Cabin class',
    CABIN_CLASS_ANY: 'Any',
    CABIN_CLASS_ECONOMY: 'Economy',
    CABIN_CLASS_ECONOMY_PREMIUM: 'Economy Premium',
    CABIN_CLASS_BUSINESS: 'Business',
    CABIN_CLASS_FIRST: 'First class',
    MAX_STOPOVERS: 'Max stopovers',
    MAX_STOPOVERS_0: 'Direct only',
    MAX_STOPOVERS_1: '1',
    MAX_STOPOVERS_2: '2',
    PASSENGERS: 'Passengers',
    ADULTS: 'Adults',
    OVER_11: 'Over 11',
    CHILDREN: 'Children',
    AGE_2_11: 'Age 2-11',
    INFANTS: 'Infants',
    UNDER_2: 'Under 2',
    BAGGAGE: 'Baggage',
    HOLD_BAGS: 'Hold bags',
    HAND_BAGS: 'Hand bags',
    FROM: 'From',
    TO: 'To',
    FOR_EXAMPLE: 'For example, {dest_name}',
    LONDON: 'London',
    NEW_YORK: 'New York',
    BERLIN: 'Berlin',
    ROME: 'Rome',
    FLIGHTS: 'Flights',
    VIEW_ALL_FLIGHTS: 'View all',
    FLIGHT_TO: 'Flight to',
    STARTING_FROM: 'Starting from',
    FULL_DETAILS: 'Full details',
    PRICE_SPECIFIC_FLIGHT_SHOWN: 'Price for specific flight',
    DAYS: 'Days',
    PLEASE_SEARCH: 'Do your first search please',
    NO_FLIGHTS_FOUND: 'No flights found',
    TRIP_SUMMARY: 'Trip summary',
    NIGHTS_IN: 'nights in',
    BOOK_FLIGHT: 'Book flight',
    CONNECTION_PROTECTED_BY_CARRIER: 'Connection protected by the carrier',
    BAGS_RECHECK_REQUIRED: 'Bags recheck required',
    LAYOVER: 'Layover',
    ARRIVED_DESTINATION: 'Arrived at destination',
    FREE: 'Free',
    PAID_OPTION: 'Paid option',
    NOT_AVAILABLE: 'Not available',
    PERSONAL_ITEM: 'Personal item',
    CABIN_BAGGAGE: 'Cabin baggage',
    CHECKED_BAGGAGE: 'Checked baggage',
    THEN_DEPART_FROM: 'then depart from',
    BAGGAGE_INFO: 'Baggage info',
    AIRLINES: 'Airlines',
    PRICE: 'Price',
    LIST_ALL: 'List all',
    DEPARTURE_DATE: 'Departure date',
    RETURN_DATE: 'Return date',
    DURATION: 'Duration',
    TRAVELERS: 'Travelers',
    TIP: 'TIP',
    FLIGHT_TIP_TXT: 'Travel safely by adding a checked bag instead. You can take less on board and avoid moving around the cabin.',
    ORDER_DETAILS: 'Order details',
    SELECT_ONE_OPTION: 'Select one option',
    NO_CHECKED_BAGGAGE: 'No checked baggage',
    ORDER: 'Order',
    PRICE_PER_PASSENGER: 'Price per passenger',
    TOTAL_ORDER_PRICE: 'Total order price',
    TOTAL: 'Total',
    PASSENGER: 'Passenger',
    PASSENGER_CATEGORY_ADULTS: 'Adult',
    PASSENGER_CATEGORY_CHILDREN: 'Child',
    PASSENGER_CATEGORY_INFANTS: 'Infant',
    FIRST_NAME: 'Name',
    LAST_NAME: 'Surname',
    NATIONALITY: 'Nationality',
    GENDER: 'Gender',
    GENDER_MALE: 'Male',
    GENDER_FEMALE: 'Female',
    DATE_OF_BIRTH: 'Date of birth',
    CARD_NUMBER: 'Card number',
    CARD_EXPIRATION: 'Card expiration',
    FLIGHT_IS_NOT_AVAILABLE: 'This flight is no longer available!',
    PRICE_CHANGED: 'Price changed!',
    PHONE: 'Phone',
    EMAIL: 'Email',
    ERROR: 'Error',
    PLEASE_CHECK_EMAIL_AND_PHONE: 'Please check email and phone fields',
    EDIT: 'Edit',
    SAVE: 'Save',
    CURRENT_STATUS: 'Current status',
    STATUS_CREATED: 'Created',
    STATUS_CREATED_DESC: 'Waiting for client confirmation',
    STATUS_CONFIRMED_BY_CLIENT: 'Confirmed by client',
    STATUS_CONFIRMED_BY_CLIENT_DESC: 'Client confirmed the order. Waiting for response from the provider',
    STATUS_ORDERED: 'Ordered',
    STATUS_ORDERED_DESC: 'Order created on provider side',
    STATUS_PAID: 'Paid',
    STATUS_PAID_DESC: 'Client paid for the order. Waiting for provider confirmation',
    STATUS_CONFIRMED: 'Confirmed',
    STATUS_CONFIRMED_DESC: 'Confirmed by provider',
    STATUS_COMPLETED: 'Completed',
    STATUS_COMPLETED_DESC: 'Order completed',
    STATUS_CANCELED: 'Canceled',
    STATUS_CANCELED_DESC: 'Order canceled',
    STATUS_FAILED: 'Failed',
    STATUS_FAILED_DESC: 'Order failed',
    BOOK: 'Book',
    BOOK_WITHOUT_CLIENT_CONFIRMATION: 'Book without client confirmation',
    CANCEL: 'Cancel',
    CONFIRM_PAYMENT: 'Confirm payment',
    ARE_YOU_SURE: 'Are you sure?',
    OPEN_ETICKETS: 'Open e-tickets',
    BACK_TO_SEARCH: 'Back to search',
  }
};
