import * as lang from "vuejs-datepicker/src/locale";
import i18n from '../common/plugins/vue-i18n';


function calculateItemPerSlide() {
    if (window.innerWidth < 650) {
      return 1;
    }
  
    if (window.innerWidth < 1080) {
      return 2;
    }
  
    return 4;
}
export default {
    filters: {
        formatDate: function (value) {
          return moment(String(value)).format("DD/MM/YYYY");
        },
        formatTime: function (value) {
          return moment(String(value)).format("HH:mm:ss");
        },
        flightsFormatDate: function (value) {
            return moment(String(value)).format("DD/MM/YYYY");
        },
        flightsFormatTime: function (value) {
            return moment(String(value)).format("HH:mm");
        },
        getDayName: function (value) {
          return moment(String(value)).format('dddd');
        },
        getDay: function (value) {
          return moment(String(value)).day();
        },
        formatPrice: function (num) {
            if (!num) {
                return '0.00';
            }
            const number = (num / 1).toFixed(2).replace(',', '.');
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
    },
    data() {
        return {
            languages: lang,
            enLogo: "/assets/img/logo/logo-enar.png",
            heLogo: "/assets/img/logo/logo-he.png",
            currentLogo: "",
            itemPerSlide: calculateItemPerSlide(),
            dayString: [
                i18n.t('COMMON.SUNDAY'),
                i18n.t('COMMON.MONDAY'),
                i18n.t('COMMON.TUESDAY'),
                i18n.t('COMMON.WEDNESDAY'),
                i18n.t('COMMON.THURSDAY'),
                i18n.t('COMMON.FRIDAY'),
                i18n.t('COMMON.SATURDAY')
            ],
        }
    },
    computed: {
        currentLang: function() {
            let currentLang = localStorage.getItem("language");
            currentLang = currentLang ? currentLang.toLowerCase() : 'he';
            return currentLang;
        },
        isOpenAside: function() {
            let isOpenAside = localStorage.getItem("isOpenAside");
            return isOpenAside;
        },
        sliderDirection() {
            if (this.currentLang=="en") {
              return "ltr"
            } else {
              return "rtl"
            }
        },
    },
    created() {
        window.addEventListener('resize', this.onResize);
        if (this.currentLang=="en") {
            this.$vuetify.rtl = false
          } else {
            this.$vuetify.rtl = true
          }
    },
    methods: {
        gsv(o, p, default_v=null) {
            return p.reduce((xs, x) => (xs && xs[x]) ? xs[x] : default_v, o)
        },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();;
            } else {
                return true;
            }
        },
        removeA(arr) {
            var what, a = arguments, L = a.length, ax;
            while (L > 1 && arr.length) {
                what = a[--L];
                while ((ax= arr.indexOf(what)) !== -1) {
                    arr.splice(ax, 1);
                }
            }
            return arr;
        },
        onResize(event) {
            this.itemPerSlide = calculateItemPerSlide();
        },
        calcEndDate(depDate, len) {
            return this.$moment(depDate).add(len, 'days').format('DD/MM/YYYY');
        },
        getDayFromTimeStamp(str) {
            const dayNum =  moment(String(str)).day();
            return this.dayString[dayNum];
        },
        timeDifferenceByDates(fromDate, toDate) {
            let momentFromDate = moment(fromDate);
            let momentToDate = moment(toDate);
            let duration = moment.duration(momentToDate.diff(momentFromDate));
            let formattedDifference = duration.hours() + ':' + duration.minutes().toString().padStart(2, '0');

            return formattedDifference;
        },
        timeDifferenceDays(fromDate, toDate) {
            let momentFromDate = moment(fromDate);
            let momentToDate = moment(toDate);
            let duration = moment.duration(momentToDate.diff(momentFromDate));
            let formattedDifference = duration.days();

            return formattedDifference;
        },
        timeDifferenceHours(fromDate, toDate) {
            let momentFromDate = moment(fromDate);
            let momentToDate = moment(toDate);
            let duration = moment.duration(momentToDate.diff(momentFromDate));
            let formattedDifference = duration.hours();

            return formattedDifference;
        },
        timeDifferenceMinutes(fromDate, toDate) {
            let momentFromDate = moment(fromDate);
            let momentToDate = moment(toDate);
            let duration = moment.duration(momentToDate.diff(momentFromDate));
            let formattedDifference = duration.minutes();

            return formattedDifference;
        },
        formattedDate(dateString) {
            let momentDate = moment(dateString);

            return momentDate.format('DD/MM/YYYY');
        }
    },
    mounted() {
        if (this.$i18n && this.$i18n.locale == "he") {
            this.currentLogo = this.heLogo;
        } else {
            this.currentLogo = this.enLogo;
        }

        document.getElementsByTagName("html")[0].style.background = "#dfe0e3";
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
};