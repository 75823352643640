import { userService } from '../services';
import Vue from 'vue';
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

const sid = Vue.$cookies.get('connect.sid');
const token = localStorage.getItem('token');
const state = {
    loggingIn: false,
    loggedIn: false,
    sid: sid ? sid : null,
    token: token ? token : null,
    user_details: null
}

const actions = {
    login({ dispatch, commit }, { username, password }) {
        commit('setLoggingInTrue');
    
        return userService.login(username, password)
            .then(
                result => {
                    commit('setLoggingInFalse');
                    if (result && result.hasOwnProperty('msg') && result.msg === 'success') {
                        let sid = Vue.$cookies.get('connect.sid');
                        localStorage.setItem('connection.sid', sid)
                        commit('setLoggedInTrue');
                        commit('setSid', sid);
                        if (result && result.hasOwnProperty('token') && result.token !== '') {
                            commit('setToken', result.token);
                            localStorage.setItem('token', result.token)
                        }
                        if (result && result.hasOwnProperty('is_distributor') && result.is_distributor !== '') {
                            localStorage.setItem('is_distributor', result.is_distributor);
                        }
                    } else {
                        commit('setLoggedInFalse');
                    }

                    return result
                },
                error => {
                    if (error.response.status === 401) {
                        dispatch('alert/error', 'Wrong credentials', {root: true})
                    } else {
                        dispatch('alert/error', 'Something went wrong', {root: true})
                    }
                    commit('setLoggingInFalse');
                    commit('setLoggedInFalse');

                    return Promise.reject(error)
                }
            );
    },
    logout({ dispatch, commit }) {
        userService.logout();
        commit('logout');
        if (Vue.$cookies.isKey('connect.sid')) {
            Vue.$cookies.remove('connect.sid');
            localStorage.removeItem("passengers");
        }
        commit('permission/clearPermissionsData', null, { root: true });
        localStorage.removeItem("permissions");

        dispatch('cartSystem/clearCart', null, {root: true});
    },
    isAuth({ dispatch, commit }) {
        return userService.is_auth()
            .then(
                is_auth => {
                    if (is_auth === true || is_auth === 'true') {
                        commit('setLoggedInTrue');
                    } else {
                        commit('logout');
                    }

                    return is_auth
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },
    getUserDetails({ dispatch, commit }, { check_if_user_logged } = {}) {
        if (check_if_user_logged && !state.loggedIn) {
            return false
        }
        userService.user_details()
            .then(
                user_details => {
                    if (user_details) {
                        commit('setUserDetails', user_details);
                    } else {
                        commit('setUserDetails', null);
                    }
                },
                error => {
                    commit('setUserDetails', null);
                }
            );
    },
};

const mutations = {
    setLoggingInTrue(state) {
      state.loggingIn = true;
    },
    setLoggingInFalse(state) {
      state.loggingIn = false;
    },
    setLoggedInTrue(state) {
      state.loggedIn = true;
    },
    setLoggedInFalse(state) {
      state.loggedIn = false;
    },
    setSid(state, sid) {
      state.sid = sid;
    },
    setToken(state, token) {
      state.token = token;
    },
    logout(state) {
        state.loggingIn = false;
        state.loggedIn = false;
    },
    setUserDetails(state, user_details) {
        state.user_details = user_details;
    }
};

export const account = {
    namespaced: true,
    state,
    actions,
    mutations
};